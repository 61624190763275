import React, { useState, useContext, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import PracticeSidebarLectures from "./PracticeSidebarLectures";
import { PracticeContext } from "../../context";

// Custom hook for responsive design
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }

      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);

      return () => media.removeEventListener("change", listener);
    }
  }, [matches, query]);

  return matches;
}

export default function PracticeSidebar({
  examEngagementStatus,
  subjectId,
  practiceId,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabIndex, setActiveTabIndex] =
    useContext(PracticeContext).activeTabIndex;
  const [practiceTabs] = useContext(PracticeContext).practiceTabs;

  // Check if we're on mobile
  const isMobile = useMediaQuery("(max-width: 767px)");

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    setActiveTab(activeTabIndex);
  }, [practiceTabs, activeTabIndex]);

  // If no tabs, don't render anything
  if (!practiceTabs || practiceTabs.length === 0) {
    return null;
  }

  // Mobile view with tabs
  if (isMobile) {
    return (
      <div className="classroom__sidebar">
        <div className="classroom__tabs dark">
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            scrollButtons={"auto"}
            variant="scrollable"
          >
            {practiceTabs?.map(({ tab_id, tab_name }) => (
              <Tab
                key={tab_id}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                style={{ minWidth: 42 }}
                label={tab_name}
                onClick={() => setActiveTabIndex(tab_id)}
              />
            ))}
          </Tabs>
        </div>

        <div className="classroom__tabs__wrapper">
          <SwipeableViews
            axis={"x"}
            index={activeTab}
            onChangeIndex={(e) => setActiveTab(e)}
            scrolling={"false"}
            containerStyle={{ background: "#161616" }}
            containerStyle={{
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            }}
            className="swipeable-container"
            style={{ background: "#161616" }}
            slideStyle={{ background: "#161616" }}
          >
            {[0, 1].map((tab) => (
              <div key={tab} className="classroom__sidebar__tab">
                <PracticeSidebarLectures
                  data={practiceTabs}
                  tabIndex={tab}
                  examEngagementStatus={examEngagementStatus}
                  subjectId={subjectId}
                  practiceId={practiceId}
                />
              </div>
            ))}
          </SwipeableViews>
        </div>
      </div>
    );
  }

  // Desktop view with stacked sections
  return (
    <div className="classroom__sidebar" style={{ background: "#161616" }}>
      <div
        className="classroom__stacked__sections classroom__tabs__wrapper"
        style={{ height: "100%", overflowY: "auto" }}
      >
        {/* Practice Section */}
        <div className="classroom__stacked__section">
          <div
            className="classroom__stacked__section__header"
            style={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              padding: "12px 16px",
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#161616",
            }}
          >
            <h3
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#fff",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
            >
              {practiceTabs[0]?.tab_name || "Practice"}
            </h3>
          </div>
          <div className="classroom__stacked__section__content">
            <PracticeSidebarLectures
              data={practiceTabs}
              tabIndex={0}
              examEngagementStatus={examEngagementStatus}
              subjectId={subjectId}
              practiceId={practiceId}
              className="!mt-0"
            />
          </div>
        </div>

        {/* Solution Section */}
        <div className="classroom__stacked__section">
          <div
            className="classroom__stacked__section__header"
            style={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              padding: "12px 16px",
              position: "sticky",
              top: 0,
              zIndex: 1,
              background: "#161616",
            }}
          >
            <h3
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#fff",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
            >
              {practiceTabs[1]?.tab_name || "Solution"}
            </h3>
          </div>
          <div className="classroom__stacked__section__content">
            <PracticeSidebarLectures
              data={practiceTabs}
              tabIndex={1}
              examEngagementStatus={examEngagementStatus}
              subjectId={subjectId}
              practiceId={practiceId}
              className="!mt-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

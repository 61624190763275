import React, {useCallback, useContext, useEffect, useState} from 'react';
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import SchoolIcon from "@material-ui/icons/School";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Chart from "./Chart";
import Modal from '@material-ui/core/Modal';
// import ScholarshipGranted from '../assets/images/scholarship_granted.svg'
import {UserContext} from "../context";
import {formatFirstWord, getClassName, humanizeTime, randomAvatar, showSnackbar} from "../helpers";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {createBlazeRequest, getLifeTimeEngagement} from "../database/connect/index";
import {getInstructorRatings} from "../database";
import {useHistory} from "react-router-dom";
import {db} from "../firebase_config";
import ChatIcon from '@material-ui/icons/Chat';
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import {getAvatarImage} from "../context/global/user-context";
import Avatar from "./global/Avatar";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";

function Loader({className, style = {}}) {
  return <div className={"loader-37 flex-1 text-zinc-900 dark:text-white " + (className ? className : '')} style={style} />
}

function OutreachSessionItem({src, isActive, createdOn, title, uid, containerClassName, sessionId}) {
  const history = useHistory();
  const [showDrawer, setShowDrawer] = useContext(UserContext).showDrawer;

  const subTitle = (
    isActive ? <span className="text-xs text-green-500">Active Now</span> :
    <span className="text-xs text-black dark:text-white text-opacity-60">Created On: <span className="text-black dark:text-white text-opacity-90 font-bold">{createdOn}</span></span>
  )

  return (
    <div style={{zoom: 0.85}} className={"grid pb-1 pt-2 border-b-1 grid-cols-[50px_minmax(100px,_1fr)_50px] items-center justify-items-center cursor-pointer " + (containerClassName ? containerClassName : '')} onClick={() => {
      history.push('/blaze/chat/' + sessionId + '?is_not_my_session=true');
      setShowDrawer(false);
      // if(onItemClick) {
      //   return onItemClick(item);
      // }
      // setFormUser(() => {
      //   setMainActiveTab(1);
      //   return item;
      // });

    }}>
      <Avatar
        className="w-10 h-10 rounded-full"
        src={src}
        name={title}
        uid={uid}
        alt="Pustack User"
      />
      <div className="font-medium text-zinc-900 dark:text-white justify-self-start px-2">
        <div className="capitalize flex items-center gap-2">
          <span>{title}</span>
          {/*<NotificationOn style={{width: '16px', height: '16px''}} />*/}
        </div>
        <div className="text-sm font-normal text-gray-400 dark:text-gray-400">{subTitle}</div>
      </div>
      <ArrowForwardIosIcon className="text-gray-500" style={{fontSize: '18px'}} />
    </div>
  )
}

function OutreachSessions({uid}) {
  const [sessions, setSessions] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    const _sessions = await db
      .collection('blaze_dev')
      .doc('collections')
      .collection('blaze_sessions')
      .where('is_outreach_session', '==', true)
      .where('student_id', '==', uid)
      .orderBy('requested_ts', 'desc')
      .get();

    const transformedSessions = [];

    _sessions.forEach(session => {
      const _moment = moment(session.data().requested_ts);
      transformedSessions.push({
        instructor: {
          uid: session.data().instructor_id,
          name: session.data().instructor_name,
          image: session.data().instructor_profile_pic,
        },
        createdOn: _moment.format('MMM DD') + ' at ' + _moment.format('h:mm A'),
        requestedTs: _moment.unix(),
        isActive: session.data().session_status !== 'completed',
        id: session.id,
      })
    })

    transformedSessions.sort((a, b) => {
      if (a.isActive === b.isActive) {
        return b.requestedTs - a.requestedTs;
      }
      return a.isActive ? -1 : 1; // Sort by isActive (true comes before false)
    });

    setSessions(transformedSessions);

  }, [uid]);

  return sessions?.length > 0 ? (
    <div className="profile-card my-6 p-4">
      <div className="text-gray-500 text-sm font-medium">Outreach Sessions</div>
      <div className="flex flex-col gap-1 mt-2 divide-y divide-solid divide-zinc-200 dark:divide-slate-800">
        {sessions?.map((session, index) => <OutreachSessionItem key={session.id} isActive={session.isActive} createdOn={session.createdOn} src={session.instructor.image} title={session.instructor.name} uid={session.instructor.uid} sessionId={session.id} />)}
      </div>
    </div>
  ) : null;
}

export default function ProfileScreen({handleBackButton, user: userProp}) {
  const [_profileUser] = useContext(UserContext).profileUser;
  const avatarImage = useContext(UserContext).avatarImage;
  const [user] = useContext(UserContext).user;
  const [, setShowDrawer] = useContext(UserContext).showDrawer;
  const [profileUser, setProfileUser] = useState(userProp);
  const [chartData, setChartData] = useState([]);
  const [timeSpent, setTimeSpent] = useState(0);
  const [lectureWatchedCount, setLectureWatchedCount] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [messageText, setMessageText] = useState(null);
  const history = useHistory();
  const [isEngaged, setIsEngaged] = useState(false);

  useEffect(() => {
    if(!userProp && _profileUser) {
      setProfileUser(_profileUser);
      setMessageText(`Hello ${formatFirstWord(_profileUser.name ?? 'User')}, let me know if you have any doubts. Would love to clear them. Welcome to PuStack! ☺️`)
    }
  }, [userProp, _profileUser])

  useEffect(() => {
    if(!profileUser?.uid) return () => {};
    setFetching(true);
    setIsEngaged(false);
    getLifeTimeEngagement(profileUser.uid, profileUser)
      .then(({chartData, timeSpent, lectureWatchedCount}) => {
        setChartData(chartData);
        setTimeSpent(timeSpent)
        setLectureWatchedCount(lectureWatchedCount);
        setFetching(false);
      })

    async function run () {
      setLoading1(true);

      const querySnapshot = await db
        .collection('blaze_dev')
        .doc('collections')
        .collection('blaze_sessions')
        .where('session_status', '==', 'accepted')
        .where('is_outreach_session', '==', true)
        // .where('instructor_id', '==', user.uid)
        .where('student_id', '==', profileUser.uid)
        .orderBy('requested_ts', 'desc')
        .get();

      setLoading1(false);

      if(querySnapshot.docs.length > 0) {
        // setShowDrawer(null);
        setOpenModal(false);
        setMessageText('');
        // return history.push('/blaze/chat/' + querySnapshot.docs[0].id);
        setIsEngaged(true);
        // return showSnackbar("Student is already engaged in an outreach session.", "error");
      }
    }

    run();
  }, [profileUser, profileUser?.uid]);

  const clickStudent = async () => {
    if(user.uid === profileUser.uid) return showSnackbar("You cannot create an outreach session with yourself.", "error");

    setLoading1(true);

    const querySnapshot = await db
      .collection('blaze_dev')
      .doc('collections')
      .collection('blaze_sessions')
      .where('session_status', '==', 'accepted')
      .where('is_outreach_session', '==', true)
      // .where('instructor_id', '==', user.uid)
      .where('student_id', '==', profileUser.uid)
      .orderBy('requested_ts', 'desc')
      .get();

    setLoading1(false);

    if(querySnapshot.docs.length > 0) {
      // setShowDrawer(null);
      setOpenModal(false);
      setMessageText('');
      // return history.push('/blaze/chat/' + querySnapshot.docs[0].id);
      setIsEngaged(true);
      return showSnackbar("Student is already engaged in an outreach session.", "error");
    }

    setIsEngaged(false);
    setOpenModal(true);
  }
  const handleContactStudent = async () => {
    // setOpenModal(true);
    // return;
    if(messageText.trim().length === 0 || loading || loading1) return;
    setLoading(true);

    const querySnapshot = await db
      .collection('blaze_dev')
      .doc('collections')
      .collection('blaze_sessions')
      .where('session_status', '==', 'accepted')
      .where('is_outreach_session', '==', true)
      // .where('instructor_id', '==', user.uid)
      .where('student_id', '==', profileUser.uid)
      .orderBy('requested_ts', 'desc')
      .get();

    if(querySnapshot.docs.length > 0) {
      // setShowDrawer(null);
      setOpenModal(false);
      setMessageText('');
      // return history.push('/blaze/chat/' + querySnapshot.docs[0].id);
      setIsEngaged(true);
      return showSnackbar("Student is already engaged in an outreach session.", "error");
    }

    setIsEngaged(false);
    const instructorRating = await getInstructorRatings({
      instructorId: user.uid,
    });

    const instructor = {
      name: user.name,
      id: user.uid,
      image: user.profile_url,
      rating: instructorRating
    }

    const student = {
      name: profileUser.name,
      id: profileUser.uid,
      grade: profileUser.grade,
      image: profileUser.image
    }

    const sessionId = await createBlazeRequest(instructor, student, messageText);

    setLoading(false);
    if(sessionId) {
      setShowDrawer(null);
      setOpenModal(false);
      setMessageText('');
      history.push('/blaze/chat/' + sessionId);
    }
  }

  return profileUser ? (
    <div className="flex flex-col font-sans h-full px-4 py-4 md:py-0">
      <ArrowBackIos style={{fontSize: '20px'}} className="cursor-pointer text-zinc-900 dark:text-white" onClick={handleBackButton} />
      <div className="font-medium dark:text-white justify-self-start px-2 text-center">
        <div className="w-32 h-32 overflow-hidden rounded-full mx-auto my-4">
          <Avatar
            className="w-full h-auto"
            src={profileUser?.image}
            name={profileUser.name}
            uid={profileUser.uid}
            alt="Pustack User"
          />
        </div>
        <div className="capitalize">{profileUser.name}</div>
        <div className="text-sm text-gray-500 dark:text-gray-500">{getClassName(profileUser.grade)}</div>
      </div>
      <div className="hide-scrollbar flex-1 overflow-auto pb-[90px] md:pb-0">
        <div className="profile-card my-6 p-4">
          <div className="text-gray-500 text-sm font-medium">Engagement</div>
          <div>
            <div className="flex items-center mt-3">
              <SchoolIcon className="text-black bg-lime-500 rounded-2xl p-1" />
              <div className="text-sm font-medium dark:text-white justify-self-start px-2">
                {!fetching ? <div>{lectureWatchedCount}</div> :
                <div className="animate-pulse w-10 h-4 mb-1 bg-zinc-300 dark:bg-zinc-600 rounded-full" />}
                <div className="text-xs text-gray-500 dark:text-gray-500">Lectures Watched</div>
              </div>
            </div>
            <div className="flex items-center mt-3">
              <QueryBuilderIcon className="text-white bg-red-500 rounded-2xl p-1" />
              <div className="text-sm font-medium dark:text-white justify-self-start px-2">
                {!fetching ? <div>{humanizeTime(timeSpent)}</div> :
                <div className="animate-pulse w-20 h-4 mb-1 bg-zinc-300 dark:bg-zinc-600 rounded-full" />}
                <div className="text-xs text-gray-500 dark:text-gray-500">Time Spent</div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-card my-6 p-4">
          <div className="text-gray-500 text-sm font-medium">Lectures Watched</div>
          {fetching ? <div className="mt-5 h-[180px] animate-pulse bg-zinc-300 dark:bg-zinc-600 rounded" /> : <div className="mt-5">
            <Chart chartData={chartData}/>
          </div>}
        </div>
        <OutreachSessions uid={profileUser.uid} />
        {isEngaged ? <button className={"cursor-default bg-gray-50 dark:bg-gray-800 justify-center rounded-lg bg-gray-400 text-white w-full h-10 flex w-full items-center "}>
          <span className="font-medium text-gray-600 dark:text-white text-sm font-roboto">Student is Engaged in a Session!</span>
        </button> : <button
          className={"grant-button justify-center rounded-lg bg-gray-400 text-white w-full h-10 flex w-full items-center "}
          onClick={() => {
            clickStudent()
          }}>
          {loading1 ? <Loader/> : (
            <>
              <ChatIcon style={{fontSize: '16px'}} className="text-white mr-1"/>
              <span className="font-medium text-white text-md">Contact</span>
            </>
          )}
        </button>}
      </div>
      {/*<Modal*/}
      {/*  isOpen={openModal}*/}
      {/*  ariaHideApp={false}*/}
      {/*  shouldCloseOnEsc={true}*/}
      {/*  className="w-80 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-4"*/}
      {/*  shouldCloseOnOverlayClick={true}*/}
      {/*  overlayClassName="new-post-modal-overlay"*/}
      {/*  onRequestClose={() => setOpenModal(false)}*/}
      {/*>*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // className="w-80 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-4"
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="w-80 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-zinc-900 rounded-xl p-4">
          <div className="flex items-center justify-between">
            <p className="divide-y divide-gray-100 font-normal text-zinc-900 dark:text-white font-sans">Reach Out to <strong className="capitalize">{profileUser.name}</strong></p>
            <CloseIcon className="cursor-pointer text-zinc-900 dark:text-white" onClick={() => setOpenModal(false)} />
          </div>
          <hr className="h-px mt-3 mb-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <textarea
            rows="6"
            placeholder="Type your message..."
            autoFocus
            name="details"
            value={messageText}
            // disabled={images.length > 0 || isSubmitting}
            // disabled={isSubmitting}
            contentEditable
            onChange={e => setMessageText(e.target.value)}
            className="doubt__details__input"
          />
          <button disabled={messageText?.trim().length === 0} className={"mt-4 grant-button justify-center rounded-lg bg-gray-400 text-white w-full h-10 grant-button flex w-full items-center disabled:opacity-50 disabled:cursor-not-allowed "} onClick={handleContactStudent}>
            {loading ? <Loader/> : (
              <>
                <ChatIcon style={{fontSize: '16px'}} className="text-white mr-1" />
                <span className="font-medium text-white text-md">Contact</span>
              </>
            )}
          </button>
        </div>
      </Modal>
    </div>
  ) : null
}

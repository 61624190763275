import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import Axios from "axios";
import Modal from "react-modal";
import Lottie from "lottie-react-web";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgora from "./hooks/useAgora";
import MediaPlayer from "./mediaPlayer";
import MicIcon from "@material-ui/icons/Mic";
import CloseIcon from "@material-ui/icons/Close";
import { useMediaQuery } from "react-responsive";
import MicOffIcon from "@material-ui/icons/MicOff";
import { TextareaAutosize } from "@material-ui/core";
import { ReservationChats } from "../../components";
import VideocamIcon from "@material-ui/icons/Videocam";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import joinedCall from "../..//assets/sounds/join_call.mp3";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import ringSound from "../..//assets/sounds/caller_sound.mp3";
import BlazeLogo from "../../assets/images/pustackBlazeLogo.png";
import CallEndRoundedIcon from "@material-ui/icons/CallEndRounded";
import callDisconnected from "../../assets/sounds/call_disconnected.mp3";
import { UserContext, ThemeContext, BlazeExternalContext } from "../../context";
import { showSnackbar } from "../../components/doubts_forum/snackbar/functions";
import {
  agoraAppID,
  makeUniqueId,
  getIndianTime,
  rtmTokenBuilder,
  makeUniqueNumericId,
  PUSHY_SECRET_API_KEY,
  formatBillDuration,
  FCM_AUTH_HEADER,
  getGrade,
  castIndianTime,
} from "../../helpers";
import {
  createCallDocument,
  getDeviceTokens,
  listenToCallDoc,
  updateRtmTokenInSession,
  updateCallDocumentInstructor,
  updateStudentEngagementByInstructor,
  updateInstructorStatus,
  completeHandoverInstructor,
  sendBlazeChat,
  updateUserBlazeAvailability,
  markUserBlazeOffline,
  getStudentBalance,
  cancelOnDisconnect,
} from "../../database";
import "./call.scss";
import SendIcon from "../../assets/blaze/sendIcon";
import imageGallery from "../../assets/images/blaze/imageGallery.svg";
import circularProgress from "../../assets/lottie/circularProgress.json";
import {
  FCM_AUTH_TOKEN,
  firebaseFunctionBaseURL,
} from "../../helpers/constants";
import { getAllDeviceTokens } from "../../database/blazeExternal";
import { queryStudentEngagement } from "../../database/blaze/fetch-data";
import { rdb } from "../../firebase_config";
import { database } from "firebase";
import Avatar from "../../components/global/Avatar";

const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

const getSubject = (arr) => {
  let sub = "";
  arr.map((w) => (sub = sub + " " + w));
  return sub.slice(1);
};

const titleCase = (str) => {
  var splitStr = str?.toLowerCase().split(" ");
  for (var i = 0; i < splitStr?.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr?.join(" ");
};

const getSubtitle = (skill) => {
  if (skill) {
    let splitted = skill.split("_");
    let sub = "";

    if (splitted?.length > 0) {
      sub =
        splitted?.length === 3 ? splitted[2] : getSubject(splitted.slice(3));
    }
    return titleCase(sub === "maths" ? "mathematics" : sub);
  }
};

function BlazeExternalCall({
  closeModal,
  sessionSelected,
  chatData,
  moreChat,
  getMoreChatsFn,
}) {
  const [uid, setUid] = useState(0);
  const [appid] = useState(agoraAppID);

  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(true);
  const [payload, setPayload] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [canHandover, setCanHandover] = useState(false);
  const [studentTokens, setStudentTokens] = useState(null);
  const [makeTransition, setMakeTransition] = useState(false);
  const [isCallDocCreated, setIsCallDocCreated] = useState(false);
  const [hasStudentEngaged, setHasStudentEngaged] = useState(false);
  const [openEndCallModal, setOpenEndCallModal] = useState(false);
  const [callCompleted, setCallCompleted] = useState(false);
  const [studentPlatform, setStudentPlatform] = useState("");
  const [maxTimePerDay] = useContext(BlazeExternalContext).maxTimePerDay;
  const [gracePeriod] = useContext(BlazeExternalContext).freePeriod;
  const [isStudentPro, setIsStudentPro] = useState(null);

  const [isDark] = useContext(ThemeContext).theme;
  const [instructorActivity] =
    useContext(BlazeExternalContext).instructorActivity;
  const [callStartTs, setCallStartTs] =
    useContext(BlazeExternalContext).callStartTs;

  const [images, setImages] = useState([]);
  const [message, setMessage] = useState("");
  const [chatText, setChatText] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [ringing, setRinging] = useState(false);
  const [ringTimer, setRingTimer] = useState(null);
  const [ringTime, setRingTime] = useState(0);
  const [balanceLeft, setBalanceLeft] = useState(null);

  const [billDuration, setBillDuration] = useState(0);
  const [billInterval, setBillInterval] = useState(null);
  const prevChatDataLength = useRef(chatData?.length ?? 0);

  const changeRef = useRef(true);
  const dontLeaveCallRef = useRef(false);

  useEffect(() => {
    if (!chatData) {
      prevChatDataLength.current = null;
      return () => {};
    }
    // if(openChat) {
    // 	prevChatDataLength.current =  chatData.length
    // 	// setPrevChatDataLength(chatData.length);
    // }
    // console.log('prevChatDataLength.current, chatData.length - ', prevChatDataLength.current, chatData.length, openChat);
    if (openChat) {
      prevChatDataLength.current = chatData.length;
      return () => {};
    }
    if (
      prevChatDataLength.current !== chatData.length &&
      !openChat &&
      prevChatDataLength.current !== null
    ) {
      prevChatDataLength.current = chatData.length;
      setAnimate(true);
      setMakeTransition((c) => !c);
      setOpenChat(true);
    }
  }, [chatData, openChat, setOpenChat]);

  const {
    join,
    leave,
    joinState,
    remoteUsers,
    toggleAudio,
    toggleVideo,
    localVideoTrack,
  } = useAgora(client);

  const [user] = useContext(UserContext).user;
  const [permissions] = useContext(UserContext).permissions;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  const token = useMemo(() => {
    console.log("payload - ", payload);
    if (!payload || !payload.data?.meeting_id) return null;
    return rtmTokenBuilder({ channel: payload.data.meeting_id });
  }, [payload]);

  useEffect(() => {
    if (!payload || !payload.data.meeting_id || !token) return;
    let channel = payload.data.meeting_id;
    if (uid) {
      setAudio(!permissions?.microphone);
      setVideo(!permissions?.camera);
      !callStartTs &&
        updateRtmTokenInSession({ sessionId: payload.data.session_id, token });
      join(
        appid,
        channel,
        token,
        uid,
        !permissions?.microphone,
        !permissions?.camera
      );
    }
  }, [uid, payload, token, callStartTs]);

  useEffect(() => {
    return () => {
      if (
        payload?.data?.session_id &&
        payload?.data?.meeting_id &&
        !dontLeaveCallRef.current
      ) {
        console.log("ran on disconnect proto = ");
        closeModal();
        rdb
          .ref(
            `/sessions/${payload.data.session_id}/calls/${payload.data.meeting_id}/instructor`
          )
          .update({
            is_web_online: false,
            platform: "web",
            last_seen: database.ServerValue.TIMESTAMP,
            offline_ts: database.ServerValue.TIMESTAMP,
          });
      }
    };
  }, [payload]);

  useEffect(() => {
    if (uid && payload && joinState && !callStartTs) {
      setRinging(true);

      sendCloudMessage();
    }
  }, [joinState, payload, uid]);

  useEffect(() => {
    let _uid = makeUniqueNumericId(5);
    setUid(_uid);
    !callStartTs && getDeviceTokensFn(_uid);
  }, []);

  useEffect(() => {
    isCallDocCreated && listenToCallDocFn(payload);
  }, [isCallDocCreated]);

  useEffect(() => {
    handoverFn();
  }, [canHandover]);

  function countUp() {
    setRingTime((ringTime) => ringTime + 1);
  }

  useEffect(() => {
    if (joinState) {
      let timer = setInterval(() => countUp(), 1000);
      setRingTimer(timer);
    }
  }, [joinState]);

  useEffect(() => {
    if (ringTime > 30) {
      clearInterval(ringTimer);
      setRingTimer(null);

      if (!hasStudentEngaged) leaveCall();
    }
  }, [ringTime, hasStudentEngaged]);

  function billDurationIncrement() {
    setBillDuration((duration) => {
      return duration + 1;
    });
    !isStudentPro &&
      setBalanceLeft((balance) => {
        if (balance === null) return 0;
        return balance + 1;
      });
  }

  useEffect(() => {
    // This handler is for the case where tutor is disconnected before the student is engaged in the call
    if (
      !hasStudentEngaged &&
      payload?.data?.session_id &&
      payload?.data?.meeting_id
    ) {
      rdb
        .ref(
          `/sessions/${payload.data.session_id}/calls/${payload.data.meeting_id}`
        )
        .onDisconnect()
        .update({
          reason_for_call_end: "disconnected_abruptly",
          instructor: {
            is_web_online: false,
            platform: "web",
            last_seen: database.ServerValue.TIMESTAMP,
            offline_ts: database.ServerValue.TIMESTAMP,
          },
        });
    } else if (hasStudentEngaged) {
      rdb
        .ref(
          `/sessions/${payload.data.session_id}/calls/${payload.data.meeting_id}`
        )
        .onDisconnect()
        .cancel();
    }
  }, [hasStudentEngaged, payload]);

  useEffect(() => {
    if (hasStudentEngaged) {
      let timer = setInterval(() => billDurationIncrement(), 1000);
      setBillInterval(timer);
    } else {
      clearInterval(billInterval);
    }
  }, [hasStudentEngaged]);

  useEffect(() => {
    if (callCompleted) {
      dontLeaveCallRef.current = true;
      updateStudentEngagementByInstructor({
        studentId: sessionSelected?.student_id,
        activeCallId: null,
        activeSessionId: null,
        isEngaged: false,
      });
      updateInstructorStatusFn({
        isEngaged: false,
        activeSessionId: null,
        activeCallId: null,
      });
      markUserBlazeOffline({
        userId: user?.uid,
        sessionId: payload.data?.session_id,
        userType: "instructor",
        callId: payload.data?.meeting_id,
      });
      updateCallDocumentInstructor({
        sessionId: payload.data?.session_id,
        meetingId: payload.data?.meeting_id,
        status: "completed",
        duration: billDuration,
        reason: "disconnected_by_instructor",
      });
      cancelOnDisconnect({
        sessionId: payload.data?.session_id,
        userType: "instructor",
        callId: payload.data?.meeting_id,
      });

      leave();
      closeModal();
      setCallStartTs(null);
      showSnackbar("Call ended", "info");
    }
  }, [callCompleted]);

  useEffect(() => {
    if (hasStudentEngaged) {
      updateUserBlazeAvailability({
        userId: user?.uid,
        sessionId: payload?.data?.session_id,
        userType: "instructor",
        callId: payload?.data?.meeting_id,
        callStartTs,
      });

      clearInterval(ringTimer);
      setRingTimer(null);
      setRinging(false);

      let joinedPing = new Audio(joinedCall);
      joinedPing.play();

      queryStudentEngagement(sessionSelected.student_id).then(
        ({ total_call_duration, upper_limit }) => {
          if (upper_limit === 0) {
            setBalanceLeft(-1);
            setIsStudentPro(true);
            return;
          }
          setIsStudentPro(false);
          console.log(
            "Math.round((new Date() - new Date(callStartTs)) / 1000) - ",
            Math.round((new Date() - new Date(callStartTs)) / 1000),
            callStartTs,
            total_call_duration
          );
          let handoverDuration =
            total_call_duration +
            Math.round((new Date() - new Date(callStartTs)) / 1000);
          setBalanceLeft(
            callStartTs
              ? handoverDuration > 0
                ? handoverDuration
                : 0
              : total_call_duration
          );
        }
      );
    }
  }, [hasStudentEngaged]);

  useEffect(() => {
    return () => setCallStartTs(null);
  }, []);

  // const talkTime = useMemo(() => {
  //   if(isStudentPro) return null;
  //   if(balanceLeft) {
  //     let minuteLeftDec = (gracePeriod - balanceLeft) / 60;
  //     let minutesLeft = {};
  //     if(minuteLeftDec < 1) {
  //       minutesLeft = {val: gracePeriod - balanceLeft, unit: 's'};
  //     } else {
  //       minutesLeft = {val: Math.round((gracePeriod - balanceLeft) / 60), unit: 'm'};
  //     }
  //     return minutesLeft;
  //   }
  //   return null;
  // }, [balanceLeft, gracePeriod, isStudentPro])

  // const endTime = useMemo(() => {
  //   if(isStudentPro) return null;
  //   if(balanceLeft) {
  //     let minuteLeftDec = (maxTimePerDay - balanceLeft) / 60;
  //     let minutesLeft = {};
  //     if(minuteLeftDec < 1) {
  //       minutesLeft = {val: maxTimePerDay - balanceLeft, unit: 's'};
  //     } else {
  //       minutesLeft = {val: Math.round((maxTimePerDay - balanceLeft) / 60), unit: 'm'};
  //     }
  //     return minutesLeft;
  //   }
  //   return null;
  // }, [balanceLeft, maxTimePerDay, isStudentPro])

  useEffect(() => {
    if (callStartTs) {
      setBillDuration(Math.round((new Date() - new Date(callStartTs)) / 1000));
      let _payload = {
        data: {
          session_id: instructorActivity?.sessionId,
          meeting_id: instructorActivity?.meetingId,
        },
      };

      setPayload(_payload);
      setHasStudentEngaged(true);
      setTimeout(() => listenToCallDocFn(_payload), 1000);
    }
  }, [callStartTs]);

  const handoverFn = async () => {
    if (!canHandover) return;
    dontLeaveCallRef.current = true;
    const res = await completeHandoverInstructor({
      sessionId: instructorActivity?.sessionId,
      meetingId: instructorActivity?.meetingId,
    });

    cancelOnDisconnect({
      sessionId: instructorActivity?.sessionId,
      userType: "instructor",
      callId: instructorActivity?.meetingId,
    });

    if (!res) return;
    await leave();
    closeModal();
    setCallStartTs(null);
  };

  const sendCloudMessage = async () => {
    const tokens = payload?.data?.tokens;
    const pushkitTokens = payload?.data?.pushkitTokens;

    const hasLeftToday = true;

    // const studentBalance = await getStudentBalance({
    //   studentId: sessionSelected?.student_id,
    // });

    if (hasLeftToday) {
      if (pushkitTokens.length > 0) {
        pushkitTokens.forEach((token) => {
          Axios.post(
            firebaseFunctionBaseURL + "deliverIosVoipNotification",
            {
              notification_data: payload.data,
              ios_pushkit_token: token,
              is_production: true,
            },
            { headers: { Authorization: FCM_AUTH_HEADER } }
          ).catch((err) => console.log(err));
        });
      }

      if (tokens.length > 0) {
        // Axios.post(
        //   "https://fcm.googleapis.com/fcm/send",
        //   {
        //     data: payload.data,
        //     registration_ids: tokens,
        //   },
        //   { headers: { Authorization: FCM_AUTH_HEADER } }
        // ).catch((err) => console.log(err));
        const data = {
          ...payload.data,
          deliver_ts: payload.data.deliver_ts.toString(),
          instructor_avg_rating: payload.data.instructor_avg_rating.toString(),
          notification_id: payload.data.notification_id.toString(),
        };
        delete data.tokens;
        delete data.pushkitTokens;
        tokens.forEach((token) => {
          Axios.post(
            `https://fcm.googleapis.com/v1/projects/avian-display-193502/messages:send`, // Replace with your Firebase project ID
            {
              message: {
                token: token,
                data: data, // Your custom data payload
                notification: {
                  title:
                    payload?.data?.notificationTitle || "Notification Title", // Example title
                  body:
                    payload?.data?.notification_subtitle || "Notification Body", // Example body
                },
              },
            },
            {
              headers: {
                Authorization: `Bearer ${FCM_AUTH_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          ).catch((err) => console.log(err));
        });
      }

      createCallDocumentFn(0);
      updateStudentEngagementByInstructor({
        studentId: sessionSelected?.student_id,
        activeCallId: payload.data.meeting_id,
        activeSessionId: payload.data?.session_id,
        isEngaged: true,
      });
    } else {
      leave();
      closeModal();
      showSnackbar("Student has no active minutes left.", "info");
    }
  };

  const getDeviceTokensFn = async (_uid) => {
    const [tokens, pushkitTokens] = await getAllDeviceTokens({
      studentId: sessionSelected?.student_id,
      isStudent: true,
    });

    setStudentTokens({ tokens, pushkitTokens });

    const meetingId = makeUniqueId(20);

    if (!(tokens?.length > 0 || pushkitTokens?.length > 0)) {
      leave();
      setCallStartTs(null);
      return showSnackbar("Student has not registered any device", "info");
    }

    setPayload({
      data: {
        notification_title: sessionSelected?.instructor_name,
        notification_subtitle:
          "PuStack " + getSubtitle(sessionSelected?.skill) + " Expert",
        missed_notification_title: "Missed Call",
        missed_notification_subtitle: "Open app to see details ",
        notification_brief: "Brief",
        notification_type: "session_ping",
        instructor_id: user?.uid,
        instructor_name: "Prof. " + user?.name.split(" ")[0],
        instructor_profile_picture: user?.profile_url,
        session_id: sessionSelected?.id,
        student_id: sessionSelected?.student_id,
        belongs_to: sessionSelected?.student_id,
        meeting_id: meetingId,
        notification_id: _uid,
        deliver_ts: +getIndianTime(),
        rtm_token: token,
        tokens,
        pushkitTokens,
        instructor_avg_rating: sessionSelected?.instructor_rating,
        // last_active_mobile_platform: ios_pushkit_token ? "ios" : "android",
        source: "web",
      },
    });
  };

  const updateInstructorStatusFn = async ({
    isEngaged,
    activeCallId,
    activeSessionId,
  }) => {
    updateInstructorStatus({
      isEngaged,
      instructorId: user?.uid,
      activeCallId,
      activeSessionId,
    });
  };

  const createCallDocumentFn = async (studentBalance) => {
    const isCreated = await createCallDocument({
      sessionId: payload.data?.session_id,
      meetingId: payload.data?.meeting_id,
      studentBalance: studentBalance,
      platform: isSmallScreen ? "mweb" : "web",
      aggregatedDuration: sessionSelected?.aggregated_duration,
      aggregatedEarnings: sessionSelected?.aggregated_earnings,
    });

    setIsCallDocCreated(isCreated);
  };

  const listenToCallDocFn = (_payload) => {
    listenToCallDoc({
      sessionId: _payload.data?.session_id,
      meetingId: _payload.data?.meeting_id,
      callback: async (call, unsubscribe, ref) => {
        setStudentPlatform(call?.student_platform);
        if (call?.status === "rejected_by_student") {
          // dismissPing();
          updateInstructorStatusFn({
            isEngaged: false,
            activeSessionId: null,
            activeCallId: null,
          });
          updateStudentEngagementByInstructor({
            studentId: sessionSelected?.student_id,
            activeCallId: null,
            activeSessionId: null,
            isEngaged: false,
          });
          leave();
          setCallStartTs(null);
          closeModal();
          unsubscribe();
          showSnackbar("Call was rejected by student", "info");
        }

        if (call?.status === "engaged") {
          // dismissPing();
          if (!call?.call_start_ts) {
            const ist = await castIndianTime(true);
            await ref.set(
              {
                call_start_ts: ist.serverISOString,
              },
              { merge: true }
            );
          }
          updateInstructorStatusFn({
            isEngaged: true,
            activeSessionId: _payload?.data?.session_id,
            activeCallId: _payload?.data?.meeting_id,
          });
          setHasStudentEngaged(true);
        }

        if (call?.status === "handover_instructor") {
          setCanHandover(true);
        }

        if (call?.status === "completed") {
          setCallCompleted(true);
          unsubscribe();

          if (call?.reason_for_call_end === "student_grace_exceeded") {
            showSnackbar("Student's Balance Insufficient", "grace");
          }
        }
      },
    });
  };

  const leaveCall = async () => {
    dontLeaveCallRef.current = true;
    setOpenEndCallModal(false);

    let audio = new Audio(callDisconnected);
    audio.play();

    updateCallDocumentInstructor({
      sessionId: payload.data?.session_id,
      meetingId: payload.data?.meeting_id,
      status: hasStudentEngaged ? "completed" : "disconnected_by_instructor",
      duration: billDuration,
      reason: "disconnected_by_instructor",
    });

    markUserBlazeOffline({
      userId: user?.uid,
      sessionId: payload.data?.session_id,
      userType: "instructor",
      callId: payload.data?.meeting_id,
    });

    updateRtmTokenInSession({ sessionId: sessionSelected?.id, token: null });

    updateStudentEngagementByInstructor({
      studentId: sessionSelected?.student_id,
      activeCallId: null,
      activeSessionId: null,
      isEngaged: false,
    });

    updateInstructorStatusFn({
      isEngaged: false,
      activeSessionId: null,
      activeCallId: null,
    });

    cancelOnDisconnect({
      sessionId: payload.data?.session_id,
      userType: "instructor",
      callId: payload.data?.meeting_id,
    });
    setCallStartTs(null);

    if (!hasStudentEngaged) {
      const tokens = studentTokens.tokens;
      const pushkitTokens = studentTokens.pushkitTokens;

      let pingExpiredData = {
        ...payload.data,
        notification_type: "ping_expired",
        deliver_ts: +getIndianTime(),
      };

      if (pushkitTokens.length > 0) {
        pushkitTokens.forEach((token) => {
          Axios.post(
            firebaseFunctionBaseURL + "deliverIosVoipNotification",
            {
              notification_data: pingExpiredData,
              ios_pushkit_token: token,
              is_production: true,
            },
            { headers: { Authorization: FCM_AUTH_HEADER } }
          ).catch((err) => console.log(err));
        });
      }

      if (tokens.length > 0) {
        // Axios.post(
        //   "https://fcm.googleapis.com/fcm/send",
        //   {
        //     data: pingExpiredData,
        //     registration_ids: tokens,
        //   },
        //   { headers: { Authorization: FCM_AUTH_HEADER } }
        // ).catch((err) => console.log(err));
        const data = {
          ...payload.data,
          deliver_ts: payload.data.deliver_ts.toString(),
          instructor_avg_rating: payload.data.instructor_avg_rating.toString(),
          notification_id: payload.data.notification_id.toString(),
        };
        delete data.tokens;
        delete data.pushkitTokens;
        tokens.forEach((token) => {
          Axios.post(
            `https://fcm.googleapis.com/v1/projects/avian-display-193502/messages:send`, // Replace with your Firebase project ID
            {
              message: {
                token: token,
                data: pingExpiredData, // Your custom data payload
                notification: {
                  title:
                    payload?.data?.notificationTitle || "Notification Title", // Example title
                  body:
                    payload?.data?.notification_subtitle || "Notification Body", // Example body
                },
              },
            },
            {
              headers: {
                Authorization: `Bearer ${FCM_AUTH_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          ).catch((err) => console.log(err));
        });
      }
    }

    leave();
    closeModal();
  };

  // const dismissPing = () => {
  //   const androidToken = studentTokens.android;
  //   const isPushkitToken = studentTokens.isPushkitToken;
  //
  //   let dismissPingData = {
  //     ...payload.data,
  //     notification_type: "dismiss_ping",
  //     deliver_ts: +getIndianTime(),
  //   };
  //
  //
  //   if (androidToken && !isPushkitToken) {
  //     Axios.post(
  //       "https://fcm.googleapis.com/fcm/send",
  //       {
  //         data: dismissPingData,
  //         registration_ids: [androidToken],
  //       },
  //       { headers: { Authorization: FCM_AUTH_HEADER } }
  //     ).catch((err) => console.log(err));
  //   }
  //
  //   if (androidToken && isPushkitToken) {
  //     Axios.post(
  //       firebaseFunctionBaseURL + 'deliverIosVoipNotification',
  //       {
  //         notification_data: dismissPingData,
  //         is_production: true,
  //       },
  //       { headers: { Authorization: FCM_AUTH_HEADER } }
  //     ).catch((err) => console.log(err));
  //   }
  // };

  const handleOpenChat = () => {
    setAnimate(true);
    setMakeTransition(!makeTransition);
    openChat ? setTimeout(() => setOpenChat(false), 300) : setOpenChat(true);
  };

  const handleMessageSubmit = async () => {
    if (isSending) return;
    setIsSending(true);
    setChatText("");
    await sendBlazeChat({
      user: user,
      text: chatText,
      reference: sessionSelected?.ref,
      images: images,
      session_id: sessionSelected?.id,
      receiver_id: sessionSelected?.instructor_id,
      session_topic: sessionSelected?.topic,
      student_grade: getGrade(sessionSelected?.skill),
      type: images.length > 0 ? "attachment" : "text",
      disableNotification: true,
    });
    setImages([]);
    setChatText("");
    setIsSending(false);
  };

  const imageSelectionHandler = (e) => {
    const { files } = e.target;

    let _images = [];

    let _num =
      files.length + images.length > 1
        ? images.length - files.length > 0
          ? images.length - files.length
          : 1
        : files.length;

    if (files.length + images.length > 1) {
      setMessage("Only 1 image is allowed at a time");
      setOpenSnack(true);
      setTimeout(() => setOpenSnack(false), 2500);
    }

    if (images.length !== 1) {
      for (let i = 0; i < _num; i++) {
        _images[i] = {
          url: URL.createObjectURL(files[i]),
          ext: files[i].name.split(".").slice(-1)[0],
          name: files[i].name,
        };
      }
      setImages(images.concat(_images));
    }
    e.target.value = "";
  };

  const handleImageDelete = () => setImages([]);

  return (
    <div className="call">
      <div
        className={
          makeTransition
            ? "call__blaze__wrapper keep__aside"
            : "call__blaze__wrapper"
        }
      >
        {ringing && <audio src={ringSound} autoPlay loop />}
        <div className="player-container">
          <div className="call__blaze__head">
            <img
              src={BlazeLogo}
              alt="blaze"
              className="call__blaze__logo"
              draggable={false}
            />
          </div>
          <div className="local-player-wrapper">
            {remoteUsers.length === 0 ? (
              <>
                <div className="student__video__bg">
                  <Avatar
                    src={sessionSelected?.student_profile_pic}
                    name={sessionSelected?.student_name}
                    uid={sessionSelected?.student_id}
                    alt="student bg"
                    className="student__bg"
                  />
                  <Avatar
                    src={sessionSelected?.student_profile_pic}
                    name={sessionSelected?.student_name}
                    uid={sessionSelected?.student_id}
                    alt="student"
                    className="student__placeholder"
                  />
                </div>
                <div className="student__name">
                  {sessionSelected?.student_name}
                </div>
              </>
            ) : (
              remoteUsers.slice(-1).map((user) => (
                <>
                  <MediaPlayer
                    videoTrack={user.videoTrack}
                    audioTrack={user.audioTrack}
                    type="main"
                    platform={studentPlatform}
                    name={sessionSelected?.student_name}
                    uid={sessionSelected?.student_id}
                    profilePic={sessionSelected?.student_profile_pic}
                  />
                  <div className="student__name">
                    {sessionSelected?.student_name}
                  </div>
                  <div className="student__mic">
                    {user?.hasAudio ? <MicIcon /> : <MicOffIcon />}
                  </div>
                </>
              ))
            )}
          </div>
        </div>
        <div className="remote-player-wrapper">
          {video ? (
            <MediaPlayer
              videoTrack={localVideoTrack}
              type="student"
              name={user?.name}
              uid={user?.uid}
              profilePic={user?.profile_url}
              platform={null}
              audioTrack={undefined}
            />
          ) : (
            <div className="student__video__bg">
              <Avatar
                name={user?.name}
                uid={user?.uid}
                src={user?.profile_url}
                alt="student bg"
                className="student__bg"
              />
              <Avatar
                name={user?.name}
                uid={user?.uid}
                src={user?.profile_url}
                alt="student"
                className="student__placeholder"
              />
            </div>
          )}
          {joinState && (
            <div className="student__mic">
              {audio ? <MicIcon /> : <MicOffIcon />}
            </div>
          )}
        </div>
        {/*<div className="student-container">*/}
        {/*  */}
        {/*</div>*/}
      </div>
      <div className="button-group">
        <div className="bill-duration">
          <p>{formatBillDuration(billDuration)}</p>
        </div>
        {joinState && (
          <div>
            <button
              className={makeTransition ? "chat-icon on" : "chat-icon"}
              onClick={handleOpenChat}
            >
              {sessionSelected?.instructor_unread_count > 0 && (
                <div className="unread-dot" />
              )}
              <ChatBubbleIcon />
            </button>
            <button
              onClick={() => {
                setVideo((c) => {
                  toggleVideo(!c);
                  return !c;
                });
              }}
              className={!video ? "off" : "on"}
            >
              {!video ? <VideocamOffIcon /> : <VideocamIcon />}
            </button>
            <button
              onClick={() => {
                setAudio((c) => {
                  toggleAudio(!c);
                  return !c;
                });
              }}
              className={!audio ? "off" : "on"}
            >
              {!audio ? <MicOffIcon /> : <MicIcon />}
            </button>
            <button
              id="leave"
              type="button"
              className="leave-btn"
              onClick={() => setOpenEndCallModal(true)}
            >
              <CallEndRoundedIcon />
            </button>
          </div>
        )}
      </div>
      <div
        className={
          makeTransition
            ? "chat-messages slideInside"
            : "chat-messages slideOutside"
        }
      >
        <div className="w-full h-full">
          {openChat && chatData && (
            <>
              <div className="chat-messages-head">
                <h1>Chat</h1> <CloseIcon onClick={handleOpenChat} />
              </div>
              <ReservationChats
                chats={chatData}
                moreChat={moreChat}
                getMoreChatsFn={getMoreChatsFn}
                midCall={true}
              />
              <div className="blaze__chat__image__preview fadeOutDown">
                {images.length > 0 &&
                  images.map((image, i) => (
                    <div className="image__preview">
                      <CloseIcon
                        onClick={handleImageDelete}
                        className="imagePreviewDialog_closeIcon"
                      />
                      <img src={image.url} alt="X" draggable={false} />
                    </div>
                  ))}
              </div>
              <div className="user__chat__input">
                <div
                  className={
                    isDark
                      ? "blaze__image__picker dark"
                      : "blaze__image__picker"
                  }
                >
                  <label htmlFor="blaze-image-picker-main">
                    <div>
                      <img
                        className="blaze__input__image"
                        style={{ color: "rgb(68, 189, 96)", cursor: "cursor" }}
                        src={imageGallery}
                        alt="chatImageInput"
                      />
                    </div>
                  </label>
                  <input
                    accept="image/*"
                    type="file"
                    id="blaze-image-picker-main"
                    style={{ display: "none" }}
                    onChange={imageSelectionHandler}
                  />
                </div>

                <div className="textarea__wrapper">
                  <TextareaAutosize
                    className="livesession__commentSectionInput blazeres__input"
                    rowsMax={chatText === "" ? 1 : 4}
                    aria-label="maximum height"
                    autoFocus
                    value={chatText}
                    placeholder={"Type your doubt here ..."}
                    disabled={images.length > 0}
                    onChange={(event) =>
                      changeRef.current && setChatText(event.target.value)
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        !e.shiftKey &&
                        (chatText.trim() !== "" || images.length !== 0)
                      ) {
                        changeRef.current = false;
                        handleMessageSubmit();
                      } else {
                        changeRef.current = true;
                      }
                    }}
                  />
                </div>
                <button
                  onClick={handleMessageSubmit}
                  className="livesession__commentSectionButton blazeres__btn"
                  disabled={chatText.trim() === "" && images.length === 0}
                  aria-label="blazeres__btn"
                >
                  {isSending ? (
                    <div className="circular__progress__lottie">
                      <Lottie
                        options={{
                          animationData: circularProgress,
                          loop: true,
                        }}
                      />
                    </div>
                  ) : (
                    <SendIcon
                      className="blaze-send-icon"
                      color={
                        chatText.trim() === "" && images.length === 0
                          ? "grey"
                          : "var(--color-highlight)"
                      }
                    />
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setOpenEndCallModal(false)}
        ariaHideApp={false}
        overlayClassName="new-post-modal-overlay"
        isOpen={openEndCallModal}
        className={isDark ? "end__call dark" : "end__call"}
      >
        <div>
          <div>
            <button className="yes__btn" onClick={leaveCall}>
              End Call
            </button>
            <button
              className="no__btn"
              onClick={() => setOpenEndCallModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/*{endTime && endTime.val >= 0 && gracePeriod < balanceLeft && (isStudentPro !== null && !isStudentPro) && <div className="timer-end"*/}
      {/*                                                   style={{*/}
      {/*                                                     position: 'fixed',*/}
      {/*                                                     top: '40px',*/}
      {/*                                                     right: '40px',*/}
      {/*                                                     padding: '7px 10px',*/}
      {/*                                                     pointerEvents: 'none',*/}
      {/*                                                     borderRadius: '8px',*/}
      {/*                                                     background: 'red',*/}
      {/*                                                     fontSize: '13px',*/}
      {/*                                                     color: 'white',*/}
      {/*                                                     opacity: 0.7,*/}
      {/*                                                     zIndex: 22222*/}
      {/*                                                   }}*/}
      {/*>*/}
      {/*  Call ends in {endTime.val}{endTime.unit}*/}
      {/*</div>}*/}

      {/*{talkTime && talkTime.val >= 0 && gracePeriod >= balanceLeft && (isStudentPro !== null && !isStudentPro) && <div className="timer-end"*/}
      {/*                                                     style={{*/}
      {/*                                                       position: 'fixed',*/}
      {/*                                                       top: '40px',*/}
      {/*                                                       right: '40px',*/}
      {/*                                                       padding: '7px 10px',*/}
      {/*                                                       pointerEvents: 'none',*/}
      {/*                                                       borderRadius: '8px',*/}
      {/*                                                       background: 'red',*/}
      {/*                                                       fontSize: '13px',*/}
      {/*                                                       color: 'white',*/}
      {/*                                                       opacity: 0.7,*/}
      {/*                                                       zIndex: 22222*/}
      {/*                                                     }}*/}
      {/*>*/}
      {/*  Talktime Left: {talkTime.val}{talkTime.unit}*/}
      {/*</div>}*/}
    </div>
  );
}

export default BlazeExternalCall;

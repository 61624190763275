import firebase from "firebase/app";
import {
  db,
  auth,
  googleProvider,
  appleProvider,
  facebookProvider
} from "../../firebase_config";
import { version } from "../../../package.json";
import {castIndianTime, VAPIDKEY} from "../../helpers";

const PROVIDER_NOT_SUPPORTED_MSG = 'The auth provider is not supported. Please contact pustack administrator.'

const deleteUserFromDatabase = (userId) => {
  db.collection("users").doc(userId).delete();
};

// export const deleteUserFn = (uid) => {
  // return db.doc('users/' + uid).delete();
  // const deleteUser = functions.httpsCallable('deleteUser');
  // return deleteUser({
  //   uid
  // });
// }

export const signInWithGoogle = async (
  facebookPendingCredentials,
  googleMailAddress
) => {
  let user;
  await auth
    .signInWithPopup(googleProvider)
    .then((res) => {
      user = res.user;

      if (
        facebookPendingCredentials &&
        googleMailAddress === res.user.email &&
        facebookPendingCredentials !== "no-credentials"
      ) {
        res.user.linkWithCredential(facebookPendingCredentials);
      }
    })
    .catch((_) => (user = null));

  return user;
};

export const submit = async (
  provider,
  userData,
  setPhoneNumberError,
  setSocialProcessStarted,
  setExistingAccountError,
  handleAuth,
  setData,
  handleIfNoEmail = () => {}
) => {
  try {

    if (!provider) throw new Error(PROVIDER_NOT_SUPPORTED_MSG)

    // logged-in user with the phone
    const prevUser = auth.currentUser;

    // console.log('prevUser - ', prevUser);

    // logging in with the GOOGLE provider
    const res = await auth.signInWithPopup(provider);

    // console.log('res.user - ', res.user);

    if (!res.user?.email) {
      setPhoneNumberError("The facebook account doesn't have an email");
      setSocialProcessStarted([false, false, false]);
      setTimeout(() => {
        setPhoneNumberError("");
        handleIfNoEmail();
      }, 2000);
      return;
    }

    // const fcmToken = localStorage.getItem('fcmToken');
    // if(!fcmToken) {
    //   const token = await messaging.getToken({vapidKey: VAPIDKEY});
    //   localStorage.setItem('fcmToken', token);
    // }

    if (res.user.phoneNumber !== null) {
      // Deleting the phone user using the cloud function
      // await deleteUserFn(prevUser.uid);

      // Deleting the phone user
      await prevUser.delete();

      return setData(res.user);
    } else {
      let time_now = await castIndianTime();
      let displayName = "";
      let photoURL = "";
      const email = res.user?.email;

      let dataArray = res.user.providerData;

      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]?.displayName) {
          displayName = dataArray[i]?.displayName;
        }
        if (dataArray[i]?.displayName) {
          photoURL = dataArray[i]?.photoURL;
        }
      }

      // Deleting the email user
      // await deleteUserFn(res.user.uid);

      await res.user.delete();

      await prevUser.linkWithCredential(res.credential);

      // And then logging back to the account(phone-number one) as the logged-in email account has been deleted.
      await auth.signInWithCredential(res.credential);

      let data = {
        app_rating: null,
        app_rating_history: [],
        email,
        grade: null,
        has_rated_app: false,
        is_instructor: false,
        is_external_instructor: true,
        name: displayName,
        pro_expiration_date: null,
        profile_url: photoURL,
        role: "External Instructor",
        tutor_signup_ts: +time_now,
        tier: "free",
        uid: prevUser?.uid,
      };

      // Now, updating the firebase document with the user id.
      handleAuth(data);
    }
  } catch (error) {
    console.log('error - ', error);
    if (
      error.code === "auth/credential-already-in-use" ||
      error.code === "auth/email-already-in-use"
    ) {
      userData.delete();
      // setExistingAccountError(error);
      setSocialProcessStarted([false, false, false]);
      // return setPhoneNumberError(ALREADY_EXIST_EMAIL_LOGIN_CODE)
      return;
    }
    setPhoneNumberError(error.message);
    setSocialProcessStarted([false, false, false]);
  }
}

export const submitGoogleEmail = ({
  userData,
  setActiveTab,
  activeTab,
  countryCode,
  setTempUserData,
  setPhoneNumberError,
  setSocialProcessStarted,
  setExistingAccountError,
  setProcessStarted,
}) => {
  userData
    .linkWithPopup(googleProvider)
    .then((res) => {
      let time_now = Date.now();
      let displayName = "";
      let photoURL = "";

      let dataArray = res.user.providerData;

      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]?.displayName) {
          displayName = dataArray[i]?.displayName;
        }
        if (dataArray[i]?.displayName) {
          photoURL = dataArray[i]?.photoURL;
        }
      }

      let data = {
        app_rating: null,
        app_rating_history: [],
        email: res.user?.email,
        grade: null,
        has_rated_app: false,
        is_instructor: false,
        name: displayName,
        pro_expiration_date: null,
        profile_url: photoURL,
        role: "External Instructor",
        tutor_signup_ts: time_now,
        tier: "free",
        uid: res.user?.uid,
        balance: 0,
        is_external_instructor: true,
        is_tutor_verified: false,
        phone_country_code: countryCode,
        phone_number: userData.phoneNumber?.slice(countryCode.length + 1),
        source: `web ${version}`,
        has_submitted_skills: true,
      };

      setTempUserData({ ...data });
      setActiveTab(activeTab + 1);
      setTimeout(() => setProcessStarted(false), 200);
    })
    .catch(async (error) => {
      console.log({ error });
      if (
        error.code === "auth/credential-already-in-use" ||
        error.code === "auth/email-already-in-use"
      ) {
        userData.delete();
        setExistingAccountError(error);
        setSocialProcessStarted([false, false, false]);

        return;
      }

      if (error.code === "auth/provider-already-linked") {
        userData.delete();
        setExistingAccountError(error);
        setTimeout(() => setProcessStarted(false), 200);
        setTimeout(() => setActiveTab(0), 1000);
      }

      setPhoneNumberError(error.message);
      setSocialProcessStarted([false, false, false]);
    });
};

export const signInWithApple = async () => {
  let user;
  appleProvider.addScope("email");
  appleProvider.addScope("name");

  await auth
    .signInWithPopup(appleProvider)
    .then((res) => (user = res.user))
    .catch((error) => {
      user = null;
    });

  return user;
};

export const signInWithFacebook = async (
  setSocialProcessStarted,
  setGmailExists,
  setGoogleMailAddress,
  setFacebookPendingCredentials
) => {
  let user;

  auth.useDeviceLanguage();

  await auth
    .signInWithPopup(facebookProvider)
    .then(function (result) {
      user = result.user;
    })
    .catch(function (error) {
      user = null;

      if (error.code === "auth/account-exists-with-different-credential") {
        setGoogleMailAddress(error.email);
        setFacebookPendingCredentials(error.credential);
        setGmailExists(true);
      }

      setSocialProcessStarted([false, false, false]);
    });

  return user;
};

export const linkFacebookWithGmail = (email, onSignInWithGoogleBtnClick) => {
  auth
    .fetchSignInMethodsForEmail(email)
    .then((methods) => {})
    .catch((err) => console.log(err));
};

export const submitFacebook = ({
  userData,
  countryCode,
  setActiveTab,
  activeTab,
  setTempUserData,
  setPhoneNumberError,
  setSocialProcessStarted,
  setGmailExists,
  setGoogleMailAddress,
  setFbPendingCredentials,
  submitGoogleEmailFn,
  setExistingAccountError,
}) => {
  userData
    .linkWithPopup(facebookProvider)
    .then((res) => {
      let time_now = Date.now();
      let displayName = "";
      let photoURL = "";

      let dataArray = res.user.providerData;

      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i]?.displayName) {
          displayName = dataArray[i]?.displayName;
        }
        if (dataArray[i]?.displayName) {
          photoURL = dataArray[i]?.photoURL;
        }
      }

      if (!res.user?.email) {
        setPhoneNumberError("The facebook account doesn't have an email");
        setSocialProcessStarted([false, false, false]);
        setTimeout(() => {
          setPhoneNumberError("");
          submitGoogleEmailFn();
        }, 2000);
        return;
      }

      let data = {
        app_rating: null,
        app_rating_history: [],
        email: res.user?.email,
        grade: null,
        has_rated_app: false,
        is_instructor: false,
        name: displayName,
        pro_expiration_date: null,
        profile_url: photoURL,
        role: "External Instructor",
        tutor_signup_ts: time_now,
        tier: "free",
        uid: res.user?.uid,
        balance: 0,
        is_external_instructor: true,
        is_tutor_verified: false,
        phone_country_code: countryCode,
        phone_number: userData.phoneNumber?.slice(countryCode.length + 1),
        source: `web ${version}`,
        has_submitted_skills: true,
      };

      setTempUserData({ ...data });
      setActiveTab(activeTab + 1);
    })
    .catch(async (error) => {
      if (error.code === "auth/email-already-in-use") {
        userData.delete();

        setGmailExists(true);
        setGoogleMailAddress(error.email);
        setFbPendingCredentials(error.credential);
      }

      if (error.code === "auth/credential-already-in-use") {
        userData.delete();

        setGmailExists(true);
        setGoogleMailAddress(error.email);
        setFbPendingCredentials("no-credentials");
      }

      if (error.code === "auth/provider-already-linked") {
        userData.delete();
        setExistingAccountError(error);
        setTimeout(() => setActiveTab(0), 1000);
      }

      setPhoneNumberError(error.message);
      setSocialProcessStarted([false, false, false]);
    });
};

export const signInWithPhone = async (
  phoneNumber,
  countryCode,
  setActiveTab,
  tab = 1,
  setSendingOtp,
  setPhoneNumberError,
  tempEmailUserData,
  setTempEmailUserData,
  setPhoneRoute,
  setPhoneExists,
  cb = () => {}
) => {
  auth.useDeviceLanguage();

  // const phoneExists = await checkIfPhoneNumberExists(
  //   countryCode ? phoneNumber.slice(countryCode?.length + 1) : phoneNumber
  // );


  if (tempEmailUserData?.phoneNumber === null) {
    await submitPhoneNumber(
      phoneNumber,
      setActiveTab,
      tab,
      setSendingOtp,
      setPhoneNumberError,
      cb
    );
    // tempEmailUserData
    //   .linkWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //
    //     setSendingOtp(false);
    //
    //     setTimeout(() => setActiveTab(tab), 100);
    //     cb();
    //   })
    //   .catch((error) => {
    //     if (error?.code === "require('../../firebase-config').auth/invalid-phone-number") {
    //       setPhoneNumberError("Invalid Phone Number");
    //     } else {
    //       setPhoneNumberError(error?.message);
    //     }
    //
    //     setSendingOtp(false);
    //     setTimeout(() => setPhoneNumberError(""), 5000);
    //   });
  } else {
    if (tempEmailUserData) {
      tempEmailUserData.delete();
      deleteUserFromDatabase(tempEmailUserData?.uid);
      setTempEmailUserData(null);
      setPhoneExists(true);

      await submitPhoneNumber(
        phoneNumber,
        setActiveTab,
        1,
        setSendingOtp,
        setPhoneNumberError,
        cb
      );

      setActiveTab(1);
      setPhoneRoute(true);
    } else {
      console.log('coming from here- ', tab);
      await submitPhoneNumber(
        phoneNumber,
        setActiveTab,
        tab,
        setSendingOtp,
        setPhoneNumberError,
        cb
      );
    }
  }
};

const submitPhoneNumber = async (
  phoneNumber,
  setActiveTab,
  tab,
  setSendingOtp,
  setPhoneNumberError,
  cb = () => {}
) => {
  const appVerifier = window.recaptchaVerifier;

  console.log('phone number - ', phoneNumber);

  await auth
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((confirmationResult) => {
      console.log('coming here');
      window.confirmationResult = confirmationResult;

      setSendingOtp(false);

      if(tab >= 0) setTimeout(() => setActiveTab(tab), 100);
      cb();
    })
    .catch((error) => {
      console.log('error - ', error);
      if (error?.code === "auth/invalid-phone-number") {
        setPhoneNumberError("Invalid Phone Number");
      } else {
        setPhoneNumberError(error?.message);
      }

      setSendingOtp(false);
      setTimeout(() => setPhoneNumberError(""), 5000);
    });
};

export const submitPhoneNumberAuthCode = async (
  code,
  facebookPendingCredentials,
  googleMailAddress,
  updatePhone = false,
) => {
  const otpConfirm = window.confirmationResult;

  if (updatePhone) {
    return auth.currentUser
      .updatePhoneNumber(
        firebase.auth.PhoneAuthProvider.credential(
          otpConfirm.verificationId,
          code
        )
      )
      .then(() => {
        return [null, true, ""]
      })
      .catch((err) => {
        console.log('err - ', err);
        let e = err.message;
        if (err.code === "auth/invalid-verification-code") {
          e = "Incorrect Verification Code.";
        } else if (err.code === "code-expired") {
          e = "The OTP has expired, please try again.";
        }
        return [null, false, e]
      });
  }
  const prevUser = auth.currentUser;

  console.log('prevUser - ', prevUser);

  return await otpConfirm
    ?.confirm(code)
    .then(async (result) => {
      let user = result.user;

      window.phoneCredentials = result.credential;

      const snapshot = await db.collection('users')
        .doc(user.uid)
        .get();

      console.log('result -', result, prevUser, snapshot.exists);

      // if(snapshot.exists && prevUser && prevUser !== result.user) {
      //
      //   // Deleting the email user using the cloud function
      //   await deleteUserFn(prevUser.uid);
      //
      //   // Deleting the phone user
      //   await prevUser.delete();
      //
      //
      //   // if(user.email !== null) {
      //   //
      //
      //   // }
      //   return [user, true, "", true];
      // }

      // if(!snapshot.exists && prevUser && prevUser !== result.user){
      //
      //   const credential = firebase.auth.PhoneAuthProvider.credential(
      //     otpConfirm.verificationId,
      //     code
      //   );
      //
      //   // Deleting the email user using the cloud function
      //   await deleteUserFn(result.user.uid);
      //
      //   // Deleting the phone user
      //   await result.user.delete();
      //
      //   await prevUser.linkWithCredential(credential);
      //
      //   // And then logging back to the account(phone-number one) as the logged-in email account has been deleted.
      //   const resss = await auth.signInWithCredential(credential);
      //
      //   user = resss.user;
      // }

      console.log('user - ', user);

      return [user, true, ""];


      // if (
      //   facebookPendingCredentials &&
      //   googleMailAddress === result.user.email
      // ) {
      //   result.user.linkWithCredential(facebookPendingCredentials);
      // }

      // return [user, true, ""];
    })
    .catch(async (err) => {
      console.log('err - ', err);
      if (err.code === "auth/credential-already-in-use") {
        return [null, false, "Phone number associated with another user."];
      } else if (err.code === "code-expired") {
        return [null, false, "The OTP has expired, please try again."];
      } else if (err.code === "auth/invalid-verification-code") {
        return [null, false, "Incorrect Verification Code."];
      }
      return [null, false, err.code];
    });
};

export const updatePhoneNumber = async (uid, phoneNumber, countryCode) => {
  return await db
    .collection("users")
    .doc(uid)
    .set(
      {
        phone_number: phoneNumber,
        phone_country_code: countryCode,
      },
      { merge: true }
    )
    .then(() => true)
    .catch(() => false);
};

export const logOut = async () => {
  let logout_sucess;
  await auth.signOut().then(() => {
    logout_sucess = true;
  })

  return logout_sucess;
};

export const removeFcmToken = async (userId, fcmToken) => {
  return await db
    .collection("user_tokens")
    .doc(userId)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        let tokens = doc.data().tokens?.filter((t) => t.token !== fcmToken);

        return await db
          .collection("user_tokens")
          .doc(userId)
          .update({ tokens: tokens })
          .then(() => true)
          .catch(() => false);
      }
    })
    .catch(() => false);
};

export const checkIfPhoneNumberExists = async (phoneNumber) => {
  return await db
    .collection("users")
    .where("phone_number", "==", phoneNumber)
    .get()
    .then((res) => res.docs.length > 0)
    .catch(() => false);
};

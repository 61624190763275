import React, {useState, useCallback, useRef, useContext, useEffect, useMemo} from 'react';
import UserListItem from "./UserListItem";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {getClassName, humanizeTime} from "../helpers";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import {ThemeContext, UserContext} from "../context";
import moment from "moment";
import EmptyLottie from "../assets/lottie/empty-box.json";
import Lottie from "lottie-react-web";
import EmptyBox from "../assets/lottie/empty-box.json";
import {circularProgress} from "../assets";
import {useMediaQuery} from "react-responsive";

const defaultPic = 'https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/users%2Fuser_profile%2Fdefault%2Fimage.jpg?alt=media&token=da277c04-0b39-49a3-8b90-4d6e93129a93'


export const AntTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    width: '100%'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#dd2476',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#dd2476',
    },
  },
})(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#dd2476',
      opacity: 1,
    },
    '&$selected': {
      color: '#dd2476',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#dd2476',
    },
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);

function Timer({initValue = 0}) {
  const [val, setVal] = useState(initValue)

  useEffect(() => {
    const interval = setInterval(() => {
      setVal(val => val + 10000);
    }, 10000)

    return () => clearInterval(interval);
  }, [initValue]);

  const string = useMemo(() => {
    const duration = moment() - moment(val);
    return humanizeTime(Math.round(duration / 1000));
  }, [val])

  return (
    <div className="flex mt-1 items-center font-sans">
      <span className="block w-1.5 h-1.5 rounded-full bg-green-400 mr-1" /> <span className="text-zinc-900 dark:text-white opacity-70 text-xs mr-2">Active Since</span> <span className="text-zinc-900 dark:text-white text-xs font-medium">{string}</span>
    </div>
  )
}

export default function ActiveUsersScreen({handleBackButton, fetchMore = () => {}, fetchMoreOnlineUsers = () => {}, fetchMoreNewUsers= () => {}, noMoreActiveUsers: _noMoreActiveUsers, noMoreNewUsers: _noMoreNewUsers, noMoreOnlineUsers: _noMoreOnlineUsers}) {
  const [activeUsers] = useContext(UserContext).activeUsers;
  const [onlineUsers] = useContext(UserContext).onlineUsers;
  const [newUsers] = useContext(UserContext).newUsers;
  const visibility = useRef();
  const visibility1 = useRef();
  const visibility2 = useRef();
  const [visible, setIsVisible] = useState(false);
  const [visible1, setIsVisible1] = useState(false);
  const [visible2, setIsVisible2] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isDark] = useContext(ThemeContext).isDark;
  const [, setMainActiveTab] = useContext(UserContext).activeTab;
  const [, setProfileUser] = useContext(UserContext).profileUser;
  const [scrolled1, setScrolled1] = useState(false);
  const [scrolled2, setScrolled2] = useState(false);
  const [scrolled3, setScrolled3] = useState(false);
  const activeTabRef = useRef(activeTab);
  const [noMoreActiveUsers, setNoMore] = useState(_noMoreActiveUsers);
  const [noMoreNewUsers, setNoMoreNewUsers] = useState(_noMoreNewUsers);
  const [noMoreOnlineUsers, setNoMoreOnlineUsers] = useState(_noMoreOnlineUsers);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab])

  const lastItemRef = useCallback(function (node) {
    if (node !== null) {
      if (visibility.current) visibility.current.disconnect();

      visibility.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && activeTabRef.current === 0) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
      if (node) visibility.current.observe(node);
    }
  }, []);

  const lastItemRef2 = useCallback(function (node) {
    if (node !== null) {
      if (visibility1.current) visibility1.current.disconnect();

      visibility1.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && activeTabRef.current === 1) {
          setIsVisible1(true);
        } else {
          setIsVisible1(false);
        }
      });
      if (node) visibility1.current.observe(node);
    }
  }, []);

  const lastItemRef3 = useCallback(function (node) {
    if (node !== null) {
      if (visibility2.current) visibility1.current.disconnect();

      visibility2.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && activeTabRef.current === 2) {
          setIsVisible2(true);
        } else {
          setIsVisible2(false);
        }
      });
      if (node) visibility2.current.observe(node);
    }
  }, []);

  useEffect(() => {
    if(visible) fetchMore(setNoMore);
  }, [visible]);

  useEffect(() => {
    if(visible1) fetchMoreOnlineUsers(setNoMoreOnlineUsers);
  }, [visible1]);

  useEffect(() => {
    if(visible2) fetchMoreNewUsers(setNoMoreNewUsers);
  }, [visible2]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const overAllUsersJSX = <>
    {activeUsers?.map((item) => {
      return item && item.grade && (
        <UserListItem key={item.uid} permissionStatus={item.permission_status} notGradient item={item} timeSpent={item.time_spent} containerClassName="mb-2" src={item.profile_url} title={item.name} subTitle={getClassName(item.grade)} onItemClick={(item) => {
          setProfileUser(() => {
            setMainActiveTab(1);
            return item;
          })
        }}/>
      )
    })}
    {!noMoreActiveUsers && <div ref={lastItemRef} className="w-full h-10 flex items-center justify-center text-white text-center">
      <Lottie
        style={{width: '20px', height: '20px', margin: '0 1em 0 0'}} options={{animationData: circularProgress, loop: true}}
      />
    </div>}
  </>

  const onlineUsersJSX = <>
    {onlineUsers && onlineUsers.length > 0 ? onlineUsers?.sort((a, b) => b.online_since - a.online_since)?.map((item) => {

      return item && item.grade && (
        <UserListItem notGradient key={item.uid} permissionStatus={item.permission_status} item={item} timeSpent={item.time_spent} containerClassName="mb-2" src={item.profile_url} title={item.name} subTitle={<Timer initValue={item.online_since} />} onItemClick={(item) => {
          setProfileUser(() => {
            setMainActiveTab(1);
            return item;
          })
        }}/>
      )
    }) : <div className="text-center">
      <Lottie
        options={{ animationData: EmptyBox, loop: false }}
        speed={0.45}
        style={{width: '60%', height: 'unset'}}
      />
      <p className="text-md font-medium text-zinc-900 dark:text-white text-opacity-70">No Active Users Found</p>
      <p className="text-sm text-zinc-900 font-light text-opacity-70 dark:text-white">There are no users active at the moment!</p>
    </div>}
    {!noMoreOnlineUsers && <div ref={lastItemRef2} className="w-full h-10 flex items-center justify-center text-white text-center">
      <Lottie
        style={{width: '20px', height: '20px', margin: '0 1em 0 0'}} options={{animationData: circularProgress, loop: true}}
      />
    </div>}
  </>


  const newUsersJSX = <>
    {newUsers?.map((item) => {

      return item && item.grade && (
        <UserListItem key={item.uid} permissionStatus={item.permission_status} notGradient item={item} timeSpent={item.time_spent} containerClassName="mb-2" src={item.profile_url} title={item.name} subTitle={getClassName(item.grade)} onItemClick={(item) => {
          setProfileUser(() => {
            setMainActiveTab(1);
            return item;
          })
        }}/>
      )
    })}
    {!noMoreNewUsers && <div ref={lastItemRef3} className="w-full h-10 flex items-center justify-center text-white text-center">
      <Lottie
        style={{width: '20px', height: '20px', margin: '0 1em 0 0'}} options={{animationData: circularProgress, loop: true}}
      />
    </div>}
  </>

  return (
    <div className="flex flex-col h-full pb-16 sm:pb-0">
      <div className="flex items-center text-zinc-900 dark:text-white my-3 md:my-0 md:mb-3 px-4">
        {!isSmallScreen && <ArrowBackIos style={{fontSize: '18px'}} className="cursor-pointer text-zinc-900 dark:text-white"
                       onClick={handleBackButton}/>}
        <h2 className="gradient-text outreach flex-1 ml-2 text-xl flowingText font-bold">Outreach</h2>
      </div>
      <div className="w-full transition-all" style={{boxShadow: (activeTab === 0 ? scrolled1 : activeTab === 1 ? scrolled2 : scrolled3) ? '0 10px 12px rgba(0,0,0,0.1)' : 'none'}}>
        <AntTabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <AntTab style={{color: activeTab === 0 ? '#dd2476' : (isDark ? '#fff' : 'rgba(0,0,0,0.5)')}} label="Overall" />
          <AntTab style={{color: activeTab === 1 ? '#dd2476' : (isDark ? '#fff' : 'rgba(0,0,0,0.5)')}} label="Active Now" />
          <AntTab style={{color: activeTab === 2 ? '#dd2476' : (isDark ? '#fff' : 'rgba(0,0,0,0.5)')}} label="New To Class" />
        </AntTabs>
      </div>
      <div className="hide-scrollbar flex-1 overflow-auto px-4">
        <SwipeableViews
          axis="x"
          index={activeTab}
          containerStyle={{
            transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
          }}
          onChangeIndex={(e) => setActiveTab(e)}
          scrolling={"false"}
          ignoreNativeScroll={true}
          disabled={true}
          style={{height: isSmallScreen ? 'calc(100%)' : 'calc(100% - 1rem)'}}
          slideStyle={{overflow: 'hidden !important'}}
        >
          {/*{overAllUsersJSX}*/}
          {/*{onlineUsersJSX}*/}
          <div className="h-full overflow-auto hide-scrollbar divide-y divide-solid divide-zinc-200 dark:divide-slate-800" onScroll={e => {
            const scrollTop = e.target.scrollTop;
            setScrolled1(scrollTop > 5 && activeTab === 0);
            // setScrolled(c => {
            //   c[0] = scrollTop > 5 && activeTab === 0;
            //   console.log('c - ', c);
            //   return c;
            // });
          }}>
            {overAllUsersJSX}
          </div>
          <div className="h-full overflow-auto hide-scrollbar divide-y divide-solid divide-zinc-200 dark:divide-slate-800" onScroll={e => {
            const scrollTop = e.target.scrollTop;
            setScrolled2(scrollTop > 5 && activeTab === 1);
          }}>
            {onlineUsersJSX}
          </div>
          <div className="h-full overflow-auto hide-scrollbar divide-y divide-solid divide-zinc-200 dark:divide-slate-800" onScroll={e => {
            const scrollTop = e.target.scrollTop;
            setScrolled3(scrollTop > 5 && activeTab === 2);
          }}>
            {newUsersJSX}
          </div>
        </SwipeableViews>
      </div>
    </div>
  )

  // return (
  //   <div className="flex flex-col h-full">
  //     <div className="flex items-center text-white">
  //       <ArrowBackIos style={{fontSize: '18px'}} className="cursor-pointer text-zinc-900 dark:text-white" onClick={handleBackButton} />
  //       <div className="flex-1 ml-2 text-xl flowingText font-bold">Active Users</div>
  //     </div>
  //     <div className="[&>*]:mt-3 hide-scrollbar flex-1 mt-4 overflow-auto">
  //
  //     </div>
  //   </div>
  // )
}

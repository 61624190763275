import React, { useEffect, useContext, useState, useRef, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { usePageVisibility } from "react-page-visibility";
// import { useRouter } from 'next/router';
// const Link = dynamic(() => import('next/link'))
// const Head = dynamic(() => import('next/head'))
// const Image = dynamic(() => import('next/image'))
import Modal from "react-modal";
import TopBarProgress from "react-topbar-progress-indicator";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PdfPreview from "../../components/newsfeed/pdf-preview";
import NotesSVG from "../../assets/images/pdf.svg";

import TipsNavbar from "../../components/tips/TipsNavBar";
import TipsPlayer from "../../components/tips/TipsPlayer";
import TipsSidebar from "../../components/tips/TipsSidebar";
import { TipsContext, UserContext } from "../../context";
import { proLogoDark } from "../../assets";
import axios from "axios";
import { db } from "../../firebase_config";
import { baseUrl, getGrade } from "../../helpers";
import { Link, useHistory } from "react-router-dom";
import useQuery from "../../hooks/query/useQuery";
import ShareLecture from "../../components/shareLecture/ShareLecture";
import LecturePlayer from "../../components/LecturePlayer";
import NotesButton from "../../components/global/NotesButton";

TopBarProgress.config({
  barColors: { 0: "#bb281b", "1.0": "#bb281b" },
  shadowBlur: 5,
});

const getTipsEngaggementStatus = async ({ grade, userId, subjectId }) => {
  return await db
    .collection("user_engagement")
    .doc(grade)
    .collection(userId)
    .doc(subjectId)
    .get()
    .then((doc) => (doc.data() || "" ? doc.data().tip_engagement_status : null))
    .catch((_) => null);
};

function youTubeGetID(url) {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

const getVideoId = async ({ grade, subjectId, tipId }) => {
  return await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_tips`)
    .collection("category")
    .doc(subjectId)
    .collection("tip")
    .doc(tipId)
    .get()
    .then((doc) => youTubeGetID(doc.data().youtube_url))
    .catch((_) => null);
};

const getSubjectTips = async ({ grade, subjectId }) => {
  return await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_tips`)
    .collection("category")
    .doc(subjectId)
    .get()
    .then((doc) => doc.data())
    .catch((_) => null);
};

const changeUserGrade = async (userId, grade) => {
  axios
    .post(baseUrl() + "/refreshNotificationSubscriptionList", {
      uid: userId,
    })
    .then();
  return await db
    .collection("users")
    .doc(userId)
    .set({ grade: grade }, { merge: true })
    .then(() => true)
    .catch(() => false);
};

const getUserDailyEngagement = async ({ grade, userId, yearMonth }) => {
  return await db
    .collection("user_engagement")
    .doc("daily_engagement")
    .collection(userId)
    .doc(yearMonth)
    .get()
    .then((doc) => {
      if (doc.exists) return doc.data();
      else return null;
    });
};

export default function TipsScreen() {
  // const router = useRouter();
  const router = useHistory();
  const isMobileScreen = useMediaQuery({ query: "(max-width: 500px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isTabletScreen = useMediaQuery({ query: "(max-width: 1367px)" });

  // const isVisible = usePageVisibility();

  const [videoID, setVideoID] = useContext(TipsContext).videoID;
  const [tipsData, setTipsData] = useContext(TipsContext).tipsData;
  const [activeItem, setActiveItem] = useContext(TipsContext).activeItem;
  const [nextItem, setNextItem] = useContext(TipsContext).nextItem;
  const [notesLink, setNotesLink] = useContext(TipsContext).notesLink;
  const [isNotes, setIsNotes] = useContext(TipsContext).isNotes;
  const [playing, setPlaying] = useContext(TipsContext).playing;
  const [videoSeeking, setVideoSeeking] = useContext(TipsContext).videoSeeking;
  const [beaconBody, setBeaconBody] = useContext(TipsContext).beaconBody;

  const [tipsEngagement, setTipsEngagement] =
    useContext(TipsContext).tipsEngagement;
  const [, setTipTier] = useContext(TipsContext).tipTier;
  const [showOnlyLogo] = useContext(TipsContext).showOnlyLogo;

  const [user, setUser] = useContext(UserContext).user;
  const [isUserProTier] = useContext(UserContext).tier;

  const [userDailyEngagement, setUserDailyEngagement] = useState(null);
  const [tipsSubject, setTipsSubject] = useState(null);
  const [tipsSubjectName, setTipsSubjectName] = useState("");
  const [videoDuration, setVideoDuration] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [totalSpentTime, setTotalSpentTime] = useState(0);
  const [totalTipsWatched, setTotalTipsWatched] = useState(0);
  const [isLastEngagementSent, setIsLastEngagementSent] = useState(false);
  const [tipsEngagementStatus, setTipsEngagementStatus] = useState(null);
  const [interval, setInter] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);
  const [elapsedPercentage, setElapsedPercentage] = useState(0);
  const [tipsWatched, setTipsWatched] = useState([]);
  const [linkGrade, setLinkGrade] = useState(null);

  const tipEngagementRef = useContext(UserContext).lectureEngagementRef;
  const itemIdRef = useRef(null);
  const userIdRef = useRef(user?.uid);
  const userGrade = useRef(user?.grade);

  useEffect(() => {
    userIdRef.current = user?.uid;
  }, [user?.uid]);

  const beaconRef = useRef(beaconBody);

  const getClassName = (grade) => {
    const splitted = grade.split("_");

    return (
      splitted[0].charAt(0).toUpperCase() +
      splitted[0].slice(1) +
      " " +
      splitted[1]
    );
  };

  const query = useQuery();

  useEffect(() => {
    // let _currentURL = new URL(window.location.href);
    let subjectQuery = query.get("subject");
    let tipQuery = query.get("tip");

    if (typeof subjectQuery === "object") subjectQuery = subjectQuery[0];
    if (typeof tipQuery === "object") tipQuery = tipQuery[0];

    console.log("subjectQuery - ", subjectQuery);
    console.log("tipQuery - ", tipQuery);

    if (subjectQuery && tipQuery) {
      setTipsSubject(subjectQuery);
      const _tip = tipQuery;
      userGrade.current = getGrade(_tip);
      setActiveItem({ item: _tip });
      const splitted = _tip.split("_");

      const grade = {
        id: splitted[0] + "_" + splitted[1],
        name: getClassName(_tip),
      };

      if (userGrade.current !== grade.id) {
        setVideoSeeking(false);
      }

      setLinkGrade(grade);
    } else {
      router.push("/");
    }
  }, [query]);

  const handleGradeChange = async (grade) => {
    const prevGrade = user?.grade;

    const updatedUser = { ...user, grade };
    setUser(updatedUser);

    let res = await changeUserGrade(user?.uid, grade);
    if (res) {
      // window.location.reload();
    } else {
      const updatedUser = { ...user, grade: prevGrade };
      setUser(updatedUser);
    }
  };

  useEffect(() => {
    return () => {
      setActiveItem(null);
      setNextItem(null);
      setVideoID(null);
      setTipsData(null);
      setTipsEngagement(null);
      setElapsedTime(0);
      setElapsedPercentage(0);
      setUserDailyEngagement(null);
      setPlaying(false);
      setVideoSeeking(true);
      setTipsSubject(null);
    };
  }, []);

  useEffect(() => {
    if (tipsSubject) {
      getSubjectTipsFn();
      getUserDailyEngagementFn();
    }
  }, [tipsSubject]);

  useEffect(() => {
    if (activeItem) getVideoIdFn();
  }, [activeItem]);

  const getSubjectTipsFn = async () => {
    const res = await getSubjectTips({
      grade: userGrade.current,
      subjectId: tipsSubject,
    });

    setTipsData(res);
    setTipsSubjectName(res?.category_name);
  };

  const getVideoIdFn = async () => {
    if (!activeItem?.item) return;
    const res = await getVideoId({
      grade: userGrade.current,
      subjectId: tipsSubject,
      tipId: activeItem?.item,
    });

    setVideoID(res);
  };

  useEffect(() => {
    if (autoPlay) {
      setActiveItem({ item: nextItem?.item });

      setTipTier(nextItem?.tier === "pro");

      setVideoSeeking(true);
      setAutoPlay(false);
    }
  }, [autoPlay]);

  useEffect(() => {
    if (tipsData) {
      setNextLectureFn();
    }
  }, [tipsData, activeItem]);

  const setNextLectureFn = () => {
    let currentIdx = 0;
    tipsData._meta.map((p, idx) =>
      p.tip_id === activeItem.item ? (currentIdx = idx) : ""
    );

    setNextItem({
      item:
        currentIdx !== tipsData._meta.length - 1
          ? tipsData._meta[currentIdx + 1].tip_id
          : null,
      tier:
        currentIdx !== tipsData._meta.length - 1
          ? tipsData._meta[currentIdx + 1].tier
          : null,
      childName:
        currentIdx !== tipsData._meta.length - 1
          ? tipsData._meta[currentIdx + 1].tip_name
          : null,
    });
  };

  useEffect(() => {
    if (user && tipsSubject) getTipsEngaggementStatusFn();
  }, [user, tipsSubject]);

  const getTipsEngaggementStatusFn = async () => {
    const res = await getTipsEngaggementStatus({
      grade: userGrade.current,
      userId: user?.uid,
      subjectId: tipsSubject,
    });

    setTipsEngagement(res);
  };

  function countUp() {
    setElapsedTime((elapsedTime) => elapsedTime + 1);
  }

  useEffect(() => {
    if (!playing || videoSeeking) {
      clearInterval(interval);
      setInter(null);
    } else if (playing) {
      let interval = setInterval(() => countUp(), 1000);
      setInter(interval);
    }
  }, [playing, videoSeeking, activeItem]);

  useEffect(() => {
    if (elapsedPercentage > 20) setTotalSpentTime(totalSpentTime + elapsedTime);

    setElapsedTime(0);
    setElapsedPercentage(0);
  }, [activeItem]);

  useEffect(() => {
    if (videoDuration > 0)
      setElapsedPercentage((elapsedTime / videoDuration) * 100);
  }, [elapsedTime, videoDuration]);

  useEffect(() => {
    if (elapsedPercentage > 20) {
      if (!tipsWatched.includes(activeItem?.item)) {
        setTotalTipsWatched(totalTipsWatched + 1);

        const _lecturesWatched = [...tipsWatched];
        _lecturesWatched.push(activeItem?.item);
        setTipsWatched(_lecturesWatched);
      }
    }

    let _tipEngagementStatus;

    if (tipsEngagement) {
      let activeItemBody = tipsEngagement[activeItem?.item];

      _tipEngagementStatus = {
        ...tipsEngagement,
        [activeItem?.item]: {
          is_completed: activeItemBody?.is_completed
            ? activeItemBody?.is_completed
            : elapsedPercentage > 20,
          total_viewed_duration: activeItemBody?.total_viewed_duration
            ? elapsedTime + activeItemBody?.total_viewed_duration
            : elapsedTime,
        },
      };
    } else {
      _tipEngagementStatus = {
        ...tipsEngagement,
        [activeItem?.item]: {
          is_completed: elapsedPercentage > 20,
          total_viewed_duration: elapsedTime,
        },
      };
    }

    setTipsEngagementStatus(_tipEngagementStatus);
  }, [activeItem, elapsedTime]);

  useEffect(() => {
    setTipsEngagement(tipsEngagementStatus);
  }, [activeItem]);

  const getUserDailyEngagementFn = async () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const yearMonth = `${year}_${month}`;

    const res = await getUserDailyEngagement({
      grade: userGrade.current,
      userId: user?.uid,
      yearMonth,
    });

    setUserDailyEngagement(res);
  };

  const updateDailyEngagementMap = () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const date = new Date().getDate();
    const yearMonth = `${year}_${month}`;
    const yearMonthDate = `${year}_${month}_${date}`;

    const total_spent_time =
      userDailyEngagement !== null
        ? typeof userDailyEngagement?.daily_engagement !== "undefined"
          ? typeof userDailyEngagement?.daily_engagement[yearMonthDate] !==
            "undefined"
            ? userDailyEngagement?.daily_engagement[yearMonthDate]
                ?.total_spent_time + totalSpentTime
            : totalSpentTime
          : totalSpentTime
        : totalSpentTime;

    const total_watched_lecture_count =
      userDailyEngagement !== null
        ? typeof userDailyEngagement?.daily_engagement !== "undefined"
          ? typeof userDailyEngagement?.daily_engagement[yearMonthDate] !==
            "undefined"
            ? userDailyEngagement?.daily_engagement[yearMonthDate]
                .total_watched_lecture_count + totalTipsWatched
            : totalTipsWatched
          : totalTipsWatched
        : totalTipsWatched;

    let dailyEngagement = {
      [yearMonthDate]: {
        total_spent_time:
          elapsedPercentage > 20
            ? total_spent_time + elapsedTime
            : total_spent_time,
        total_watched_lecture_count,
      },
    };

    return [dailyEngagement, yearMonth];
  };

  useMemo(() => {
    let [dailyEngagement, yearMonth] = updateDailyEngagementMap();

    let body = {
      dailyEngagement,
      yearMonth,
      tipsEngagement: tipsEngagementStatus,
      subjectId: tipsSubject,
      user,
      context: { auth: !!user?.uid },
    };

    localStorage.setItem("tipsBeaconBody", JSON.stringify(body));

    // setBeaconBody(body);
    // beaconRef.current = body;
  }, [elapsedTime]);

  // useEffect(() => {
  // 	function run () {
  // 		const lectureMap = Transform.shapeLectureMap(tipEngagementRef.current);
  //
  // 		const body = {
  // 			uid: userIdRef.current,
  // 			lectureMap,
  // 			context: {
  // 				auth: true
  // 			}
  // 		};
  //
  // 		fetch("http://127.0.0.1:5001/avian-display-193502/asia-east1/updateLectureEngagementData", {
  // 			method: "POST",
  // 			body: JSON.stringify(body),
  // 			headers: {
  // 				'Content-Type': 'application/json'
  // 			},
  // 			keepalive: true
  // 		});
  //
  // 		if(itemIdRef.current && tipEngagementRef.current[itemIdRef.current] && tipEngagementRef.current[itemIdRef.current].length > 0) {
  // 			const newEngagement = tipEngagementRef.current[itemIdRef.current].at(-1).end;
  // 			console.log('tipEngagementRef.current[itemIdRef.current] - ', itemIdRef.current, tipEngagementRef.current[itemIdRef.current]);
  //
  // 			tipEngagementRef.current = {[itemIdRef.current]: [{start: newEngagement, end: newEngagement}]};
  // 		} else {
  // 			tipEngagementRef.current = {};
  // 		}
  // 	}
  //
  // 	// window.addEventListener('unload', run);
  // 	window.addEventListener('visibilitychange', run);
  //
  // 	return () => {
  // 		run();
  // 		// window.removeEventListener('unload', run);
  // 		window.removeEventListener('visibilitychange', run);
  // 	}
  // }, []);

  const handleClickNextItem = (nextItem) => {
    if (!nextItem) return;
    setActiveItem(nextItem);
    const subject = query.get("subject");
    router.push({
      pathname: "/tips",
      query: {
        subject,
        tip: nextItem,
      },
    });
  };

  const tipItem = useMemo(() => {
    if (!tipsData || !activeItem || !tipsData._meta) return null;
    return tipsData._meta.find((c) => c.tip_id === activeItem.item);
  }, [tipsData, activeItem]);

  useEffect(() => {
    itemIdRef.current = tipItem?.tip_id;
  }, [tipItem]);

  // console.log('tipEngagementRef.current - ', tipEngagementRef.current);

  return (
    <div className="classroom__screen__wrapper">
      <ShareLecture tipItem={tipItem} />
      <div className="classroom__topbar">
        {videoSeeking && !showOnlyLogo && <TopBarProgress />}
      </div>

      {/*<Head>*/}
      {/*  <meta charSet="utf-8" />*/}
      {/*  <title>{tipsSubjectName + " Tips | PuStack"}</title>*/}
      {/*</Head>*/}

      {!isMobileScreen && <TipsNavbar title={tipsSubjectName} />}
      <div className="classroom__screen">
        <div className="classroom__content">
          <div className="back__library">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                router.goBack();
              }}
            >
              <ChevronLeftIcon /> <span>Back to Library</span>
            </Link>
            a
          </div>
          {videoID ? (
            <LecturePlayer
              video_id={videoID}
              playing={playing}
              setPlaying={setPlaying}
              nextItem={nextItem}
              setActiveItem={setActiveItem}
              setLectureTier={setTipTier}
              setVideoDuration={setVideoDuration}
              isUserProTier={isUserProTier}
              videoSeeking={videoSeeking}
              handleClickNextItem={handleClickNextItem}
              setVideoSeeking={setVideoSeeking}
              isSmallScreen={isSmallScreen}
              isTabletScreen={isTabletScreen}
              tipEngagementRef={tipEngagementRef}
              setAutoPlay={setAutoPlay}
              itemId={tipItem?.tip_id}
              showOnlyLogo={showOnlyLogo}
            />
          ) : (
            <div className="classroom-player-wrapper">
              <div className="classroom__video__seeking other__grade">
                <div className="classroom__no__video">
                  <img
                    src={proLogoDark}
                    alt="pustack logo"
                    className="no__video"
                    draggable={false}
                  />
                  {/*{user?.grade !== linkGrade?.id && (*/}
                  {/*	<div className="different__grade">*/}
                  {/*		<h4>This content is from {linkGrade?.name}.</h4>*/}
                  {/*		<h5>*/}
                  {/*			Do you wish to change your grade from{" "}*/}
                  {/*			<span>{getClassName(user?.grade)}</span> to{" "}*/}
                  {/*			<span>{linkGrade?.name}</span> ?*/}
                  {/*		</h5>*/}
                  {/*		<div>*/}
                  {/*			<button*/}
                  {/*				className="yes__btn"*/}
                  {/*				onClick={() => handleGradeChange(linkGrade?.id)}*/}
                  {/*			>*/}
                  {/*				Yes*/}
                  {/*			</button>*/}
                  {/*			<button*/}
                  {/*				className="no__btn"*/}
                  {/*				onClick={() => (window.location = "/")}*/}
                  {/*			>*/}
                  {/*				No*/}
                  {/*			</button>*/}
                  {/*		</div>*/}
                  {/*	</div>*/}
                  {/*)}*/}
                </div>
              </div>
            </div>
          )}
          <div className="video-metadata-bar dark">
            <div>
              <h3>{tipsSubjectName}</h3>
              <h1>{tipItem?.tip_name}</h1>
            </div>
            {tipItem?.notes_link && (
              <NotesButton
                onClick={() => {
                  setNotesLink(tipItem?.notes_link);
                  setIsNotes(true);
                }}
                iconSrc={NotesSVG}
                text="View Notes"
              />
            )}
          </div>
        </div>
        <TipsSidebar />

        <Modal
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsNotes(false)}
          ariaHideApp={false}
          className="new-post-modal pdf-preview-modal"
          overlayClassName="new-post-modal-overlay"
          isOpen={!!isNotes}
        >
          <PdfPreview pdf={notesLink} onClose={() => setIsNotes(false)} />
        </Modal>
      </div>
    </div>
  );
}

import React, { useState } from "react";

const NotesButton = ({
  onClick,
  text = "View Notes",
  iconSrc,
  className = "",
  style = {},
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      className={`notes-button-creative ${className}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        padding: "8px 16px 8px 12px",
        background: "linear-gradient(135deg, #2a2a2a 0%, #1a1a1a 100%)",
        border: "none",
        borderRadius: "20px",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 500,
        cursor: "pointer",
        overflow: "hidden",
        boxShadow: isHovered
          ? "0 6px 16px rgba(0, 0, 0, 0.2)"
          : "0 4px 12px rgba(0, 0, 0, 0.15)",
        transform: isHovered ? "translateY(-2px)" : "translateY(0)",
        transition: "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
        ...style,
      }}
    >
      {/* Animated glow effect */}
      <div
        className="button-glow"
        style={{
          position: "absolute",
          top: "-50%",
          left: "-50%",
          width: "200%",
          height: "200%",
          background:
            "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
          opacity: isHovered ? 0.8 : 0.4,
          transition: "opacity 0.3s ease",
          pointerEvents: "none",
        }}
      />

      {/* Paper texture overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage:
            "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPgogIDxmaWx0ZXIgaWQ9Im5vaXNlIj4KICAgIDxmZVR1cmJ1bGVuY2UgdHlwZT0iZnJhY3RhbE5vaXNlIiBiYXNlRnJlcXVlbmN5PSIwLjA1IiBudW1PY3RhdmVzPSIyIiBzdGl0Y2hUaWxlcz0ic3RpdGNoIj48L2ZlVHVyYnVsZW5jZT4KICAgIDxmZUNvbG9yTWF0cml4IHR5cGU9InNhdHVyYXRlIiB2YWx1ZXM9IjAiPjwvZmVDb2xvck1hdHJpeD4KICAgIDxmZUJsZW5kIG1vZGU9Im92ZXJsYXkiIGluMj0iU291cmNlR3JhcGhpYyI+PC9mZUJsZW5kPgogIDwvZmlsdGVyPgogIDxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbHRlcj0idXJsKCNub2lzZSkiIG9wYWNpdHk9IjAuMDUiPjwvcmVjdD4KPC9zdmc+')",
          opacity: "0.5",
          pointerEvents: "none",
        }}
      />

      {/* Icon with custom styling */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          background: "rgba(255, 255, 255, 0.1)",
          padding: "4px",
        }}
      >
        <img
          height={16}
          width={16}
          alt="Button Icon"
          src={iconSrc}
          style={{
            filter: "drop-shadow(0 1px 2px rgba(0,0,0,0.3))",
            transform: isHovered
              ? "rotate(10deg) scale(1.1)"
              : "rotate(0) scale(1)",
            transition: "transform 0.3s ease",
          }}
        />
      </div>

      {/* Text with subtle animation */}
      <span
        style={{
          position: "relative",
          zIndex: 2,
          textShadow: "0 1px 2px rgba(0,0,0,0.2)",
        }}
      >
        {text}
      </span>

      {/* Subtle line decoration */}
      <div
        style={{
          position: "absolute",
          bottom: "6px",
          left: "12px",
          right: "12px",
          height: "1px",
          background:
            "linear-gradient(90deg, transparent, rgba(255,255,255,0.1), transparent)",
          pointerEvents: "none",
        }}
      />
    </button>
  );
};

export default NotesButton;

import axios from "axios";

export const starPath =
  "M93.658,7.186,68.441,60.6,12.022,69.2C1.9,70.731-2.15,83.763,5.187,91.227l40.818,41.557-9.654,58.7c-1.738,10.611,8.959,18.559,17.918,13.6l50.472-27.718,50.472,27.718c8.959,4.922,19.656-2.986,17.918-13.6l-9.654-58.7,40.818-41.557c7.337-7.464,3.282-20.5-6.835-22.029L141.04,60.6,115.824,7.186A12.139,12.139,0,0,0,93.658,7.186Z";

export const VAPIDKEY =
  "BBAS6jVsiEE86EtJvP9RGSkPt46szXb2Ao7pfUdOL0xhhDiiPGnzgwN3utpw_O6RFMbuxgui2d3F7W98jFB5ZWk";

export const agoraAppID = "320f1867bc3d4922b4da5963a9f2b760";

export const PUSHY_SECRET_API_KEY =
  "73572a9672a97ef4af6e38167d99d87825d53642114ff16780f3082f9973c16f";

export const PUSHY_APP_ID = "6155cb14fbd979fe468eab32";

export const FCM_AUTH_HEADER =
  "key=AAAAmhuAeFI:APA91bHSnIpAbEKtDTjvZH1F3xtJvHIlz_m1GOOgzbtZdsiqXpUk7QZvVdKs_9PIbnENdz_ytxaEjFqihGA9MZsW7S6FS1WsOOTDfawNdW_6iVbeduNZOxMVwfEzM69QpHsEVouUQFyd";

export const getFCMAuthToken = async () => {
  const res = await axios.get(
    "https://us-central1-avian-display-193502.cloudfunctions.net/getGoogleAPIAccessToken"
  );

  return res.data.data.success;
};

export const FCM_AUTH_TOKEN =
  "ya29.c.c0ASRK0GbMKtrJVaRT3Bn-dfeo8EDS4V-uWBEa4ZtXCUbWQoYxG8gCjlxshMsmB8Cl_NcQ5NuzdDrzfBOnJv_kz-u0wdBkLmr70VFkFWcbQopIW51vAnqi_6FUQ_RtiYGWObGyDp5UX6GZyg4J5d3xL4uO9lHEIlVsg20LVivACpe7tVsLhKOF-qA6_GzzgUH-yFZ711j6U0VW4EZ3fP3nL-dCrpburpBIYJkiA8AyvyKUxaJDAxoxNb3tqmnBqbOPD8Jn1x6zDRqeK-V5Tvh0V-PtIToX1-2JEUPFMdrCh0edaOs47x2K-pboCkzsy40uIvPqCRBdKwTqLSXBTo2IfxWEQ6D7KVSTyd4cD8Ig3QVJUKaflBSHxj0UN385Dn3j7Rje-wuBdii7eysdjJq7fRY89bdQxp0kklXd1mZVsSIySObXjSm6Rfr9_BnjirbIt6QtgSW1heBBj5l_9at2fOJl3lkRYreVScr_d0tYsc-tUF7uoc7iI17ghsn8vo8jsm0Zdf3d08SteYv2rIJgvYOQwW8e2Bg5YpkVs5j_4hcUxRIp2l8mj2i8hVXF92i1jt2ik_91BZr6cgsxVuzUYBJI15sxwMxhQbI8JnkvbiVxjmhRv1xSxJbnmipi0cdzb8mdo2zrvSFoI7v76k5iVI3Moo7qIoe4on604zyn2W08OmfZoQMjWqogp-ox5F3Iw7OySMeXUZg4nwjgvs9QWyyZ4blZr5O58M90hIU1qOJMOw2-mnuqf8nR-gw0jzrqBYMsJyppguX7tc2i2M4gIbrx0xjanbhvRiU3iWfFj-BmUmO2-fM8eWkuI1cndyXnr1u47hV_jMfhJlVkd-lzXvRnxZtMF26jYhSogdd2Vh4JqBwcor_Jf61FWQbkgOYFw4UlWcvFJWOSyVt4t1dhos5snzhQp3Fa7R7ewi9bZ2nW9yj8InUUXBviFx1ZUXBi4bYf2rSdz47tXUMvouypck9Rr17833dOXlyUR_-bv1e2t3zvzji-3x";

export const firebaseAPiKey = "AIzaSyCgfeFcXVvvuIp79IJD8KCahJo2PzrHDco";

export const appGooglePlayLink =
  "https://play.google.com/store/apps/details?id=com.pustack.android.pustack&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";

export const appGooglePlayTutorLink =
  "https://play.google.com/store/apps/details?id=com.pustack.android.pustacktutor";

export const appAppleLink = "https://apps.apple.com/app/pustack/id6444080075";

export const appAppleTutorLink =
  "https://apps.apple.com/in/app/pustack-tutor/id6444847124";

export const googlePlayBadge =
  "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png";

export const firebaseDbURL = "https://avian-display-193502.firebaseio.com";

export const firebaseFunctionBaseURL =
  "https://us-central1-avian-display-193502.cloudfunctions.net/";

export const miniChartData = [
  {
    date: 1,
    hours: 1,
    sessions: 15,
    earnings: 400,
  },
  {
    date: 2,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 3,
    hours: 2.5,
    sessions: 15,
    earnings: 600,
  },
  {
    date: 4,
    hours: 3,
    sessions: 12,
    earnings: 480,
  },
  {
    date: 5,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 6,
    hours: 2,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 7,
    hours: 2.5,
    sessions: 30,
    earnings: 1200,
  },
  {
    date: 8,
    hours: 3,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 9,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 10,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 11,
    hours: 2,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 12,
    hours: 2.5,
    sessions: 30,
    earnings: 1200,
  },
  {
    date: 13,
    hours: 3,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 14,
    hours: 3,
    sessions: 15,
    earnings: 600,
  },
  {
    date: 15,
    hours: 3,
    sessions: 16,
    earnings: 640,
  },
  {
    date: 16,
    hours: 2,
    sessions: 20,
    earnings: 1000,
  },
  {
    date: 17,
    hours: 2,
    sessions: 22,
    earnings: 880,
  },
  {
    date: 18,
    hours: 3,
    sessions: 35,
    earnings: 1200,
  },
  {
    date: 19,
    hours: 3.5,
    sessions: 38,
    earnings: 1500,
  },
];

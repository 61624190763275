import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Modal from "react-modal";
import { format } from "date-fns";
import Lottie from "lottie-react-web";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { castIndianTime, getYoutubeID } from "../../../helpers";
import SwipeableViews from "react-swipeable-views";
import { BlazeRequestCard } from "../../../containers";
import ConnectIcon from "../../../assets/connect.svg";
import { UserContext, ThemeContext } from "../../../context";
import EmptyBox from "../../../assets/lottie/empty-box.json";
import WatchLater from "@material-ui/icons/WatchLaterRounded";
import { VideoCameraRounded, OpenInFull } from "../../../assets";
import AccountSettings from "../../global/navbar/account-settings";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeftRounded";
import ArrowRight from "@material-ui/icons/KeyboardArrowRightRounded";
import AccountBalance from "@material-ui/icons/AccountBalanceWalletRounded";
import BlazeIcon from "../../../assets/images/icons/flash.svg";
import {
  NumberMeter,
  OngoingCard,
  DashboardSkillsList,
  DetailedChart,
} from "../../../components";
import MiniChart from "../../../components/global/miniChart";
import EventAvailableIcon from "@material-ui/icons/EventAvailableRounded";
import YoutubeEmbed from "../../../components/doubts_forum/youtube-embed";
import BlazeCardLoader from "../../../components/blazeExternal/blazeCardLoader";
import {
  getSkillsRating,
  getBlazeRequests,
  getInstructorSkills,
  getTutorAccepted,
  getInstructorEngagementFor30days,
  getInstructorLifetimeEngagement,
} from "../../../database";
import "./style.scss";
import { fetchStats } from "../../../database/blazeExternal";
import LearnSection from "../../../components/home/learn-section";
import Navbar from "../../global/navbar";
import { useMediaQuery } from "react-responsive";
import VimeoPlayer from "../../../components/global/vimeo-player";
import SkillRowShimmer from "../../../components/blazeExternal/dashboardSkillsList/skillRowShimmer";
import TabSlider from "../../../components/global/tab-slider";
import * as tus from "tus-js-client";
import { Vimeo } from "@vimeo/vimeo";
import { StoryListItem } from "../sidebar";

const accessTokenRonak = "eb3aa30f683094b5e51d077a9b8bbff5";
const accessTokenMine = "f860453a7d050ec8ee88c55f0200bd45";

const client = new Vimeo(
  "bfd90325097d659892ffd962095c3bb6e327a858",
  "CSzOx+sl/el6HaeJEvvS8oitSYxk+AyyrxK6kujzhhPBuKURQYoHkc3fp67CrcY/cjmbseOW1PtqThATpmijay6vlNc2q4K1IfWhmW+7plb7gdnWdZrxuboYHGptrUYm",
  accessTokenRonak
);

const TutorDashboard = ({ openSidebar }) => {
  const history = useHistory();
  const [user] = useContext(UserContext).user;
  const [tutorEarnings] = useContext(UserContext).tutorEarnings;
  const [showDrawer, setShowDrawer] = useContext(UserContext).showDrawer;
  const [isDark] = useContext(ThemeContext).theme;

  const [chartData, setChartData] = useState(null);
  const [grade, setGrade] = useState("class_7");
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  const [earnings, setEarnings] = useState(0);
  const [earnings2, setEarnings2] = useState(0);
  const [activeTab, _setActiveTab] = useState(0);
  const [activeChartTab, setActiveChartTab] = useState(0);
  const [chartDate, setChartDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dataKey, setDataKey] = useState("earnings");
  const [callDuration, setCallDuration] = useState(0);
  const [callDuration2, setCallDuration2] = useState(0);
  const [sessionsCount, setSessionsCount] = useState(0);
  const [sessionsCount2, setSessionsCount2] = useState(0);
  const [ongoingSessions, setOngoingSessions] = useState(null);
  const [sessionSelected, setSessionSelected] = useState(null);
  const [instructorSkills, setInstructorSkills] =
    useContext(UserContext).instructorSkills;
  const [requestedSessions, setRequestedSessions] = useState(null);
  const [instructorSkillsRating, setInstructorSkillsRating] =
    useContext(UserContext).instructorSkillsRating;
  const [currMonthData, setCurrMonthData] = useState(null);
  const [prevMonthData, setPrevMonthData] = useState(null);
  const [days30ChartData, setDays30ChartData] = useState(null);
  const [videoList] = useContext(UserContext).dashboardVideos;
  const [enableSwitch, setEnableSwitch] = useState(true);
  const requestsHeadingRef = useRef(null);
  const requestsScrollContainerRef = useRef(null);

  console.log("videoList - ", videoList);

  useEffect(() => {
    if (!requestsHeadingRef.current || !requestsScrollContainerRef.current)
      return;

    // Check if the scroll container is scrolled a bit and then if it is then add the scrolled class on requestsHeadingRef
    const scrollHandler = () => {
      if (requestsScrollContainerRef.current.scrollTop > 10) {
        requestsHeadingRef.current.classList.add("scrolled");
      } else {
        requestsHeadingRef.current.classList.remove("scrolled");
      }
    };

    requestsScrollContainerRef.current.addEventListener(
      "scroll",
      scrollHandler
    );

    return () => {
      requestsScrollContainerRef.current.removeEventListener(
        "scroll",
        scrollHandler
      );
    };
  }, []);

  useEffect(() => {
    setShowDrawer(null);
  }, []);

  // useEffect(() => {
  //   db.collection('admin_videos')
  //     .doc('pustack_tutor_app')
  //     .get()
  //     .then(async (snapshot) => {
  //       if(snapshot.exists) {
  //         const data = snapshot.data();
  //         let videoList = [];
  //         for(let i = 0; i < data.videos.home_video_list.length; i++) {
  //           let c = data.videos.home_video_list[i];
  //           const {url} = await getVimeoURL(c.video_id);
  //           videoList.push({
  //             ...c,
  //             url
  //           })
  //         }
  //         setVideoList(videoList);
  //         setLoadingVideoList(false);
  //       }
  //     })
  // }, []);

  const setActiveTab = (val) => {
    if (!enableSwitch) return;
    _setActiveTab(val);
  };

  const [durationObj, setDurationObj] = useState({
    name: "Last 30 days",
    index: 0,
    month: null,
    year: null,
  });

  const [file, setFile] = useState(null);
  const uploadToVimeo = async function () {
    if (!file) return;

    console.log("Vimeo debug == ", "Making api call to /me/videos", file);
    const response = await fetch("https://api.vimeo.com/me/videos", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessTokenRonak}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        upload: {
          approach: "tus",
          size: file.size,
        },
        folder_uri: "https://vimeo.com/manage/folders/18980901",
        name: "class_saurabh_" + Date.now().toString(),
      }),
    });

    const data = await response.json();

    console.log("Vimeo debug == ", "Response from /me/videos", data);

    // return;
    const uploadLink = data.upload.upload_link;

    //
    // await fetch(uploadLink, {
    //   method: 'PATCH',
    //   headers: {
    //     'Authorization': `Bearer f860453a7d050ec8ee88c55f0200bd45`,
    //     'Tus-Resumable': '1.0.0',
    //     'Upload-Offset': '0',
    //     'Content-Type': 'application/offset+octet-stream',
    //   },
    //   body: JSON.stringify({
    //     upload: {
    //       approach: 'tus',
    //       size: file.size,
    //     },
    //   }),
    // });
    //
    // // Create a new tus upload
    const upload = new tus.Upload(file, {
      // endpoint: uploadLink,
      uploadUrl: uploadLink,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: function (error) {
        console.log("Vimeo debug == ", "Failed because: " + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(
          "Vimeo debug == ",
          bytesUploaded,
          bytesTotal,
          percentage + "%"
        );
      },
      onSuccess: function () {
        console.log("Vimeo debug == ", "Download %s from %s", upload, data.uri);
        completeUpload(data.uri);
      },
    });
    upload.start();
    //
    // // Check if there are any previous uploads to continue.
    // upload.findPreviousUploads().then(function (previousUploads) {
    //   // Found previous uploads so we select the first one.
    //   if (previousUploads.length) {
    //     upload.resumeFromPreviousUpload(previousUploads[0])
    //   }
    //
    //   // Start the upload
    //   upload.start()
    // })

    // let continueLoop = true;
    // let uploadOffset = '0';
    // let i = 0;
    //
    // while(continueLoop) {
    //   console.log('Vimeo debug == ', 'Making api call PATCH to uploadLink', uploadLink);
    //   {
    //     const response = await fetch(uploadLink, {
    //       method: 'PATCH',
    //       headers: {
    //         'Authorization': `Bearer ${accessTokenRonak}`,
    //         'Tus-Resumable': '1.0.0',
    //         'Upload-Offset': uploadOffset,
    //         'Content-Type': 'application/offset+octet-stream',
    //         'Tus-Max-Size': '5000',
    //         // 'Upload-Length': file.size,
    //       },
    //       body: JSON.stringify({
    //         upload: {
    //           approach: 'tus',
    //           size: file.size,
    //         },
    //       }),
    //     });
    //
    //     uploadOffset = response.headers.get('Upload-Offset');
    //     console.log('Vimeo debug == ', 'Progress - ', uploadOffset, (uploadOffset / file.size * 100).toFixed(4));
    //   }
    //
    //
    //   if(uploadOffset >= file.size) {
    //     continueLoop = false;
    //     break;
    //   }
    //   i++;
    // }
    //
    // completeUpload(data.uri);

    function completeUpload(videoUri) {
      fetch(videoUri, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessTokenRonak}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "Video by saurabh",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Vimeo debug == ", "Upload completed successfully!");
          console.log("Vimeo debug == ", "Video URI:", data.uri);
        })
        .catch((error) => {
          console.error("Vimeo debug == ", `Error completing upload: ${error}`);
        });
    }
  };

  const removeSessionFromRequestedSessionList = (id) => {
    let requestArr = _requestedSessions.current.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );
    _requestedSessions.current = requestArr.filter((c) => c.id !== id);
    setRequestedSessions(
      _requestedSessions.current.sort((b, c) => {
        if (c.requested_ts < b.requested_ts) return -1;
        if (c.requested_ts > b.requested_ts) return 1;
        return 0;
      })
    );
  };

  const formatDate = (date) => {
    if (date) {
      const splitted = date?.split("_");
      const year = splitted[0];
      const month = splitted[1] - 1;
      const d = splitted[2];
      return format(new Date(year, month, d), "MMM, do yyyy");
    } else return format(new Date(), "MMM, do yyyy");
  };

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month - 1, 1);
    let days = [];
    while (date.getMonth() === month - 1) {
      days.push(`${year}_${month}_${new Date(date).getDate()}`);
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const _requestedSessions = useRef([]);

  const getNewRequests = () => {
    if (instructorSkills?.length > 0) {
      let skillBatches = [],
        batchSize = 10;

      for (let i = 0; i < instructorSkills.length; i += batchSize) {
        skillBatches.push(
          instructorSkills.slice(i, i + batchSize)?.map((skill) => skill?.id)
        );
      }

      _requestedSessions.current = [];

      skillBatches.map((batch) => {
        getBlazeRequests({
          instructorSkills: batch,
          callback: (_requests, _removedFromArray) => {
            _requestedSessions.current = [
              ..._requestedSessions.current,
              ..._requests,
            ];
            let requestArr = _requestedSessions.current.filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            );

            const perc = (_removedFromArray.length / requestArr.length) * 100;
            const threshold = 0;
            if (perc >= threshold) {
              requestArr = requestArr.filter((item) => {
                return !_removedFromArray.some((c) => c.id === item.id);
              });
              _removedFromArray = [];
            } else {
              requestArr = requestArr.map((item) => {
                if (_removedFromArray.find((c) => c.id === item.id)) {
                  return { ...item, noMoreAvailable: true };
                }
                return item;
              });
            }

            setRequestedSessions(
              requestArr.sort((b, c) => {
                if (c.requested_ts < b.requested_ts) return -1;
                if (c.requested_ts > b.requested_ts) return 1;
                return 0;
              })
            );
          },
        });
      });
    }
  };

  const getInstructorSkillsFn = () => {
    getInstructorSkills({
      instructorId: user?.uid,
      callback: (_skills) => setInstructorSkills(_skills),
    });
  };

  const getBlazeOngoing = () => {
    return getTutorAccepted({
      instructorId: user?.uid,
      limit: isSmallScreen ? 100 : 3,
      callback: (_bookings) => setOngoingSessions(_bookings),
    });
  };

  useEffect(() => {
    let prom = () => {};
    if (user?.uid) {
      prom = getBlazeOngoing();
      getInstructorSkillsFn();
      getSkillsRatingFn();
    }
    return () => {
      prom();
    };
  }, [user?.uid]);

  useEffect(() => {
    getNewRequests();
  }, [instructorSkills]);

  const onMouseLeave = () => {
    setTimeout(() => {
      setCallDuration(callDuration2);
      setChartDate(null);
      setEarnings(earnings2);
      setSessionsCount(sessionsCount2);
    }, 50);
  };

  const moveForward = () =>
    activeTab < videoList.length - 1 && setActiveTab((c) => c + 1);
  const moveBackward = () => activeTab > 0 && setActiveTab((c) => c - 1);

  const getSkillsRatingFn = async () => {
    const res = await getSkillsRating({ instructorId: user?.uid });

    setInstructorSkillsRating(res);
  };

  const last30Days = async (inHash) => {
    const today = await castIndianTime();

    let dates = [];
    for (let i = 0; i < 31; i++) {
      const priorDate = new Date().setDate(today.getDate() - i);
      const date = new Date(priorDate).getDate();
      const month = new Date(priorDate).getMonth() + 1;
      const year = new Date(priorDate).getFullYear();
      if (inHash) dates.push({ year, month, date });
      else dates.push(year + "_" + month + "_" + date);
    }

    return dates.reverse();
  };

  useEffect(() => {
    user?.uid && last30DaysEngagement();
  }, [user?.uid]);

  const last30DaysEngagement = () => {
    const currentYear = new Date().getFullYear().toString();
    const prevYear = new Date(Number(currentYear), new Date().getMonth() - 1, 1)
      .getFullYear()
      .toString();

    const currentMonth =
      currentYear + "_" + (new Date().getMonth() + 1).toString();

    const prevMonth =
      prevYear +
      "_" +
      (
        new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() + 1
      ).toString();

    getInstructorEngagementFor30days({
      instructorId: user?.uid,
      prevYear,
      prevMonth,
      currentYear,
      currentMonth,
      prevCallback: (data) => {
        setPrevMonthData(data);
      },
      currCallback: (data) => {
        setCurrMonthData(data);
      },
    });
  };

  useEffect(() => {
    setChartDataFn();
  }, []);

  const setChartDataFn = async () => {
    // const _last30Days = await last30Days(true);
    //
    // const startDate = _last30Days.at(0);
    // const endDate = _last30Days.at(-1);
    //
    // console.log('startDate, endDate - ', startDate, endDate);
    //
    // fetchStats(user.uid, startDate, (data) => {
    //   console.log('startDate - data - ', data);
    // })
    //
    // fetchStats(user.uid, endDate, (data) => {
    //   console.log('endDate - data - ', data);
    // })

    // console.log('_last30days inHash - ', _last30Days);

    if (tutorEarnings?.length > 0) {
      let _earnings = 0;
      let _pendingEarnings = 0;

      const _last30Days = await last30Days();

      let _daySessionsCount = [];
      let _dayEarnings = [];

      let _dailyEarnings = {};

      let _chartData = [];

      tutorEarnings.map((order) => {
        const dailyEarnings = order?.daily_earnings;
        _dailyEarnings = { ..._dailyEarnings, ...dailyEarnings };

        for (let k in dailyEarnings) {
          _daySessionsCount.push({
            date: k,
            sessions: Object.keys(dailyEarnings[k]).length,
          });

          let dayWiseData = dailyEarnings[k];

          let oneDayEarning = 0;

          for (let session in dayWiseData) {
            _earnings += dayWiseData[session]?.amount || 0;
            oneDayEarning += dayWiseData[session]?.amount || 0;

            if (
              dayWiseData[session]?.status &&
              dayWiseData[session]?.status !== "accounted"
            ) {
              _pendingEarnings += dayWiseData[session]?.amount;
            }
          }
          _dayEarnings.push({ date: k, earnings: oneDayEarning });
        }
      });

      for (let i = 0; i < 31; i++) {
        let date = _last30Days[i];

        if (_dailyEarnings[date]) {
          const dayEarnings = _dayEarnings.filter((d) => d.date === date)[0]
            .earnings;

          const daySessions = _daySessionsCount.filter(
            (d) => d.date === date
          )[0].sessions;

          _chartData.push({
            date: formatDate(date),
            hours: dayEarnings / (100 * 60),
            sessions: daySessions,
            earnings: dayEarnings,
          });
        } else {
          _chartData.push({
            date: formatDate(date),
            hours: 0,
            sessions: 0,
            earnings: 0,
          });
        }
      }

      setChartData(_chartData);
      setDays30ChartData(_chartData);
    }
  };

  useEffect(() => {
    setMonthDataFn();
  }, [currMonthData, prevMonthData]);

  const setMonthDataFn = async () => {
    if (currMonthData || prevMonthData) {
      let _sessionsCount = 0;
      let _earnings = 0;
      let _duration = 0;
      const _last30Days = await last30Days();

      let _chartData = [];

      let _dailyEarnings = {
        ...(currMonthData || {}),
        ...(prevMonthData || {}),
      };

      for (let i = 0; i < 31; i++) {
        let date = _last30Days[i];
        if (_dailyEarnings[date]) {
          _duration += _dailyEarnings[date].call_duration || 0;
          _sessionsCount += _dailyEarnings[date].session_count || 0;
          _earnings += _dailyEarnings[date].estimated_earning || 0;

          _chartData.push({
            date: formatDate(date),
            hours: _dailyEarnings[date].call_duration / (60 * 60) || 0,
            sessions: _dailyEarnings[date].session_count || 0,
            earnings: _dailyEarnings[date].estimated_earning || 0,
          });
        } else {
          _chartData.push({
            date: formatDate(date),
            hours: 0,
            sessions: 0,
            earnings: 0,
          });
        }
      }

      setChartData(_chartData);
      setDays30ChartData(_chartData);

      setSessionsCount(_sessionsCount || 0);
      setSessionsCount2(_sessionsCount || 0);
      setEarnings(_earnings);
      setEarnings2(_earnings);
      setCallDuration(_duration / (60 * 60));
      setCallDuration2(_duration / (60 * 60));
    }
  };

  useEffect(() => {
    if (durationObj.index === 0) {
      setChartData(days30ChartData);
    }
    if (durationObj.index === 6) {
      getInstructorLifetimeEngagementFn();
    }

    if (!durationObj.month) return;
    const unsub = fetchStats(user.uid, durationObj, (data) => {
      console.log("data - ", data);
      if (!data) {
        data = {};
      }
      const _daysInMonth = getDaysInMonth(durationObj.month, durationObj.year);
      let _daySessionsCount = [];
      let _dayEarnings = [];

      let _dailyEarnings = {};

      let _chartData = [];

      for (let i = 0; i < _daysInMonth.length; i++) {
        let date = _daysInMonth[i];

        if (data[date]) {
          _chartData.push({
            date: formatDate(date),
            hours: data[date].call_duration
              ? data[date].call_duration / (100 * 60)
              : 0,
            sessions: data[date].session_count ?? 0,
            earnings: data[date].estimated_earning ?? 0,
          });
        } else {
          _chartData.push({
            date: formatDate(date),
            hours: 0,
            sessions: 0,
            earnings: 0,
          });
        }
      }

      setChartData(_chartData);
    });

    // if (tutorEarnings?.length > 0 && durationObj.month) {
    //   const _daysInMonth = getDaysInMonth(durationObj.month, durationObj.year);
    //   let _daySessionsCount = [];
    //   let _dayEarnings = [];
    //
    //   let _dailyEarnings = {};
    //
    //   let _chartData = [];
    //
    //   tutorEarnings.map((order) => {
    //     if (order.id === durationObj.year + "_" + durationObj.month) {
    //       _dailyEarnings = order?.daily_earnings;
    //     }
    //
    //     for (let k in _dailyEarnings) {
    //       _daySessionsCount.push({
    //         date: k,
    //         sessions: Object.keys(_dailyEarnings[k]).length,
    //       });
    //
    //       let dayWiseData = _dailyEarnings[k];
    //
    //       let oneDayEarning = 0;
    //
    //       for (let session in dayWiseData) {
    //         oneDayEarning += dayWiseData[session]?.amount || 0;
    //       }
    //       _dayEarnings.push({ date: k, earnings: oneDayEarning });
    //     }
    //   });
    //
    //   for (let i = 0; i < _daysInMonth.length; i++) {
    //     let date = _daysInMonth[i];
    //
    //     if (_dailyEarnings[date]) {
    //       const dayEarnings = _dayEarnings.filter((d) => d.date === date)[0]
    //         .earnings;
    //
    //       const daySessions = _daySessionsCount.filter(
    //         (d) => d.date === date
    //       )[0].sessions;
    //
    //       _chartData.push({
    //         date: formatDate(date),
    //         hours: dayEarnings / (100 * 60),
    //         sessions: daySessions,
    //         earnings: dayEarnings,
    //       });
    //     } else {
    //       _chartData.push({
    //         date: formatDate(date),
    //         hours: 0,
    //         sessions: 0,
    //         earnings: 0,
    //       });
    //     }
    //   }
    //
    //   console.log('durationObj - ', durationObj, _chartData);
    //   setChartData(_chartData);
    // }
    return () => {
      typeof unsub === "function" && unsub();
    };
  }, [durationObj]);

  const getInstructorLifetimeEngagementFn = async () => {
    const lifetimeData = await getInstructorLifetimeEngagement({
      instructorId: user?.uid,
    });

    const sortFn = (a, b) => {
      let id1 = a[0].split("_");
      let id2 = b[0].split("_");

      if (Number(id1[0]) >= Number(id2[0])) {
        if (Number(id1[1]) >= Number(id2[1])) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    };

    const sorted = lifetimeData.map((yearData) => {
      let _yearData = Object.entries(yearData);
      let newData = _yearData.slice(0, _yearData.length - 1).sort(sortFn);

      return newData;
    });
    let _chartData = [];

    sorted.map((year) =>
      year.map((month) => {
        let id = month[0].split("_");

        _chartData.push({
          date: format(
            new Date(Number(id[0]), Number(id[1]) - 1, 1),
            "MMMM, YYY"
          ),
          hours: month[1].call_duration,
          sessions: month[1].session_count,
          earnings: month[1].estimated_earning,
        });
      })
    );
    console.log("chartData - ", _chartData);

    setChartData(_chartData);
  };

  return (
    <div>
      {isSmallScreen ? (
        <Navbar
          handleMenuClick={() => {
            openSidebar();
          }}
        />
      ) : (
        <div className="tutor-dashboard-navbar">
          <div className="tutor-dashboard-title">
            <MenuIcon onClick={openSidebar} />
            <h1>Home</h1>
            {/*<div className={'flex items-center'}>*/}
            {/*  <input onChange={e => setFile(e.target.files[0])} type="file"/>*/}
            {/*  <button onClick={uploadToVimeo}>Submit</button>*/}
            {/*</div>*/}
          </div>
          <div className="flex items-center">
            <AccountSettings profileVisibility={true} />
          </div>
        </div>
      )}
      <div className="tutor-dashboard-wrapper">
        <div className="tutor-dashboard">
          <div className="tutor-dashboard-analytics">
            {isSmallScreen && <StoryListItem />}
            <div className="upcoming-sessions-board">
              <div
                className={
                  "upcoming-sessions" + (isSmallScreen ? " text-white" : "")
                }
              >
                <div className="upcoming-calendar">
                  <EventAvailableIcon />
                </div>
                <div className="ongoing-session-number">
                  <div>
                    <div className="upcoming-calendar">
                      <EventAvailableIcon />
                    </div>
                    <h2>
                      {ongoingSessions?.length
                        ? ongoingSessions?.length > 9
                          ? ongoingSessions?.length
                          : "0" + ongoingSessions?.length
                        : "00"}
                    </h2>
                  </div>
                  <h1 className="text-white">
                    Session{ongoingSessions?.length > 1 ? "s" : ""}
                    <h5>In Progress</h5>
                  </h1>
                </div>
              </div>
              {isSmallScreen && (
                <div
                  className="explore-button"
                  onClick={() => {
                    ongoingSessions?.length > 0
                      ? history.push("/sessions")
                      : history.push("/blaze");
                  }}
                >
                  {/*<img src={BlazeIcon} alt=""/>*/}
                  <span>See All</span>
                </div>
              )}
              <div
                className="ongoing-sessions-list fadeIn"
                style={{
                  marginRight: ongoingSessions?.length === 3 ? "-60px" : 0,
                }}
              >
                {ongoingSessions?.map((item) => (
                  <div onClick={() => history.push(`/blaze/chat/${item.id}`)}>
                    <OngoingCard
                      skill={item.skill}
                      title={item.topic}
                      studentName={item.student_name}
                      gradient={item.subject_color_gradient}
                      studentImage={item.student_profile_pic}
                      studentId={item.student_id}
                      sessionId={item.id}
                    />
                  </div>
                ))}
                {ongoingSessions?.length === 3 && (
                  <div className="show-all-btn">
                    <div
                      className="show-all-content"
                      onClick={() => history.push("/blaze")}
                    >
                      <h1>See all</h1>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isSmallScreen && ongoingSessions?.length > 0 && (
              <div className="flex mt-2 py-4 overflow-auto">
                {ongoingSessions?.map((item) => (
                  <div
                    className="flex-shrink-0 mr-2"
                    onClick={() => history.push(`/blaze/chat/${item.id}`)}
                  >
                    <OngoingCard
                      skill={item.skill}
                      title={item.topic}
                      studentName={item.student_name}
                      gradient={item.subject_color_gradient}
                      studentImage={item.student_profile_pic}
                      studentId={item.student_id}
                      sessionId={item.id}
                    />
                  </div>
                ))}
              </div>
            )}
            {isSmallScreen && (
              <div className={"flex mt-3 mx-2 justify-between items-center"}>
                <h2 className="text-xl text-black dark:text-white">
                  Analytics
                </h2>
                <div className="flex text-black dark:text-white items-center opacity-60 text-xs">
                  <EventAvailableIcon />
                  <p className="ml-1">Last 30 days</p>
                </div>
              </div>
            )}
            <div className="tutor-month">
              <div
                className={
                  "tutor-hours " +
                  (isSmallScreen && activeChartTab === 0 ? " active" : "")
                }
                onMouseLeave={onMouseLeave}
                onClick={() => {
                  if (isSmallScreen) {
                    setActiveChartTab(0);
                  } else {
                    setDataKey("hours");
                    setOpenModal(true);
                  }
                }}
              >
                <div className="container-legend duration">
                  <h4>
                    <WatchLater />
                    <span>Call Duration</span>
                  </h4>
                  <h2>
                    <NumberMeter
                      value={
                        callDuration < 1 ? callDuration * 60 : callDuration
                      }
                      useDecimal={true}
                    />{" "}
                    <span className="hours">
                      {callDuration < 1 ? "m" : "h"}
                    </span>
                  </h2>
                  <h6>{chartDate ? "on " + chartDate : "last 30 days"} </h6>
                </div>
                <div className="mini-chart">
                  <div className="mini-chart-overlay" />
                  <MiniChart
                    key="hours"
                    type="hours"
                    color="#8884d8"
                    data={chartData || []}
                    setTypeData={setCallDuration}
                    onMouseLeave={onMouseLeave}
                    setChartDate={setChartDate}
                  />
                  <div className="open-in-full">
                    <OpenInFull />
                  </div>
                </div>
              </div>
              <div
                className={
                  "tutor-sessions " +
                  (isSmallScreen && activeChartTab === 1 ? " active" : "")
                }
                onMouseLeave={onMouseLeave}
                onClick={() => {
                  if (isSmallScreen) {
                    setActiveChartTab(1);
                  } else {
                    setDataKey("sessions");
                    setOpenModal(true);
                  }
                }}
              >
                <div className="container-legend sessions">
                  <h4>
                    <VideoCameraRounded />
                    <span>Sessions</span>
                  </h4>
                  <h2>
                    <NumberMeter value={sessionsCount} useDecimal={false} />
                  </h2>
                  <h6>{chartDate ? "on " + chartDate : "last 30 days"} </h6>
                </div>
                <div className="mini-chart">
                  <div className="mini-chart-overlay" />
                  <MiniChart
                    key="sessions"
                    type="sessions"
                    color="#11993f"
                    data={chartData || []}
                    onMouseLeave={onMouseLeave}
                    setChartDate={setChartDate}
                    setTypeData={setSessionsCount}
                  />
                  <div className="open-in-full">
                    <OpenInFull />
                  </div>
                </div>
              </div>
              <div
                className={
                  "tutor-earnings " +
                  (isSmallScreen && activeChartTab === 2 ? " active" : "")
                }
                onMouseLeave={onMouseLeave}
                onClick={() => {
                  if (isSmallScreen) {
                    setActiveChartTab(2);
                  } else {
                    setDataKey("earnings");
                    setOpenModal(true);
                  }
                }}
              >
                <div className="container-legend earnings">
                  <h4>
                    <AccountBalance />
                    <span>Earnings</span>
                  </h4>
                  <h2>
                    ₹{" "}
                    <NumberMeter
                      value={earnings}
                      useDecimal={false}
                      onMouseLeave={onMouseLeave}
                    />
                  </h2>
                  <h6>{chartDate ? "on " + chartDate : "last 30 days"} </h6>
                </div>
                <div className="mini-chart">
                  <div className="mini-chart-overlay" />
                  <MiniChart
                    key="earnings"
                    type="earnings"
                    color="#ffc658"
                    data={chartData || []}
                    setTypeData={setEarnings}
                    setChartDate={setChartDate}
                    onMouseLeave={onMouseLeave}
                  />
                  <div className="open-in-full">
                    <OpenInFull />
                  </div>
                </div>
              </div>
            </div>
            {isSmallScreen && (
              <div style={{ height: "200px" }}>
                <SwipeableViews
                  axis={"x"}
                  index={activeChartTab}
                  scrolling={"true"}
                  ignoreNativeScroll={true}
                  containerStyle={{
                    transition:
                      "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                  }}
                  style={{ height: "100%" }}
                  className={"on-dashboard-chart"}
                  onChangeIndex={(e) => setActiveChartTab(e)}
                >
                  <DetailedChart
                    dataKey={"hours"}
                    miniChartData={chartData || []}
                    durationObj={durationObj}
                    setDurationObj={setDurationObj}
                    className="on-dashboard"
                  />
                  <DetailedChart
                    dataKey={"sessions"}
                    miniChartData={chartData || []}
                    durationObj={durationObj}
                    setDurationObj={setDurationObj}
                    className="on-dashboard"
                  />
                  <DetailedChart
                    dataKey={"earnings"}
                    miniChartData={chartData || []}
                    durationObj={durationObj}
                    setDurationObj={setDurationObj}
                    className="on-dashboard"
                  />
                </SwipeableViews>
              </div>
            )}
          </div>
          <div className="tutor-activity">
            {!isSmallScreen && (
              <div className="tutor-skills-list">
                <DashboardSkillsList
                  ratings={instructorSkillsRating}
                  skillSet={instructorSkills}
                  setInstructorSkills={setInstructorSkills}
                />
              </div>
            )}
            {isSmallScreen && (
              <div className="tutor-dashboard-right-container">
                <div className="tutor-dashboard-outstanding">
                  <div className="resources-header">
                    <h1>Resources</h1>
                    <div className="grade-selector-wrapper">
                      <TabSlider
                        onItemClick={setGrade}
                        items={[
                          { id: 1, value: "class_7", label: "Class 7" },
                          { id: 2, value: "class_8", label: "Class 8" },
                          { id: 3, value: "class_9", label: "Class 9" },
                          { id: 4, value: "class_10", label: "Class 10" },
                        ]}
                      />
                    </div>
                  </div>
                  <div
                    className={"home-main-inner"}
                    style={{ padding: "5px 10px" }}
                  >
                    <LearnSection
                      title={"learn"}
                      sectionId={0}
                      user={{ grade }}
                    />
                  </div>
                </div>
              </div>
            )}
            {isSmallScreen && (
              <DashboardSkillsList
                ratings={instructorSkillsRating}
                skillSet={instructorSkills}
                setInstructorSkills={setInstructorSkills}
                miniVersion
              />
            )}
            <div className="tutor-recommended">
              <div className="tutor-tips-head">
                <h1>Tips &amp; Tricks</h1>
                {videoList && videoList.length > 0 && (
                  <div className="toggle-btns">
                    <button
                      onClick={moveBackward}
                      className={activeTab === 0 ? "fadeOut" : ""}
                    >
                      <ArrowLeft />
                    </button>
                    <div className="slide-number">
                      <h6>{activeTab + 1}</h6>
                      <h5> / {videoList.length}</h5>
                    </div>
                    <button
                      onClick={moveForward}
                      className={
                        activeTab === videoList.length - 1 ? "fadeOut" : ""
                      }
                    >
                      <ArrowRight />
                    </button>
                  </div>
                )}
              </div>
              {!videoList ? (
                <SkillRowShimmer />
              ) : (
                <div className="recommended-list">
                  <SwipeableViews
                    axis={"x"}
                    scrolling={"true"}
                    containerStyle={{
                      transition:
                        "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                    }}
                    index={activeTab}
                    onChangeIndex={(e) => setActiveTab(e)}
                  >
                    {videoList.map((item, index) => (
                      <VimeoPlayer
                        item={item}
                        index={index}
                        isVisible={activeTab === index}
                        setEnableSwitch={setEnableSwitch}
                        activeTab={activeTab}
                        showText={true}
                      />
                    ))}
                  </SwipeableViews>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isSmallScreen && (
          <div className="tutor-dashboard-right-container">
            <div className="tutor-dashboard-outstanding flex flex-col">
              <h1 ref={requestsHeadingRef}>Requests</h1>
              <div
                className="overflow-auto flex-1 py-0 px-[10px]"
                ref={requestsScrollContainerRef}
              >
                <div className="outstanding-sessions-list">
                  {requestedSessions === null &&
                    instructorSkills?.length !== 0 &&
                    Array(6)
                      .fill(0)
                      .map((_) => <BlazeCardLoader />)}

                  {(instructorSkills?.length === 0 ||
                    !requestedSessions ||
                    requestedSessions.length === 0) && (
                    <div className="no__sessions fadeIn">
                      <Lottie
                        options={{ animationData: EmptyBox, loop: false }}
                        speed={0.45}
                        style={{ width: "60%", height: "unset" }}
                      />
                      <h4 style={{ textAlign: "center" }}>
                        There are no requested sessions
                      </h4>
                    </div>
                  )}

                  {instructorSkills?.length !== 0 &&
                    requestedSessions?.map((session) => (
                      <BlazeRequestCard
                        key={session?.id}
                        sessionData={session}
                        topic={session?.topic}
                        skill={session?.skill}
                        sessionId={session?.id}
                        reference={session?.ref}
                        studentName={session?.student_name}
                        setSessionSelected={setSessionSelected}
                        gradient={session?.subject_color_gradient}
                        studentImage={session?.student_profile_pic}
                        studentId={session?.student_id}
                        isSessionSelected={sessionSelected === session?.id}
                        noMoreAvailable={session?.noMoreAvailable}
                        removeSessionFromRequestedSessionList={
                          removeSessionFromRequestedSessionList
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
            <div
              className="tutor-dashboard-outstanding"
              style={{ alignSelf: "flex-end" }}
            >
              <div className="resources-header">
                <h1>Resources</h1>
                <div className="grade-selector-wrapper">
                  <TabSlider
                    onItemClick={setGrade}
                    items={[
                      { id: 1, value: "class_7", label: "Class 7" },
                      { id: 2, value: "class_8", label: "Class 8" },
                      { id: 3, value: "class_9", label: "Class 9" },
                      { id: 4, value: "class_10", label: "Class 10" },
                    ]}
                  />
                </div>
              </div>
              <div
                className={"home-main-inner"}
                style={{ padding: "5px 10px" }}
              >
                <LearnSection title={"learn"} sectionId={0} user={{ grade }} />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setOpenModal(false);
          setDurationObj({
            name: "Last 30 days",
            index: 0,
            month: null,
            year: null,
          });
        }}
        ariaHideApp={false}
        className={isDark ? "tutor-chart-modal dark" : "tutor-chart-modal"}
        overlayClassName="new-post-modal-overlay"
        isOpen={openModal}
      >
        <div
          className="chart-close"
          onClick={() => {
            setOpenModal(false);
            setDurationObj({
              name: "Last 30 days",
              index: 0,
              month: null,
              year: null,
            });
          }}
        />
        <DetailedChart
          dataKey={dataKey}
          miniChartData={chartData || []}
          durationObj={durationObj}
          setDurationObj={setDurationObj}
        />
      </Modal>
    </div>
  );
};

export default TutorDashboard;

import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import Modal from "react-modal";
import StarRatings from "react-star-ratings";
import CloseIcon from "@material-ui/icons/Close";
import { ArrowDownward, CheckCircleOutlineRounded } from "@material-ui/icons";
import { starPath } from "../../../helpers";
import { ThemeContext, UserContext } from "../../../context";
import { addInstructorSkill, getOverallSkills } from "../../../database";
import AddIcon from "../../../assets/images/blaze/addIcon";

import "./style.scss";
import SkillRowShimmer from "./skillRowShimmer";
import { ModalContext } from "../../../context/global/ModalContext";
import { EndSessionModal } from "../../../containers/blaze/blazereservation";
import axios from "axios";
import { firebaseFunctionBaseURL } from "../../../helpers/constants";
import { db } from "../../../firebase_config";
import Lottie from "lottie-react-web";
import circularProgress from "../../../assets/lottie/circularProgress.json";
import EmptyBox from "../../../assets/lottie/empty-box.json";
import Cancel from "@material-ui/icons/Cancel";
import { useHistory } from "react-router-dom";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import route from "react-router-dom/es/Route";

function DeleteInstructorSkill({ item }) {
  const [user] = useContext(UserContext).user;
  const [selected, setSelected] = useState([]);
  const [isDarkMode] = useContext(ThemeContext).theme;
  const [, setConfirmationModalData] = useContext(ModalContext).state;
  const [loading, setLoading] = useState(false);

  const isMultipleClasses = useMemo(() => {
    return Boolean(item?.grades?.length > 1);
  }, [item]);

  function toggleSelection(current) {
    setSelected((prev) => {
      let p = [...prev];
      let ifAny = p.some((a) => a === current);
      if (ifAny) return p.filter((a) => a !== current);
      p.push(current);
      return p;
    });
  }

  const onConfirm = async (setLoading) => {
    if (!user || !item || !user.uid || !item.id) {
      console.log("Skill or User data is missing.");
      return;
    }
    let itemIds = [item.id];
    if (isMultipleClasses) {
      itemIds = selected.map((c) => {
        let splits = item.id.split("_");
        splits[1] = c;
        return splits.join("_");
      });
    }
    setLoading(true);

    for (let i = 0; i < itemIds.length; i++) {
      let itemId = itemIds[i];
      await db
        .collection(
          "/blaze_dev/collections/instructors/" +
            user.uid +
            "/instructor_skills"
        )
        .doc(itemId)
        .delete();
    }
  };

  return (
    <div className={"confirmation-modal" + (isDarkMode ? " dark" : "")}>
      <h1>Remove Skill</h1>
      <Cancel
        className="close-rating"
        onClick={() => {
          setConfirmationModalData((data) => ({ ...data, open: false }));
        }}
      />
      <div className="text">
        <div>
          <p>
            Are you sure you want to remove the{" "}
            <strong style={{ textTransform: "capitalize" }}>
              {item?.subject ? item?.subject : item?.category}
            </strong>
            {!isMultipleClasses && (
              <>
                {" "}
                skill for{" "}
                <strong style={{ textTransform: "capitalize" }}>
                  {item?.grades
                    ? "Class " + item?.grades.join(", ")
                    : item?.grade?.replace("_", " ")}
                </strong>
              </>
            )}
            ?
          </p>
          {isMultipleClasses ? (
            <>
              <p
                style={{
                  color: "rgba(var(--color-text-rgb), .45)",
                  marginTop: "8px",
                  fontSize: "12px",
                }}
              >
                Please select at least one class to remove the skill for
              </p>
              <div className="modal-grade-btn-group">
                {item?.grades?.map((c) => (
                  <button
                    key={c}
                    onClick={() => toggleSelection(c)}
                    className={
                      "modal-grade-btn" +
                      (selected.some((a) => a === c) ? " active" : "")
                    }
                  >
                    Class {c}
                  </button>
                ))}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="flex-buttons">
        <button
          className={
            "danger btn" +
            (isMultipleClasses && selected.length === 0 ? " disabled" : "")
          }
          style={{ marginRight: "10px" }}
          onClick={async () => {
            if (isMultipleClasses && selected.length === 0) return;
            document.getElementsByTagName("body")[0].style.pointerEvents =
              "none";
            await onConfirm(setLoading);
            // Loader here
            document.getElementsByTagName("body")[0].style.pointerEvents =
              "all";
            setConfirmationModalData((data) => ({ ...data, open: false }));
          }}
        >
          {loading ? (
            <Lottie
              options={{ animationData: circularProgress, loop: true }}
              style={{ padding: 0, width: "30px" }}
            />
          ) : (
            "Yes"
          )}
        </button>
        <button
          className="btn"
          style={{ background: "gray" }}
          onClick={() => {
            setConfirmationModalData((data) => ({ ...data, open: false }));
          }}
        >
          No
        </button>
      </div>
    </div>
  );

  // return (
  //
  //   <EndSessionModal
  //     title="Remove Skill"
  //     description={(
  //       <div>
  //         <p>Are you sure you want to remove the <strong style={{textTransform: 'capitalize'}}>{item?.subject ? item?.subject : item?.category}</strong>{!(item?.grades?.length > 1) && (<> skill for <strong style={{textTransform: 'capitalize'}}>{item?.grades ? "Class " + item?.grades.join(", ") : item?.grade?.replace("_", " ")}</strong></>)}?</p>
  //         {item?.grades?.length > 1 ? (
  //           item?.grades?.map(c => <button onClick={() => toggleSelection(c)} className={"modal-grade-btn" + (selected.some(a => a === c) ? ' active' : '')}>Class {c}</button>)
  //         ) : null}
  //       </div>
  //     )}
  //     onConfirm={}
  //   />
  // )
}

const DashboardSkillsList = ({ skillSet, ratings, miniVersion }) => {
  const [disabled, setDisabled] = useState(true);
  const [skillGrade, setSkillGrade] = useState("");
  const [allSkills, setAllSkills] = useState(null);
  const [, setConfirmationModalData] = useContext(ModalContext).state;
  const [skillSubject, setSkillSubject] = useState("");
  const [skillCategory, setSkillCategory] = useState("");
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [skillsList, setSkillsList] = useState(null);
  const chipContainerRef = useRef(null);
  const visibility = useRef();
  const [isVisible, setIsVisible] = useState(true);
  const scrollRef = useRef(null);
  const shadowContainerRef = useRef(null);
  const history = useHistory();

  const lastItemRef = useCallback(function (node) {
    console.log("node - ", node);
    if (node !== null) {
      if (visibility.current) visibility.current.disconnect();

      visibility.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
      if (node) visibility.current.observe(node);
    }
  }, []);

  useEffect(() => {
    if (!scrollRef.current || !shadowContainerRef.current) return;

    // I want to check if the container is scrolled even a little bit
    function handleScroll() {
      if (scrollRef.current.scrollTop > 0) {
        shadowContainerRef.current.classList.add("scrolled");
      } else {
        shadowContainerRef.current.classList.remove("scrolled");
      }
    }

    scrollRef.current.addEventListener("scroll", handleScroll);

    return () => {
      scrollRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    chipContainerRef.current &&
      chipContainerRef.current.scroll({
        top: chipContainerRef.current.clientHeight,
        behavior: "smooth",
      });
  }, [skillSubject, skillCategory, skillGrade]);

  const instructorSkills = useMemo(() => {
    console.log("skillSet - ", skillSet);
    if (skillSet) return [...skillSet];
    return null;
  }, [skillSet]);

  const gradesList = useMemo(() => {
    if (!allSkills) return [];
    const grades = Object.keys(allSkills);
    return grades
      .map((grade) => ({
        value: grade,
        name: (grade[0].toUpperCase() + grade.slice(1)).split("_").join(" "),
        number: +grade.split("_")[1],
      }))
      .sort((a, b) => a.number - b.number);
  }, [allSkills]);

  const categoryIdx = {
    Maths: 0,
    Science: 1,
    SST: 2,
    English: 3,
    General: 4,
  };

  const [user] = useContext(UserContext).user;
  const [isDark] = useContext(ThemeContext).theme;

  useEffect(() => {
    getOverallSkillsFn();
  }, []);

  useEffect(() => {
    const res = isBtnDisabled();
    setDisabled(res);
  }, [skillCategory, skillGrade, skillSubject]);

  const getOverallSkillsFn = async () => {
    const res = await getOverallSkills();

    [{ value: "class_9" }, { value: "class_10" }].map(({ value: grade }) => {
      res?.[grade] && (res[grade].subjects.Maths = ["Maths"]);
    });

    setAllSkills(res);
  };

  const handleSkillModalClose = () => {
    setOpenSkillModal(false);
    setSkillCategory("");
    setSkillSubject("");
    setSkillGrade("");
  };

  const isBtnDisabled = () => {
    if (skillCategory === "Maths") return false;
    else if (skillCategory !== "Maths" && skillSubject) return false;

    return true;
  };

  const handleAddSkill = async () => {
    let _skillId = "";
    if (skillCategory === "Maths") {
      _skillId = skillGrade + "_maths";
    } else {
      _skillId =
        skillGrade +
        "_" +
        skillCategory.toLowerCase() +
        "_" +
        skillSubject.replace(" ", "_").toLowerCase();
    }

    if (_skillId) {
      try {
        let skillToAdd = {
          category: skillCategory,
          grade: skillGrade,
          id: _skillId,
          is_skippable:
            allSkills[skillGrade]?.skip_subjects[categoryIdx[skillCategory]],
          reason: null,
          status: "requested",
          subject: skillCategory === "Maths" ? "Maths" : skillSubject,
        };

        const res = await addInstructorSkill({
          instructorId: user?.uid,
          skillId: _skillId,
          skill: skillToAdd,
        });

        if (res) {
          handleSkillModalClose();
        } else console.log("failed");
      } catch (er) {
        console.log(er);
      }
    }
  };

  const isSkillAlreadyAdded = (subject) => {
    return (
      instructorSkills?.filter((item) => item.subject === subject).length === 1
    );
  };

  const isMathsSkillAdded = () => {
    return (
      instructorSkills?.filter((item) => item.category === "Maths").length === 1
    );
  };

  const getAverage = (arr) => {
    if (arr) {
      const sum = arr.reduce((a, b) => a + b, 0);
      return sum / arr.length || 0;
    }
    return 0;
  };

  useEffect(() => {
    if (ratings && instructorSkills) {
      let _subjectsRating = {};
      // for (let i = 0; i < instructorSkills.length; i++) {
      //   let key = instructorSkills[i].id;
      //   const splitted = key.split("_");
      //   let sub = splitted[splitted.length - 1];
      //
      //   _subjectsRating[sub] = {
      //     average: [
      //       ...(_subjectsRating[sub]?.average || []),
      //       ratings?.skills[key]?.average,
      //     ],
      //     grades: [
      //       ...(_subjectsRating[sub]?.grades || []),
      //       Number(splitted[1]),
      //     ],
      //   };
      // }

      let _subjects = [];
      let _instructorSkills = [...instructorSkills];

      _instructorSkills = _instructorSkills?.map((item) => {
        const splitted = item.id.split("_");
        let sub = splitted[splitted.length - 1];

        if (_subjects.includes(sub)) {
          // _instructorSkills = _instructorSkills.filter((t) => t.id !== item.id);
          // return item;
        }

        _subjects.push(sub);

        item["rating"] = ratings.skills[item.id]?.average;
        // item["rating"] = getAverage(_subjectsRating[sub]?.average);
        // item["grades"] = _subjectsRating[sub]?.grades?.sort((a, b) => a - b);

        return item;
      });

      console.log("_instructorSkills - ", _instructorSkills);

      setSkillsList(_instructorSkills);
    } else if (instructorSkills) {
      setSkillsList(instructorSkills);
    }
    // console.log('instructorSkills - ', instructorSkills);
  }, [ratings, instructorSkills]);

  return (
    <div
      className={
        "tutor-settings-body" + (miniVersion ? " miniVersion" : " !p-0")
      }
    >
      <div
        className={"settings-skills-wrapper " + (miniVersion ? "" : " !p-0")}
      >
        {!isVisible && skillsList?.length > 0 && !miniVersion && (
          <div
            className="skills-table-explore-more"
            onClick={() => {
              if (scrollRef.current) {
                scrollRef.current.scrollTo({
                  top: scrollRef.current.scrollHeight,
                  left: 0,
                  behavior: "smooth",
                });
              }
            }}
          >
            <ArrowDownward />
            <span>Explore more</span>
          </div>
        )}
        <div className={"skills-head-container"} ref={shadowContainerRef}>
          <div
            className={
              "skills-head-2 " + (miniVersion ? "" : " !p-[10px_20px]")
            }
          >
            <h1 className={"flex items-center"}>
              {!miniVersion && (
                <div
                  className={"flex items-center justify-center"}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowBackIos
                    style={{
                      color: "var(--color-text)",
                      fontSize: "20px",
                      marginRight: "4px",
                    }}
                  />
                </div>
              )}
              My Skills
            </h1>
            {!miniVersion && skillsList?.length > 0 && (
              <button
                className="add-skill"
                onClick={() => setOpenSkillModal(true)}
              >
                <AddIcon />
              </button>
            )}
            {miniVersion && (
              <button
                onClick={(e) => {
                  history.push("/skills");
                }}
              >
                View All
              </button>
            )}
          </div>

          {!miniVersion && ratings?.average_rating && (
            <div className="tutor-overall-rating">
              <StarRatings
                rating={
                  ratings?.average_rating > 0 ? ratings?.average_rating : 0
                }
                starRatedColor="#fec107"
                starHoverColor="#fec107"
                numberOfStars={5}
                starDimension="24px"
                starSpacing="2.5px"
                name="rating"
                svgIconPath={starPath}
                svgIconViewBox="0 0 207.802 207.748"
              />
              {ratings?.average_rating > 3.5 && (
                <h5>
                  <span>Keep it up!</span> 🎉{" "}
                </h5>
              )}
            </div>
          )}
        </div>
        {skillsList?.length === 0 && (
          <div className="no-skills">
            <Lottie
              options={{ animationData: EmptyBox, loop: false }}
              speed={0.45}
              style={{ width: "200px", height: "200px", height: "unset" }}
            />

            <h1>No skills have been added</h1>

            <button onClick={() => setOpenSkillModal(true)}>Add Skills</button>
          </div>
        )}
        {!skillsList && (
          <div className="px-[20px] py-[25px]">
            <SkillRowShimmer />
          </div>
        )}
        <div
          className={
            "skills-table-wrapper " + (miniVersion ? "" : " !p-[0_30px]")
          }
          ref={scrollRef}
        >
          <table className="skills-table">
            {(miniVersion ? skillsList?.slice(0, 5) : skillsList)?.map(
              (item, i) =>
                item && (
                  <tr
                    className="skills-row fadeIn"
                    style={{ animationDelay: `${0.025 * i}s` }}
                    key={item?.id}
                    ref={skillsList.length - 1 === i ? lastItemRef : null}
                  >
                    <td>
                      {item.status === "requested" ? (
                        <div className="requested-status" />
                      ) : (
                        <CheckCircleOutlineRounded className="check-circle" />
                      )}
                    </td>
                    <td>
                      <h1>{item?.subject ? item?.subject : item?.category}</h1>
                      <p>
                        {item?.grades
                          ? "Class " + item?.grades.join(", ")
                          : item?.grade?.replace("_", " ")}
                      </p>
                    </td>

                    {/*<td>*/}
                    {/*  <h1>*/}
                    {/*    {item?.grades*/}
                    {/*      ? "Class " + item?.grades.join(", ")*/}
                    {/*      : item?.grade?.replace("_", " ")}*/}
                    {/*  </h1>*/}
                    {/*</td>*/}
                    <td className="stars">
                      <div className="skill-rating">
                        <StarRatings
                          rating={item?.rating > 0 ? item.rating : 0}
                          starRatedColor="#fec107"
                          starHoverColor="#fec107"
                          numberOfStars={5}
                          starDimension="15px"
                          starSpacing="1px"
                          name="rating"
                          svgIconPath={starPath}
                          svgIconViewBox="0 0 207.802 207.748"
                        />
                      </div>
                    </td>
                    {!miniVersion && (
                      <td style={{ textAlign: "right" }}>
                        <svg
                          className="skill-removal"
                          width={15}
                          height={18}
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => {
                            setConfirmationModalData({
                              open: true,
                              Children: <DeleteInstructorSkill item={item} />,
                            });
                          }}
                        >
                          <path d="M14.416 5.098c.372 0 .63-.377.49-.722a3.345 3.345 0 0 0-3.097-2.09h-.647C10.92 1.01 9.802 0 8.434 0H7.309C5.942 0 4.823 1.01 4.58 2.285h-.647c-1.4 0-2.602.867-3.098 2.091a.527.527 0 0 0 .49.722h13.09ZM7.309 1.055h1.125c.77 0 1.423.518 1.643 1.23H5.665a1.73 1.73 0 0 1 1.644-1.23ZM2.775 16.53c.059.825.752 1.47 1.578 1.47h7.036c.826 0 1.52-.645 1.578-1.47l.739-10.378H2.036l.74 10.378Zm6.538-7.556a.527.527 0 0 1 1.053.052l-.28 5.625a.527.527 0 0 1-1.054-.052l.281-5.625Zm-3.437-.5c.29-.015.538.209.553.5l.281 5.625a.527.527 0 0 1-1.053.052l-.281-5.625a.527.527 0 0 1 .5-.553Z" />
                        </svg>
                      </td>
                    )}
                  </tr>
                )
            )}
          </table>
        </div>
      </div>

      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleSkillModalClose}
        ariaHideApp={false}
        overlayClassName="new-post-modal-overlay"
        isOpen={openSkillModal}
        className={isDark ? "skills-modal dark" : "skills-modal"}
      >
        {openSkillModal && (
          <div className="skills-modal-inner">
            <div className="modal-head">
              <h1>Add Skills</h1>
              <CloseIcon onClick={handleSkillModalClose} />
            </div>

            <div className="skills-list-wrapper" ref={chipContainerRef}>
              <div className="grades-list-wrapper">
                <h1>Select Grade</h1>
                <div className="grades-list">
                  {gradesList.map((item) => (
                    <span
                      key={item.value}
                      onClick={() => {
                        setSkillGrade(item.value);
                        setSkillCategory("");
                        setSkillSubject("");
                      }}
                      className={
                        item.value === skillGrade ? "chip selected" : "chip"
                      }
                    >
                      {item.name}
                    </span>
                  ))}
                </div>
              </div>
              {skillGrade && (
                <div className="categories-list-wrapper">
                  <h1>Select Category</h1>
                  <div className="categories-list">
                    {allSkills[skillGrade]?.categories.map((item, i) => (
                      <span
                        key={i}
                        onClick={() => {
                          setSkillCategory(item);
                          setSkillSubject("");
                        }}
                        className={
                          item === skillCategory ? "chip selected" : "chip"
                        }
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              {skillCategory && (
                <div className="subjects-list-wrapper">
                  <h1>Select Subject</h1>
                  <div className="subjects-list">
                    {allSkills[skillGrade].subjects[skillCategory].map(
                      (item, i) => (
                        <span
                          key={i}
                          className={
                            item === skillSubject ? "chip selected" : "chip"
                          }
                          onClick={() => setSkillSubject(item)}
                        >
                          {item}
                        </span>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>

            <button
              className="add-skill-btn"
              disabled={disabled}
              type="submit"
              onClick={() => handleAddSkill()}
            >
              Add
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DashboardSkillsList;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { animate } from "framer-motion";
import { usePreLoader } from "../../hooks/usePreloader";
import { SpinnerSVG } from "../StoryModal";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import {
  fetchAssetsOfChapter,
  GradientSVG,
  STORY_STATUS,
} from "../../containers/home/sidebar";
import { SubjectModalContext, ThemeContext } from "../../context";
import { mockStories } from "./mock";
import {
  Delete,
  PauseCircleFilled,
  PlayArrow,
  VolumeOff,
  VolumeUp,
} from "@material-ui/icons";
import { ModalContext } from "../../context/global/ModalContext";
import Cancel from "@material-ui/icons/Cancel";
import Lottie from "lottie-react-web";
import circularProgress from "../../assets/lottie/circularProgress.json";
import { db } from "../../firebase_config";
import { storyEventNames } from "../../context/global/SubjectModal";
import { StoryItem as SubjectStoryIcon } from "../../containers/home/sidebar";
import { getClassName, getGrade } from "../../helpers";

class Transform {
  x;
  scale;
  constructor(x, scale, active = false) {
    this.x = x;
    this.scale = scale;
    this.active = active;
  }

  toString() {
    return `translateX(${this.x}px) scale(${this.scale})`;
  }
}

export const DeleteStoryModal = ({ handleClose, onConfirm }) => {
  const [, setConfirmationModalData] = useContext(ModalContext).state;
  const [isDarkMode] = useContext(ThemeContext).theme;
  const [loading, setLoading] = useState(false);
  return (
    <div className={"confirmation-modal" + (isDarkMode ? " dark" : "")}>
      <h1>Delete Story</h1>
      <Cancel
        className="close-rating"
        onClick={() => {
          setConfirmationModalData((data) => ({ ...data, open: false }));
        }}
      />
      <p className="text">{"Are you sure you want to delete the story?"}</p>
      <div className="flex-buttons">
        <button
          className="danger btn"
          style={{ marginRight: "10px" }}
          onClick={async () => {
            document.getElementsByTagName("body")[0].style.pointerEvents =
              "none";
            await onConfirm(setLoading);
            // Loader here
            document.getElementsByTagName("body")[0].style.pointerEvents =
              "all";
            setConfirmationModalData((data) => ({ ...data, open: false }));
          }}
        >
          {loading ? (
            <Lottie
              options={{ animationData: circularProgress, loop: true }}
              style={{ filter: "invert(1)", padding: 0, width: "30px" }}
            />
          ) : (
            "Yes"
          )}
        </button>
        <button
          className="btn"
          style={{ background: "gray" }}
          onClick={() => {
            setConfirmationModalData((data) => ({ ...data, open: false }));
          }}
        >
          No
        </button>
      </div>
    </div>
  );
};
const indices = [-3, -2, -1, 0, 1, 2, 3];

const transformValues = {
  [-1]: new Transform(-69 - 199, 0.4),
  [0]: new Transform(-69, 0.4),
  [1]: new Transform(129.143, 0.4),
  [2]: new Transform(328, 1, true),
  [3]: new Transform(328 + 199 + 199 + 11, 0.4),
  [4]: new Transform(328 + 199 + 199 + 199 + 11, 0.4),
  [5]: new Transform(328 + 199 + 199 + 199 + 199 + 11, 0.4),
  //
  // 0: {
  //   x: -69,
  //   scale: 0.4
  // },
  // 1: {
  //   x: 129.143,
  //   scale: 0.4
  // },
  // 2: {
  //   x: 328,
  //   scale: 1
  // },
  // 3: {
  //   x: 736.306,
  //   scale: 0.4
  // },
  // 4: {
  //   x: 935.224,
  //   scale: 0.4
  // },
  // 5: {
  //   x: 935.224 + 199,
  //   scale: 0.4
  // }
};

const getTransformClass = (index, direction) => {
  const transformClass = transformValues[index + direction];
  return transformClass instanceof Transform
    ? transformClass
    : transformClass[0];
};

const displayGrades = (grade) => {
  const grades = grade.split(",");

  // If there are more than 2 grades, display them in a more compact format
  if (grades.length > 2) {
    const gradeNumbers = grades.map((g) => parseInt(g.split("_")[1]));
    return `Classes ${gradeNumbers
      .join(", ")
      .replace(/,([^,]*)$/, " and$1")}th`;
  }

  // For 1 or 2 grades, keep the original behavior
  const displayArr = grades.reduce((acc, curr) => {
    acc.push(getClassName(curr) + "th");
    return acc;
  }, []);
  return displayArr.join(" and ");
};

const getTransform = (index, direction) => {
  return getTransformClass(index, direction).toString();
};

const ProgressItem = ({ progress, isActive, count }) => {
  return (
    <div
      className="relative overflow-hidden"
      style={{
        height: "2px",
        background: "#aaa",
        borderRadius: 2,
        width: 100 / count + "%",
      }}
    >
      <div
        className="absolute top-0 left-0 h-full bg-white"
        style={{ width: `${progress}%`, transition: isActive ? "all" : "none" }}
      />
    </div>
  );
};

const ProgressContainer = ({ progress, isActive, currentIndex, count = 1 }) => {
  return (
    <div className="flex items-center justify-evenly gap-2 px-2 my-2">
      {[...Array(count)].map((_, index) => {
        const _progress =
          index === currentIndex ? progress : index < currentIndex ? 100 : 0;
        return (
          <ProgressItem
            isActive={isActive}
            progress={_progress}
            count={count}
          />
        );
      })}
    </div>
  );
};

const url =
  "https://player.vimeo.com/progressive_redirect/playback/901751928/rendition/720p/file.mp4?loc=external&oauth2_token_id=1574768840&signature=ea14ef6cb135085df4b7a4f4c32e2fc5ce4f702977b2966b4347f45888932925";
const url1 =
  "https://player.vimeo.com/progressive_redirect/playback/901755712/rendition/720p/file.mp4?loc=external&oauth2_token_id=1574768840&signature=a9914e02709932e752dcc1dca1076888d809371f790e4573e12a11661f912989";
const url2 =
  "https://player.vimeo.com/progressive_redirect/playback/901755517/rendition/720p/file.mp4?loc=external&oauth2_token_id=1574768840&signature=61b73fa94fe44d06f5c86ae15f43852afec4d943453d0632ada06f178db706de";

const stories = {
  sst: [url],
  science: [url, url],
  english: [url, url, url, url],
  maths: [url, url, url],
};

const Story = ({
  subjectStory,
  story,
  isDummy,
  handleDelete,
  onStoryEnd,
  setProgress,
}) => {
  const videoElRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [paused, setPaused] = useState(false);
  const [muted, setMuted] = useState(false);

  function handleDurationChange(e) {}

  function _handleDelete() {
    videoElRef.current.pause();
    handleDelete();
  }

  function handleTimeUpdate(e) {
    setProgress((e.target.currentTime / e.target.duration) * 100);
  }

  function handlePlay() {
    setPaused(false);
  }

  useEffect(() => {
    if (!videoElRef.current) return;

    videoElRef.current.addEventListener("durationchange", handleDurationChange);
    videoElRef.current.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElRef.current.removeEventListener(
        "durationchange",
        handleDurationChange
      );
      videoElRef.current.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  // useEffect(() => {
  //   if(!isInView) return;
  //
  //   videoElRef.current.play();
  // }, [isInView])

  if (!story.grade_id) return null;

  return (
    <div className="w-full h-full bg-black bg-opacity-90">
      {!isDummy && (
        <div
          className="absolute top-4 md:right-4 right-12 flex gap-2 items-center"
          style={{ zIndex: 100 }}
        >
          {paused ? (
            <div className="relative cursor-pointer text-lg">
              <PlayArrow
                onClick={() => {
                  videoElRef.current.play();
                  setPaused(false);
                }}
                className={"text-white"}
              />
            </div>
          ) : (
            <div className="relative cursor-pointer text-lg">
              <PauseCircleFilled
                onClick={() => {
                  videoElRef.current.pause();
                  setPaused(true);
                }}
                className={"text-white"}
              />
            </div>
          )}
          {!muted ? (
            <div className="relative cursor-pointer text-lg">
              <VolumeUp
                onClick={() => {
                  videoElRef.current.muted = true;
                  setMuted(true);
                }}
                className={"text-white"}
              />
            </div>
          ) : (
            <div className="relative cursor-pointer text-lg">
              <VolumeOff
                onClick={() => {
                  videoElRef.current.muted = false;
                  setMuted(false);
                }}
                className={"text-white"}
              />
            </div>
          )}
          <Delete
            className="cursor-pointer text-red-500"
            onClick={_handleDelete}
          />
        </div>
      )}
      {subjectStory && (
        <div className="absolute top-4 left-4 flex gap-2 items-center">
          <div className="w-12 h-12 rounded-full bg-[transparent] p-1.5">
            <div className="bg-gray-700 flex relative justify-center items-center  rounded-full overflow-hidden">
              <img
                className="w-full h-full object-cover"
                src={
                  fetchAssetsOfChapter(subjectStory.item_background_image)
                    .bgImage
                }
                alt=""
              />
              <img
                className="w-[50%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                src={
                  fetchAssetsOfChapter(subjectStory.item_background_image).image
                }
                alt=""
              />
            </div>
          </div>
          <div className="">
            <h2 className={"text-white text-base"}>{subjectStory.item_name}</h2>
            <p className={"text-xs text-gray-400"}>
              {displayGrades(story.grade_id)}
            </p>
          </div>
        </div>
      )}
      <video
        className="w-full h-full"
        autoPlay={!isDummy}
        muted={isDummy}
        src={story.url}
        onPlay={() => setPaused(false)}
        onPause={() => setPaused(true)}
        onLoadedData={() => {
          setLoaded(true);
        }}
        onLoad={() => {
          console.log("Instagram Story | onLoad - ");
        }}
        onLoadedMetadata={() => {
          console.log("Instagram Story | onLoadedMetadata - ");
        }}
        onLoadCapture={() => {
          console.log("Instagram Story | onLoadCapture - ");
        }}
        onSeeking={() => {
          console.log("Instagram Story | onSeeking - ");
        }}
        onSeeked={() => {
          console.log("Instagram Story | onSeeked - ");
        }}
        onEnded={() => {
          onStoryEnd();
        }}
        ref={videoElRef}
      />
      {!isDummy && !loaded && (
        <div className="absolute top-0 left-0 w-full h-full z-20 flex justify-center items-center">
          <SpinnerSVG />
        </div>
      )}
    </div>
  );
};

const BackStoryView = ({ story, children }) => {
  return (
    <div className="relative w-full h-full">
      <div className="absolute top-0 left-0 z-20 w-full h-full flex items-center justify-center flex-col gap-3 bg-black bg-opacity-60">
        <SubjectStoryIcon big={true} story={story} handleClick={() => null} />
        {/*<div className="flex-grow-1 flex-shrink-0 flex flex-col items-center justify-center cursor-pointer">*/}
        {/*  <div className="aspect-square w-[110px] h-[110px] p-0 rounded-full">*/}
        {/*    <GradientSVG />*/}
        {/*    <CircularProgressbarWithChildren*/}
        {/*      strokeWidth={4}*/}
        {/*      value={status === STORY_STATUS.IS_UPLOADING ? progress : status === STORY_STATUS.LOADED && !unWatched ? 0 : 100}*/}
        {/*      classes={{*/}
        {/*        root: [STORY_STATUS.IS_TRANSCODING, STORY_STATUS.LOADING].includes(status) ? 'animate-spin' : '',*/}
        {/*      }}*/}
        {/*      styles={{*/}
        {/*        path: { stroke: `url(#hello)`, height: "100%" },*/}
        {/*        trail: {*/}
        {/*          stroke: isSmallScreen ? (isDark ? "rgba(255,255,255,0.15)" : "rgba(0,0,0,0.15)") : "rgba(255,255,255,0.35)"*/}
        {/*        }*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <div className="rounded-full bg-[transparent] p-1.5">*/}
        {/*        <div className="bg-gray-700 flex relative justify-center items-center  rounded-full overflow-hidden">*/}
        {/*          <img className="w-full h-full object-cover" src={fetchAssetsOfChapter(story.item_background_image).bgImage} alt=""/>*/}
        {/*          <img className="w-[50%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src={fetchAssetsOfChapter(story.item_background_image).image} alt=""/>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </CircularProgressbarWithChildren>*/}
        {/*  </div>*/}
        {/*  <span className={"text-[26px] mt-1 text-opacity-80 text-white"}>{story.item_name}</span>*/}
        {/*</div>*/}
      </div>
      {children}
    </div>
  );
};

const _mockStories = [url, url1, url2];
export const StoryView = ({
  isMobile,
  isActive,
  nextSubject,
  prevSubject,
  story,
  deleteSubjectStory,
}) => {
  const [storyItems, setStoryItems] = useState(story.items);
  const [currentId, setCurrentId] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [storyVideoIndices, setStoryVideoIndices] =
    useContext(SubjectModalContext).storyVideoIndices;
  const [stories, setStories] = useContext(SubjectModalContext).stories;
  const [progress, setProgress] = useState(0);
  const [modalData, setModalData] = useContext(ModalContext).state;
  const storyEventRef = useContext(SubjectModalContext).storyEventRef;

  usePreLoader(storyItems, 0, 3);

  useEffect(() => {
    console.log("Setting story - ", story.items);
    setStoryItems(story.items);
  }, [story.items]);

  const loadedRef = useRef(false);

  useEffect(() => {
    if (loadedRef.current || !story || !storyVideoIndices) return;
    setCurrentIndex(storyVideoIndices[story.id] || 0);

    loadedRef.current = true;
  }, [storyVideoIndices, story.id]);

  async function handleConfirmDelete(setLoading) {
    const storyItem = storyItems[currentIndex];
    const storyId = story.id;

    setLoading(true);
    await db
      .collection("stories")
      .doc(storyId)
      .collection("items")
      .doc(storyItem.video_id)
      .delete();

    setLoading(false);
    console.log("Setting story - ");
    setStoryItems((s) => {
      const newStories = [...s];
      newStories.splice(currentIndex, 1);
      if (newStories.length > 0) {
        setProgress(0);
        setCurrentIndex(
          currentIndex > newStories.length - 1
            ? newStories.length - 1
            : currentIndex
        );
      } else {
        deleteSubjectStory();
        nextSubject();
      }
      return newStories;
    });

    setStories((stories) => {
      let _storyObject = stories.find((c) => c.item_name === story.item_name);
      if (!_storyObject) _storyObject = story;
      const storyIndex = stories.findIndex(
        (item) => item.id === _storyObject.id
      );
      const newStories = stories.filter(
        (story) => story.id !== _storyObject.id
      );
      const newItems =
        _storyObject.items?.filter(
          (item) => item.id !== storyItem.id && item.id !== null
        ) ?? [];
      newStories.splice(storyIndex, 0, {
        ..._storyObject,
        items: [...newItems],
      });
      return newStories;
    });
  }

  function handleDelete() {
    // setStories(s => {
    //   const newStories = [...s];
    //   newStories.splice(currentIndex, 1);
    //   if(newStories.length > 0) {
    //     setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : currentIndex + 1)
    //   } else {
    //     nextSubject();
    //   }
    //   return newStories;
    // });

    setModalData({
      open: true,
      Children: (
        <DeleteStoryModal
          onConfirm={handleConfirmDelete}
          handleClose={() => {}}
        />
      ),
    });
  }

  useEffect(() => {
    if (isActive && storyEventRef && story?.id) {
      storyEventRef.current.dispatchEvent(storyEventNames.storyViewed, {
        storyId: story?.id,
      });
    }
  }, [storyEventRef, story?.id, isActive]);

  const handlePrev = useCallback(() => {
    setProgress(0);
    const newInd = currentIndex - 1;
    if (newInd < 0) {
      prevSubject();
      return setCurrentIndex(storyVideoIndices[story.id] || 0);
    }
    setCurrentIndex(newInd);
  }, [prevSubject]);

  const handleNext = useCallback(() => {
    setProgress(0);
    const newInd = currentIndex + 1;
    if (newInd > storyItems.length - 1) {
      nextSubject();
      return setCurrentIndex(storyVideoIndices[story.id] || 0);
    }
    setCurrentIndex(newInd);
  }, [nextSubject]);

  useEffect(() => {
    function handleKeyPress(e) {
      if (!isActive) return;
      if (e.keyCode === 37) {
        console.log("Vimeo Debug | Running | handlePrev() - ");
        handlePrev();
      } else if (e.keyCode === 39) {
        console.log("Vimeo Debug | Running | handleNext() - ");
        handleNext();
      }
    }

    if (isActive) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isActive, handlePrev, handleNext]);

  useEffect(() => {
    setStoryVideoIndices((s) => {
      return { ...s, [story.id]: currentIndex };
    });
  }, [currentIndex, story.id]);

  function onStoryEnd() {
    handleNext();
  }

  return isActive ? (
    <div className="w-full h-full static relative">
      {/*Progress bar*/}
      <div className="relative z-50">
        <ProgressContainer
          isActive={isActive}
          progress={progress}
          currentIndex={currentIndex}
          count={storyItems.length}
        />
      </div>
      <div className="w-full h-full">
        <div className="absolute top-0 left-0 w-full h-full">
          {storyItems && storyItems[currentIndex] && (
            <Story
              subjectStory={story}
              handleDelete={handleDelete}
              onStoryEnd={onStoryEnd}
              key={currentIndex}
              story={storyItems[currentIndex]}
              progress={progress}
              setProgress={setProgress}
            />
          )}
        </div>
      </div>
      {isMobile ? (
        <>
          <div
            onClick={handlePrev}
            className={"w-1/2 absolute bottom-0 left-0 h-[80vh]"}
          />
          <div
            onClick={handleNext}
            className={"w-1/2 absolute bottom-0 right-0 h-[80vh]"}
          />
        </>
      ) : (
        <>
          <button
            className="bg-white text-yellow w-6 h-6 flex justify-center items-center rounded-full absolute right-[105%] top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={handlePrev}
          >
            <ArrowLeftIcon />
          </button>
          <button
            className="bg-white text-yellow w-6 h-6 flex justify-center items-center rounded-full absolute left-[105%] top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={handleNext}
          >
            <ArrowRightIcon />
          </button>
        </>
      )}
    </div>
  ) : (
    <BackStoryView story={story}>
      <div className="w-full h-full">
        <div className="absolute top-0 left-0 w-full h-full">
          {storyItems && storyItems[currentIndex] && (
            <Story
              isDummy={true}
              key={currentIndex}
              story={storyItems[currentIndex]}
              progress={progress}
              setProgress={setProgress}
            />
          )}
        </div>
      </div>
    </BackStoryView>
  );
};

const subjects = ["English", "Maths", "SST", "Science"];

function StoryItem({
  position,
  isActive,
  story,
  nextCursor,
  prevCursor,
  deleteSubjectStory,
}) {
  function setDirection() {}

  return (
    <div
      className="absolute aspect-[9/16] h-full top-0 bg-black transition-transform"
      style={{
        transform: `translateX(calc(${position}px - 50%)) scale(${
          isActive ? 1 : 0.4
        })`,
      }}
    >
      {story && (
        <StoryView
          story={story}
          isActive={isActive}
          setDirection={setDirection}
          nextSubject={nextCursor}
          prevSubject={prevCursor}
          deleteSubjectStory={deleteSubjectStory}
        />
      )}
    </div>
  );
}

export default function StoryCarousel({ handleClose }) {
  const containerRef = React.useRef(null);
  const [cursor, setCursor] = useContext(SubjectModalContext).storyCursor;
  const [positions, setPositions] = useState(null);
  const [_stories, setStories] = useContext(SubjectModalContext).stories;

  console.log("Test My Bad | cursor - ", cursor);

  const stories = useMemo(() => {
    return _stories.filter((c) => !c.fetched || c.items?.length > 0);
  }, [_stories]);

  const calculatePosition = useCallback(
    (cursor) => {
      const middleX = calculateMiddleX();
      const difference = 400;
      const position = [];
      for (let i = 0; i < stories.length; i++) {
        const ind = i - cursor; // 1, 2
        const index = ind * difference; // 200 // 400
        position.push(
          middleX + (ind === 0 ? 0 : index - (ind + (ind < 0 ? 1 : -1)) * 100)
        ); // 200
      }
      return position;
    },
    [stories]
  );

  function calculateMiddleX() {
    if (!containerRef.current) {
      console.error("containerRef.current is null");
      return 0;
    }
    const rect = window.innerWidth;
    return rect / 2;
  }

  useEffect(() => {
    // Calculate middleX
    function calculateCoords() {
      const pos = calculatePosition(cursor);
      console.log("pos - ", pos);
      setPositions(pos);
    }

    window.addEventListener("resize", calculateCoords);

    calculateCoords();

    return () => {
      window.removeEventListener("resize", calculateCoords);
    };
  }, [calculatePosition, cursor]);

  const handleNext = useCallback(() => {
    setCursor((c) => {
      const newIndex = c + 1;
      if (newIndex >= stories.length) {
        handleClose();
        return c;
      }
      return newIndex;
    });
  }, [handleClose]);

  const handlePrev = useCallback(() => {
    setCursor((c) => {
      const newIndex = c - 1;
      if (newIndex < 0) {
        handleClose();
        return c;
      }
      return newIndex;
    });
  }, [handleClose]);

  function handleDelete() {
    // setItems(items => {
    //   if(items.length - 1 === cursor) {
    //     setCursor(c => c - 1);
    //   }
    //   return items.filter((item, i) => i !== cursor);
    // });
  }

  function deleteSubjectStory() {
    console.log("Setting story - ");
    setStories((stories) => {
      const newStories = [...stories];
      newStories.splice(cursor, 1);
      return newStories;
    });
  }

  return (
    <div className={"w-screen h-screen bg-black"}>
      {/*<div className="flex gap-2 absolute top-4 left-1/2 transform -translate-x-1/2">*/}
      {/*  <button className="bg-red-500 text-white" onClick={handlePrev}>Prev</button>*/}
      {/*  <button className="bg-red-500 text-white" onClick={handleNext}>Next</button>*/}
      {/*  <button className="bg-red-500 text-white" onClick={handleDelete}>Delete</button>*/}
      {/*</div>*/}
      <div
        ref={containerRef}
        className="w-full h-[full] md:h-[80vh] absolute top-1/2 left-0 transform -translate-y-1/2"
      >
        {positions &&
          stories &&
          stories
            .filter((c) => c.items?.length > 0)
            .map((story, i) => (
              <StoryItem
                deleteSubjectStory={deleteSubjectStory}
                nextCursor={handleNext}
                prevCursor={handlePrev}
                story={story}
                position={positions[i]}
                isActive={cursor === i}
                key={story.id}
              />
            ))}
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Stories from "react-insta-stories";
import Close from "@material-ui/icons/Close";
import { SubjectModalContext, ThemeContext, UserContext } from "../context";
import Vimeo from "@u-wave/react-vimeo";
import { ChevronRight } from "@material-ui/icons";
import { ModalContext } from "../context/global/ModalContext";
import * as tus from "tus-js-client";
import { showSnackbar } from "../helpers";
import { default as StoryRenderer } from "./StoryRenderer";
import SwipeableViews from "react-swipeable-views";
import {
  getBunnyCDNConfig,
  storyEventNames,
} from "../context/global/SubjectModal";
import { useMediaQuery } from "react-responsive";
import StoryCarousel, { StoryView } from "./story/StoryCarousel";
import { mockStories } from "./story/mock";
import { ShareStory, storyConfig } from "../context/global/StoryState";

const accessTokenRonak = "eb3aa30f683094b5e51d077a9b8bbff5";

export const SpinnerSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    stroke="#fff"
    {...props}
  >
    <g fill="none" fillRule="evenodd" strokeWidth={2}>
      <circle cx={22} cy={22} r={1}>
        <animate
          attributeName="r"
          begin="0s"
          calcMode="spline"
          dur="1.8s"
          keySplines="0.165, 0.84, 0.44, 1"
          keyTimes="0; 1"
          repeatCount="indefinite"
          values="1; 20"
        />
        <animate
          attributeName="stroke-opacity"
          begin="0s"
          calcMode="spline"
          dur="1.8s"
          keySplines="0.3, 0.61, 0.355, 1"
          keyTimes="0; 1"
          repeatCount="indefinite"
          values="1; 0"
        />
      </circle>
      <circle cx={22} cy={22} r={1}>
        <animate
          attributeName="r"
          begin="-0.9s"
          calcMode="spline"
          dur="1.8s"
          keySplines="0.165, 0.84, 0.44, 1"
          keyTimes="0; 1"
          repeatCount="indefinite"
          values="1; 20"
        />
        <animate
          attributeName="stroke-opacity"
          begin="-0.9s"
          calcMode="spline"
          dur="1.8s"
          keySplines="0.3, 0.61, 0.355, 1"
          keyTimes="0; 1"
          repeatCount="indefinite"
          values="1; 0"
        />
      </circle>
    </g>
  </svg>
);

const tester = (story) => {
  return {
    // Use this renderer only when the story type is video
    condition: story.type === "video",
    priority: 3,
  };
};

const stories = [
  {
    url: "https://player.vimeo.com/progressive_redirect/playback/898746993/rendition/240p/file.mp4?loc=external&oauth2_token_id=1574768840&signature=aa659ef886fa2f4ec369df38e710ed4a1ed4c63d5be1b40259ac937816237546",
    type: "video",
    preloadResource: true,
    styles: {
      width: "100%",
      height: "100%",
    },
  },
  {
    url: "https://player.vimeo.com/progressive_redirect/playback/899747147/container/2ae89eb8-8350-4daa-bef9-547de5f17e5c/0f5d0a76-f60b0189?expires=1704618860&loc=external&oauth2_token_id=1574768840&signature=96953ec4cbe2b40c6a7cbcaf4226692e92504faf89b8766778bb83110fba68f5",
    type: "video",
    preloadResource: true,
    styles: {
      width: "100%",
      height: "100%",
    },
  },
];

function playSubjectStory(index) {}

function StoryVideoHolder({
  storyId,
  storyItems,
  isVisible,
  startIndex = 0,
  playSubjectStory,
}) {
  // const [currentIndex, setCurrentIndex] = useState(startIndex);
  const [storyVideoIndices, setStoryVideoIndices] =
    useContext(SubjectModalContext).storyVideoIndices;
  const storyEventRef = useContext(SubjectModalContext).storyEventRef;

  function handleChange(direction) {
    return (object) => {
      const index = object[storyId] ?? 0;
      console.log("Vimeo Debug | - ", storyItems, index);
      const newIndex = index + direction;
      if (newIndex < 0) {
        playSubjectStory(-1);
        return { ...object, [storyId]: index };
      }
      if (newIndex > storyItems.length - 1) {
        playSubjectStory(1);
        return { ...object, [storyId]: index };
      }

      return { ...object, [storyId]: newIndex };
    };
  }

  useEffect(() => {
    if (isVisible) {
      storyEventRef.current.dispatchEvent(storyEventNames.storyViewed, {
        storyId: storyId,
      });
    }
  }, [storyId, isVisible]);

  return isVisible ? (
    <Stories
      preloadCount={3}
      // keyboardNavigation
      defaultInterval={8000}
      // renderers={[{
      // renderer: StoryRenderer.Renderer,
      // tester: StoryRenderer.Tester
      // }]}
      // loop
      stories={storyItems}
      width={"100%"}
      height={"100%"}
      storyStyles={{ color: "red", width: "100%", height: "100%" }}
      storyContainerStyles={{ color: "black", background: "transparent" }}
      storyInnerContainerStyles={{
        color: "white",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "stretch",
        gridTemplateColumns: "1fr",
        justifyItems: "center",
        justifyContent: "center",
      }}
      onAllStoriesEnd={() => {
        console.log("All Stories end");
      }}
      currentIndex={storyVideoIndices[storyId]}
      onNext={(cur) => {
        console.log("Vimeo Debug | onNext - ", cur);
        setStoryVideoIndices(handleChange(1));
      }}
      onPrevious={(cur) => {
        console.log("Vimeo Debug | onPrevious - ", cur);
        setStoryVideoIndices(handleChange(-1));
      }}
    />
  ) : null;
}

function ShareStoryModal({ duration, handleCloseModal }) {
  const [modalData, setModalData] = useContext(ModalContext).state;
  const shareStoryClassRef = useContext(SubjectModalContext).shareStoryClassRef;
  const [bunnyCDNConfig] = useContext(SubjectModalContext).bunnyCDNConfig;
  const uploadStory = useContext(SubjectModalContext).uploadStory;
  const [uploadStoryFile] = useContext(SubjectModalContext).uploadStoryFile;
  const [user] = useContext(UserContext).user;
  const [selected, setSelected] = useState({
    grades: [storyConfig.grades[0]],
    subject: storyConfig.subjects[0],
    sharing: false,
  });
  const [subjectCollections] =
    useContext(SubjectModalContext).subjectCollections;

  const onChange = (name, value) => {
    setSelected((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function handleGradeChange(gradeObj) {
    const _grades = selected.grades;
    const gradeIndex = _grades.findIndex(
      (grade) => grade.value === gradeObj.value
    );
    if (gradeIndex >= 0) {
      _grades.splice(gradeIndex, 1);
    } else {
      _grades.push(gradeObj);
    }

    onChange("grades", _grades);
  }

  function handleSubjectChange(subjectObj) {
    onChange("subject", subjectObj);
  }

  function handleClose() {
    setModalData({
      open: false,
      Children: null,
    });
  }

  function onUploadProgress({ percentage, bytesUploaded, bytesTotal }) {}

  useEffect(() => {
    if (subjectCollections) {
      onChange("subject", subjectCollections[0]);
    }
  }, [subjectCollections]);

  function onUploadComplete() {}

  async function handleShare() {
    if (selected.sharing || !user || !bunnyCDNConfig) return;

    const shareStoryClass = new ShareStory(
      selected.grades,
      selected.subject,
      {
        uid: user.uid,
        name: user.name,
        profile_pic: user.profile_url,
      },
      uploadStoryFile,
      duration,
      bunnyCDNConfig
    );

    uploadStory(shareStoryClass).then();

    onChange("sharing", true);
    setTimeout(() => {
      onChange("sharing", false);
      handleClose();
      handleCloseModal();
    }, 2000);
  }

  return (
    <div className="flex flex-col gap-5 relative p-4 bg-white dark:bg-black rounded-lg">
      <div
        className="absolute top-2 right-2 w-6 h-6 cursor-pointer bg-gray-200 rounded-full flex justify-center items-center"
        onClick={handleClose}
      >
        <Close style={{ fontSize: "13px" }} />
      </div>
      <h3 className="text-black dark:text-white text-sm font-bold text-center w-full">
        Share
      </h3>

      <div>
        <h5 className="text-black dark:text-white text-sm font-bold mb-2">
          Select Grade
        </h5>
        <div className="flex items-center gap-2">
          {storyConfig.grades.map((grade) => {
            const isSelected =
              selected.grades.findIndex(
                (_grade) => _grade.value === grade.value
              ) >= 0;
            return (
              <div
                key={grade.id}
                className={
                  "cursor-pointer bg-black dark:bg-white bg-opacity-20 dark:bg-opacity-20 rounded-lg py-2 px-4" +
                  (isSelected ? " !bg-blue-500 !bg-opacity-100" : "")
                }
                onClick={() => handleGradeChange(grade)}
              >
                <span
                  className={
                    "text-black dark:text-white text-sm" +
                    (isSelected ? " !text-white" : "")
                  }
                >
                  {grade.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <h5 className="text-black dark:text-white text-sm font-bold mb-2">
          Select Subject
        </h5>
        <div className="flex items-center gap-2">
          {subjectCollections
            ? subjectCollections.map((subject) => (
                <div
                  key={subject.guid}
                  className={
                    "cursor-pointer bg-black dark:bg-white bg-opacity-20 dark:bg-opacity-20 rounded-lg py-2 px-4" +
                    (selected.subject.guid === subject.guid
                      ? " !bg-blue-500 !bg-opacity-100"
                      : "")
                  }
                  onClick={() => handleSubjectChange(subject)}
                >
                  <span
                    className={
                      "text-black dark:text-white text-sm" +
                      (selected.subject.guid === subject.guid
                        ? " !text-white"
                        : "")
                    }
                  >
                    {subject.name}
                  </span>
                </div>
              ))
            : storyConfig.subjects.map((subject) => (
                <div
                  key={subject.id}
                  className={
                    "cursor-pointer bg-black dark:bg-white bg-opacity-20 animate-pulse dark:bg-opacity-20 rounded-lg py-2 px-4 "
                  }
                >
                  <span className={"text-sm opacity-0"}>{subject.label}</span>
                </div>
              ))}
        </div>
      </div>

      <button
        className="w-full h-12 flex justify-center items-center rounded-lg bg-blue-500 mt-3 text-sm text-white"
        onClick={handleShare}
      >
        {selected.sharing ? <SpinnerSVG /> : <span>Share</span>}
      </button>
    </div>
  );
}

export default function StoryModal() {
  const [showStoryModal, setShowStoryModal] =
    useContext(SubjectModalContext).showStoryModal;
  const [selectedStoryIndex, setSelectedStoryIndex] =
    useContext(SubjectModalContext).selectedStoryIndex;
  const [cursor, setCursor] = useContext(SubjectModalContext).storyCursor;
  const [stories, setStories] = useContext(SubjectModalContext).stories;
  // const stories = useMemo(() => {
  //   return mockStories;
  // }, []);
  const [uploadStoryFile, setUploadStoryFile] =
    useContext(SubjectModalContext).uploadStoryFile;
  const [url, setUrl] = useState(null);
  const [modalData, setModalData] = useContext(ModalContext).state;
  const [duration, setDuration] = useState(0);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  function playSubjectStory(direction) {
    if (!stories) return;
    setSelectedStoryIndex((c) => {
      const newIndex = c + direction;
      if (newIndex < 0 || newIndex > stories?.length - 1) {
        handleClose();
        return c;
      }
      return newIndex;
    });
  }

  function handleClose() {
    setUploadStoryFile(null);
    setUrl(null);
    setModalData({
      open: false,
      Children: null,
    });
    setShowStoryModal(false);
  }

  useEffect(() => {
    if (!uploadStoryFile) return;
    const _url = URL.createObjectURL(uploadStoryFile);

    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const _duration = video.duration;
      console.log("onloadedmetadata - - ", video.duration);
      const aspectRatio = video.videoWidth / video.videoHeight;
      const isAspectRatioAllowed = aspectRatio > 0.35 && aspectRatio < 0.65;
      if (!isAspectRatioAllowed) {
        showSnackbar(
          "Please use a portrait-style video for the best viewing experience.",
          "warning"
        );
        return handleClose();
      }
      if (_duration > 60) {
        // showSnackbar("Video duration cannot exceed the limit of 1 minute", "warning");
        // return handleClose();
      }
      setDuration(_duration);
      setUrl(_url);
    };

    video.src = _url;

    return () => {
      URL.revokeObjectURL(_url);
    };
  }, [uploadStoryFile]);

  function setDirection() {}

  function nextCursor() {
    setSelectedStoryIndex((c) => {
      const newIndex = c + 1;
      if (newIndex > stories?.length - 1) {
        handleClose();
        return c;
      }
      return newIndex;
    });
  }

  function prevCursor() {
    setSelectedStoryIndex((c) => {
      const newIndex = c - 1;
      if (newIndex < 0) {
        handleClose();
        return c;
      }
      return newIndex;
    });
  }

  console.log("6th Feb | selectedStoryIndex - ", selectedStoryIndex);

  function deleteSubjectStory() {
    setStories((stories) => {
      const newStories = [...stories];
      typeof selectedStoryIndex === "number" &&
        newStories.splice(selectedStoryIndex, 1);
      return newStories;
    });
  }

  return showStoryModal ? (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center"
      style={{ zIndex: 300 }}
    >
      <div
        className="absolute top-5 right-5  cursor-pointer bg-gray-200 rounded-full p-1"
        onClick={handleClose}
        style={{ zIndex: 200 }}
      >
        <Close />
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[95vw] h-[92vh] md:w-[100vw] md:h-[100vh] flex justify-center items-center bg-black bg-opacity-40">
        {!uploadStoryFile && !url && stories && (
          <>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <SpinnerSVG />
            </div>
            {isSmallScreen ? (
              <SwipeableViews
                axis={"x"}
                scrolling={"false"}
                index={selectedStoryIndex}
                style={{ outline: "none", width: "100%", height: "100%" }}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  transition: "none",
                }}
                ignoreNativeScroll={true}
                disabled={true}
              >
                {stories.map((story, ind) => (
                  <StoryView
                    isMobile={true}
                    story={story}
                    isActive={selectedStoryIndex === ind}
                    setDirection={setDirection}
                    nextSubject={nextCursor}
                    prevSubject={prevCursor}
                    deleteSubjectStory={deleteSubjectStory}
                  />
                  // <StoryVideoHolder key={story.id} isVisible={selectedStoryIndex === ind} storyId={story.id} story={story} storyItems={story.items ?? []} playSubjectStory={playSubjectStory}/>
                ))}
              </SwipeableViews>
            ) : (
              <StoryCarousel handleClose={handleClose} />
            )}
          </>
        )}
        {url && (
          <>
            <video className="w-full h-full" controls autoPlay>
              <source src={url} type="video/mp4" />
            </video>
            <div
              className="absolute cursor-pointer bottom-5 right-5 w-14 h-14 flex justify-center items-center bg-white rounded-full"
              onClick={() => {
                setModalData({
                  open: true,
                  Children: (
                    <ShareStoryModal
                      duration={duration}
                      handleCloseModal={handleClose}
                    />
                  ),
                });
              }}
            >
              <ChevronRight
                style={{ fontSize: "35px" }}
                className="text-blue-500"
              />
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
}

import { z } from "zod";
import { getBunnyCDNConfig } from "./SubjectModal";
import * as tus from "tus-js-client";
import { rdb } from "../../firebase_config";
import { STORY_STATUS } from "../../containers/home/sidebar";

export const bunnyCdnConfigSchema = z.object({
  api_key: z.string(),
  pull_zone: z.string(),
  host_name: z.string(),
  library_id: z.string(),
});

export const storyConfigSchema = z.object({
  bunny_cdn: bunnyCdnConfigSchema,
});

const accessTokenRonak = "eb3aa30f683094b5e51d077a9b8bbff5";

export const storyConfig = {
  grades: [
    { id: "class_7", value: "class_7", label: "Class 7" },
    { id: "class_8", value: "class_8", label: "Class 8" },
    { id: "class_9", value: "class_9", label: "Class 9" },
    { id: "class_10", value: "class_10", label: "Class 10" },
  ],
  subjects: [
    { id: "maths", value: "maths", label: "Maths", folderId: "18980897" },
    { id: "science", value: "science", label: "Science", folderId: "18980898" },
    { id: "sst", value: "sst", label: "SST", folderId: "18980899" },
    { id: "english", value: "english", label: "English", folderId: "18980901" },
  ],
};

export class ShareStory {
  storyId;
  grades;
  subjectObj;
  file;
  userId;
  uploadProgress = 0;
  isTransCoding = true;
  videoUri;
  duration = 0;
  videoName;
  videoId;
  videoLink;
  userObject;
  bunnyCDNConfig;

  constructor(
    grades,
    subjectObj,
    userObject,
    file,
    duration = 1,
    bunnyCDNConfig
  ) {
    this.grades = grades;
    this.subjectObj = subjectObj;
    this.file = file;
    this.userId = userObject.uid;
    this.userObject = {
      uid: userObject.uid,
      user_name: userObject.name,
      user_profile_pic: userObject.profile_pic,
    };
    this.duration = duration > 0 ? duration : 1;
    this.bunnyCDNConfig = bunnyCDNConfig;
  }

  static getStoryConfig() {
    return storyConfig;
  }

  getStoryId() {
    return this.videoId;
  }

  getSubjectName() {
    return this.subjectObj.name;
  }

  getItemBackgroundImage() {
    switch (this.subjectObj.name) {
      case "Maths":
        return "images/subjects/maths.svg";
      case "Science":
        return "images/subjects/physics.svg";
      case "SST":
        return "images/subjects/civics.svg";
      case "English":
        return "images/subjects/literature.svg";
      default:
        return "images/subjects/maths.svg";
    }
  }

  getStoryObject() {
    return {
      grade_id: this.getGradeId(),
      item_background_image: this.getItemBackgroundImage(),
      item_name: this.getSubjectName(),
      uid: this.userId,
      user_name: this.userObject.user_name,
      user_profile_pic: this.userObject.user_profile_pic,
    };
  }

  getGradeId(separator = ",") {
    return this.grades.map((grade) => grade.value).join(separator);
  }

  getSubjectCollectionId() {
    return this.subjectObj.guid;
  }

  getVideoDuration() {
    return this.duration;
  }

  getVideoName() {
    return this.videoName;
  }

  getVideoId() {
    return this.videoId;
  }

  fetchStoryObject() {
    this.videoId = this.videoUri;
    const _url = `https://${this.bunnyCDNConfig.host_name}/${this.videoId}/play_240p.mp4`;
    return {
      id: this.videoId,
      url: _url,
      grade_id: this.getGradeId(),
      seeMore: () => {},
      type: "video",
      duration: this.duration,
      preloadResource: true,
      uploadProgress: this.uploadProgress,
      isTransCoding: this.isTransCoding,
      watched: false,
      video_id: this.videoId,
    };
  }

  checkForTranscoding(onTranscodeComplete = () => {}) {
    const onUpdate = (snapshot) => {
      const status = snapshot.val() ? snapshot.val() : 0;

      console.log("onUpdate | story - ", status, this.getSubjectCollectionId());

      if (status === 3) {
        const _url = `https://${this.bunnyCDNConfig.host_name}/${this.videoId}/play_240p.mp4`;
        this.isTransCoding = false;
        onTranscodeComplete(_url);
        rdb
          .ref(
            `stories/collections/${this.getSubjectCollectionId()}/transcoding_status/status`
          )
          .off("value", onUpdate);
      }
    };

    rdb
      .ref(
        `stories/collections/${this.getSubjectCollectionId()}/transcoding_status/status`
      )
      .on("value", onUpdate);
  }

  /**
   *
   * @returns {Promise<string>}
   */
  async createBunnyCDNUploadLink() {
    // this.bunnyCDNConfig = await getBunnyCDNConfig();
    this.videoName =
      this.getGradeId("_") + "_" + this.userId + "_" + Date.now();
    const response = await fetch(
      `https://video.bunnycdn.com/library/${this.bunnyCDNConfig.library_id}/videos`,
      {
        method: "POST",
        headers: {
          AccessKey: this.bunnyCDNConfig.api_key,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: this.videoName,
          thumbnailTime: Math.round(this.duration).toString(),
          collectionId: this.getSubjectCollectionId(),
        }),
      }
    );

    const data = await response.json();

    this.videoUri = data.guid;
    return this.videoUri;
  }
  // Step 1 - Get Video config object
  // Step 2 - Upload video
  /**
   * @deprecated
   * @returns {Promise<*>}
   */
  async createUploadLink() {
    this.videoName =
      this.getGradeId("_") + "_" + this.userId + "_" + Date.now();
    const response = await fetch("https://api.vimeo.com/me/videos", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessTokenRonak}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        upload: {
          approach: "tus",
          size: this.file.size,
        },
        folder_uri: "https://vimeo.com/manage/folders/" + this.subjectObj.guid,
        name: this.videoName,
      }),
    });

    const data = await response.json();
    console.log(
      "Vimeo debug == ",
      "Response from the video upload link ",
      data
    );

    this.videoUri = data.uri;
    return data.upload.upload_link;
  }

  async signPresignedRequest(library_id, api_key, expiration_time, video_id) {
    const string = library_id + api_key + expiration_time + video_id;
    const textEncoder = new TextEncoder();
    const data = textEncoder.encode(string);
    const buffer = await crypto.subtle.digest("SHA-256", data);
    return Array.from(new Uint8Array(buffer))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  }

  async uploadBunnyCDNVideo(videoId, onProgress, onComplete) {
    const expirationTime = Math.floor(Date.now() / 1000) + 3600;
    const signedSignature = await this.signPresignedRequest(
      this.bunnyCDNConfig.library_id,
      this.bunnyCDNConfig.api_key,
      expirationTime,
      videoId
    );
    const upload = new tus.Upload(this.file, {
      endpoint: "https://video.bunnycdn.com/tusupload",
      // uploadUrl: uploadLink,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      headers: {
        AuthorizationSignature: signedSignature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
        AuthorizationExpire: expirationTime, // Expiration time as in the signature,
        VideoId: videoId, // The guid of a previously created video object through the Create Video API call
        LibraryId: this.bunnyCDNConfig.library_id,
      },
      metadata: {
        filetype: this.file.type,
        title: this.videoName,
        collection: this.subjectObj.guid,
      },
      onError: function (error) {
        console.log("Vimeo debug == ", "Failed because: " + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(
          "Vimeo debug == ",
          bytesUploaded,
          bytesTotal,
          percentage + "%"
        );
        this.uploadProgress = percentage;
        onProgress({ percentage, bytesTotal, bytesUploaded });
      },
      onSuccess: () => {
        console.log(
          "Vimeo debug == ",
          "Download %s from %s",
          upload,
          this.videoUri
        );
        this.uploadProgress = 100;
        onComplete(this.videoUri);
      },
    });
    upload.start();
  }

  /**
   * @deprecated
   * @param uploadLink
   * @param onProgress
   * @param onComplete
   * @returns {Promise<void>}
   */
  async uploadVideo(uploadLink, onProgress, onComplete) {
    const upload = new tus.Upload(this.file, {
      // endpoint: uploadLink,
      uploadUrl: uploadLink,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: this.file.name,
        filetype: this.file.type,
      },
      onError: function (error) {
        console.log("Vimeo debug == ", "Failed because: " + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        // console.log('Vimeo debug == ', bytesUploaded, bytesTotal, percentage + '%')
        this.uploadProgress = percentage;
        onProgress({ percentage, bytesTotal, bytesUploaded });
      },
      onSuccess: () => {
        console.log(
          "Vimeo debug == ",
          "Download %s from %s",
          upload,
          this.videoUri
        );
        this.uploadProgress = 100;
        onComplete(this.videoUri);
      },
    });
    upload.start();
  }

  // Step 4 - Get Upload progress
  // Step 3 - Get video url
}

import React, { useState, useRef, useEffect } from "react";
import "./style.scss";

export default function TabSlider({ onItemClick, items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (index, value) => {
    setActiveIndex(index);
    onItemClick(value);
    setIsOpen(false);
  };

  return (
    <div className="grade-selector-container" ref={dropdownRef}>
      <div className="grade-selector-header" onClick={() => setIsOpen(!isOpen)}>
        <span>{items[activeIndex]?.label || "Select Grade"}</span>
        <svg
          className={`dropdown-arrow ${isOpen ? "open" : ""}`}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L9 1"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {isOpen && (
        <div className="grade-selector-dropdown">
          {items.map((item, index) => (
            <div
              key={item.id}
              className={`grade-selector-item ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => handleSelect(index, item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
